import React, { Fragment, useContext, useState, useEffect } from 'react';
import DashboardNavBar from '../appbar/DashboardNavBar';
import SideBar from '../sidebar/SideBar';
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Typography,
  OutlinedInput,
  NativeSelect,
  Snackbar,
  Divider,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PersonIcon from '@material-ui/icons/Person';
import RoomIcon from '@material-ui/icons/Room';
import { clientUserInfoStyles } from './clientUserInfoStyles';
import AuthContext from '../../context/authentication/authContext';
import ClientContext from '../../context/client/clientContext';
import GoogleAutocomplete from '../googleAutocomplete/googleAutocomplete';

const ClientUserInfo = (props) => {
  const classes = clientUserInfoStyles();

  const authContext = useContext(AuthContext);
  const clientContext = useContext(ClientContext);

  const { user } = authContext;
  const {
    getClient,
    client,
    updateClient,
    uploadImage,
    clientMsg,
    clearMsg,
  } = clientContext;

  const [snackbar, setSnackbar] = useState(false);
  const [saveBtn, setSaveBtn] = useState(true);
  const [autoCompleteStreet, setAutoCompleteStreet] = useState(undefined);
  const [googleToken, setGoogleToken] = useState('');

  const [localUser, setLocalUser] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    street: '',
    unit: '',
    city: '',
    province: 'ON',
    postal_code: '',
    dob: '',

    member_id: '',
    insurance_name: '',
    care_type: '',
    insurance_date: '',
    insurance_company: '',
    insurance_relationship: '',
    policy_holder: false,
    contact_insurance_consent: false,
    claim_insurance_consent: false,
    certify_knowledge: false,

    set_lat_lng: false,
  });

  const {
    firstname,
    lastname,
    email,
    phone,
    street,
    unit,
    city,
    province,
    postal_code,
    dob,
    member_id,
    insurance_name,
    care_type,
    insurance_date,
    holder_dob,
    certify_knowledge,
    claim_insurance_consent,
    contact_insurance_consent,
    insurance_company,
    insurance_relationship,
  } = localUser;

  useEffect(() => {
    user &&
      user.user_type === 'provider' &&
      props.history.push('/provider/user-info');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    user && getClient(user.public_id);
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    setLocalUser({
      firstname: client.firstname || '',
      lastname: client.lastname || '',
      email: client.email || '',
      phone: client.phone || '',
      street: client.street || '',
      unit: client.unit || '',
      city: client.city || '',
      province: client.province || '',
      postal_code: client.postal_code || '',
      dob: client.dob || '',
      member_id: client.member_id || '',
      insurance_name: client.insurance_name || '',
      care_type: client.care_type || '',
      insurance_date: client.insurance_date || '',
      holder_dob: client.holder_dob || '',
      insurance_company: client.insurance_company || '',
      insurance_relationship: client.insurance_relationship || '',
      certify_knowledge: client.certify_knowledge || false,
      claim_insurance_consent: client.claim_insurance_consent || false,
      contact_insurance_consent: client.contact_insurance_consent || false,
      set_lat_lng: client.set_lat_lng || '',
      profile_pic: client.profile_pic || '',
      client_lng: client.client_lng,
      client_lat: client.client_lat,
    });
  }, [client]);

  useEffect(() => {
    clientMsg === 'Changes saved successfully' && setSnackbar(true);
    // eslint-disable-next-line
  }, [localUser]);

  useEffect(() => {
    if (window.google) {
      if (autoCompleteStreet !== undefined && autoCompleteStreet !== null) {
        const ontario = new window.google.maps.LatLng(51.2538, 85.3232);
        const map = new window.google.maps.Map(document.getElementById('map'), {
          center: ontario,
          zoom: 17,
        });

        const service = new window.google.maps.places.PlacesService(map);
        const request = {
          placeId: autoCompleteStreet.place_id,
          fields: ['address_component', 'geometry'],
          sessionToken: googleToken,
        };

        service.getDetails(request, (result, status) => {
          setSaveBtn(false);
          let street = '';
          let route = '';
          let city = '';
          let postalCode = '';

          result.address_components.map((component) => {
            component.types.map((type) => {
              if (type === 'street_number' && street === '')
                street = component.long_name;
              if (type === 'route' && route === '') route = component.long_name;
              if (type === 'locality' || type === 'sublocality_level_1') {
                if (city === '') {
                  city = component.long_name;
                }
              }
              if (type === 'postal_code' && postalCode === '')
                postalCode = component.long_name;
            });
          });

          street += ` ${route}`;

          setLocalUser((prevState) => {
            return {
              ...prevState,
              city: city,
              street: street,
              postal_code: postalCode,
              client_lat: result.geometry.location.lat(),
              client_lng: result.geometry.location.lng(),
            };
          });
        });
      }
    }
  }, [autoCompleteStreet]);

  const handleChange = (e) => {
    const { value, name } = e.target;

    if (name === 'insurance_relationship' && value !== 'Self') {
      setLocalUser({ ...localUser, policy_holder: false, [name]: value });
    } else {
      setLocalUser({ ...localUser, [name]: value });
    }

    setSaveBtn(false);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    if (name === 'policy_holder' && checked) {
      setLocalUser({
        ...localUser,
        insurance_relationship: 'Self',
        policy_holder: true,
        holder_dob: dob,
      });
    } else {
      setLocalUser({ ...localUser, [name]: checked });
    }

    setSaveBtn(false);
  };

  const handleSnackbar = () => {
    setSnackbar(!snackbar);
    clearMsg();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!firstname || !email) return;

    let addressSet = false;
    if ((street && city) || postal_code || (street && unit)) {
      addressSet = true;
    } else {
      addressSet = false;
    }
    updateClient(
      {
        firstname,
        lastname,
        email,
        phone,
        street,
        unit,
        city,
        province,
        postal_code,
        dob,
        member_id,
        insurance_name,
        care_type,
        insurance_date,
        holder_dob,
        certify_knowledge,
        claim_insurance_consent,
        contact_insurance_consent,
        insurance_company,
        insurance_relationship,
        set_lat_lng: addressSet ? true : false,
        client_lng: String(localUser.client_lng),
        client_lat: String(localUser.client_lat),
      },
      user.public_id
    );
    setSaveBtn(true);
  };

  const onUploadImage = (e) => {
    uploadImage(e, user.public_id, 'client');
  };

  return (
    <Fragment>
      <DashboardNavBar />
      <SideBar />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={2000}
        open={snackbar}
        onClose={handleSnackbar}
      >
        <Alert elevation={6} variant="filled" severity="success">
          {clientMsg}
        </Alert>
      </Snackbar>

      <div className={classes.root}>
        {/* ********************** Profile pic container ********************** */}
        <Grid
          container
          alignItems="center"
          className={classes.profileDivContainer}
        >
          <Grid item className={classes.profileItemDiv}>
            <div className={classes.profilePicDiv}>
              {client.profile_pic && (
                <div
                  className={classes.profilePicUploaded}
                  style={{
                    backgroundImage: `url(${client.profile_pic})`,
                  }}
                ></div>
              )}
              {!client.profile_pic && (
                <PersonIcon className={classes.profilePic} />
              )}
            </div>

            <input
              id="avatar-file-upload"
              type="file"
              multiple={false}
              accept=".jpg,.jpeg,.png"
              onChange={onUploadImage}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6" color="primary" className={classes.user}>
              {user && `${user.firstname} ${user.lastname}`}
            </Typography>
            <div className={classes.div}>
              <RoomIcon className={classes.locationIcon} />
              <Typography
                color="textSecondary"
                variant="body2"
                className={classes.location}
              >
                {client && client.city && `${client.city}, `} {client.province}
              </Typography>
            </div>
          </Grid>
        </Grid>

        {/* ************************* Form ********************** */}
        <form noValidate className={classes.form} onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="firstname">
                  First Name
                </InputLabel>
                <OutlinedInput
                  id="firstname"
                  name="firstname"
                  color="secondary"
                  type="text"
                  className={classes.input}
                  value={localUser.firstname}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="lastname">
                  Last Name
                </InputLabel>
                <OutlinedInput
                  id="lastname"
                  name="lastname"
                  color="secondary"
                  type="text"
                  className={classes.input}
                  value={localUser.lastname}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="email">
                  Email address
                </InputLabel>
                <OutlinedInput
                  disabled
                  id="email"
                  name="email"
                  type="email"
                  color="secondary"
                  className={classes.input}
                  value={localUser.email}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="phone">
                  Phone number
                </InputLabel>
                <OutlinedInput
                  id="phone"
                  name="phone"
                  type="text"
                  color="secondary"
                  className={classes.input}
                  value={localUser.phone}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={10} className={classes.grid}>
              <div id="map"></div>

              <GoogleAutocomplete
                label="Street Address"
                placeholder="2020 Street"
                full={false}
                receiveData={(option) => {
                  setAutoCompleteStreet(option);
                }}
                receiveToken={(token) => setGoogleToken(token)}
                currentPlace={street ? street : ''}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="unit">
                  Unit/Suit
                </InputLabel>
                <OutlinedInput
                  id="unit"
                  name="unit"
                  type="text"
                  color="secondary"
                  className={classes.input}
                  value={localUser.unit}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="city">
                  City
                </InputLabel>
                <OutlinedInput
                  id="city"
                  name="city"
                  type="text"
                  color="secondary"
                  className={classes.input}
                  value={localUser.city}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="province">
                  Province
                </InputLabel>
                <NativeSelect
                  disabled
                  value={localUser.province}
                  onChange={handleChange}
                  input={
                    <OutlinedInput
                      id="province"
                      name="province"
                      type="text"
                      color="secondary"
                      className={classes.input}
                    />
                  }
                >
                  <option value="AB">Alberta</option>
                  <option value="BC">British Columbia</option>
                  <option value="MB">Manitoba</option>
                  <option value="NB">New Brunswick</option>
                  <option value="NL">Newfoundland and Labrador</option>
                  <option value="NT">Northwest Territories</option>
                  <option value="NS">Nova Scotia</option>
                  <option value="NU">Nunavut</option>
                  <option value="ON">Ontario</option>
                  <option value="PE">Prince Edward Island</option>
                  <option value="QC">Quebec</option>
                  <option value="SK">Saskatchewan</option>
                  <option value="YT">Yukon</option>
                </NativeSelect>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="postal_code">
                  Postal Code
                </InputLabel>
                <OutlinedInput
                  id="postal_code"
                  name="postal_code"
                  type="text"
                  color="secondary"
                  className={classes.input}
                  value={localUser.postal_code}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="dob">
                  Date of Birth
                </InputLabel>
                <OutlinedInput
                  id="dob"
                  name="dob"
                  color="secondary"
                  type="date"
                  className={classes.input}
                  value={localUser.dob}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>

          <div className={classes.divider}>
            <Divider />
          </div>

          {/* ********************* Insurance information ********************** */}
          <Typography color="primary" variant="h6" className={classes.title}>
            Insurance information
          </Typography>
          <Typography variant="body2" gutterBottom>
            To have MyProvider inquire about your full insurance coverage and/or
            submit a claim on your behalf please complete the following.
          </Typography>

          <Typography variant="body2">
            Please input the <b>policy holder's</b> information below.
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="insurance_company">
                  Name of insurance company
                </InputLabel>
                <OutlinedInput
                  id="insurance_company"
                  name="insurance_company"
                  color="secondary"
                  type="text"
                  className={classes.input}
                  value={localUser.insurance_company}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={localUser.policy_holder}
                    onChange={handleCheckboxChange}
                    name="policy_holder"
                  />
                }
                label="I am the policy holder"
              />

              <FormControl>
                <InputLabel shrink htmlFor="insurance_relationship">
                  Relationship to insured
                </InputLabel>
                <NativeSelect
                  value={localUser.insurance_relationship}
                  onChange={handleChange}
                  input={
                    <OutlinedInput
                      id="insurance_relationship"
                      name="insurance_relationship"
                      type="text"
                      color="secondary"
                      className={classes.input}
                    />
                  }
                >
                  <option value="Self">Self</option>
                  <option value="Spouse">Spouse</option>
                  <option value="Dependant/Child">Dependant/Child</option>
                </NativeSelect>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="member_id">
                  Member ID number
                </InputLabel>
                <OutlinedInput
                  id="member_id"
                  name="member_id"
                  color="secondary"
                  type="text"
                  className={classes.input}
                  value={localUser.member_id}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="insurance_name">
                  Policy holder's full name
                </InputLabel>
                <OutlinedInput
                  id="insurance_name"
                  name="insurance_name"
                  color="secondary"
                  type="text"
                  className={classes.input}
                  value={localUser.insurance_name}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="care_type">
                  Care type
                </InputLabel>
                <OutlinedInput
                  id="care_type"
                  name="care_type"
                  color="secondary"
                  type="text"
                  className={classes.input}
                  value={localUser.care_type}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="insurance_date">
                  Coverage effective date
                </InputLabel>
                <OutlinedInput
                  id="insurance_date"
                  name="insurance_date"
                  color="secondary"
                  type="date"
                  className={classes.input}
                  value={localUser.insurance_date}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="holder_dob">
                  Police Holder's date of birth
                </InputLabel>
                <OutlinedInput
                  id="holder_dob"
                  name="holder_dob"
                  color="secondary"
                  type="date"
                  className={classes.input}
                  value={localUser.holder_dob}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={10} className={classes.grid}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={localUser.contact_insurance_consent}
                    onChange={handleCheckboxChange}
                    name="contact_insurance_consent"
                  />
                }
                label="Consent to allow MyProvider to contact insurance company and obtain coverage information for client"
                labelPlacement="start"
              />
            </Grid>

            <Grid item xs={12} sm={10} className={classes.grid}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={localUser.claim_insurance_consent}
                    onChange={handleCheckboxChange}
                    name="claim_insurance_consent"
                  />
                }
                label="Consent to allow MyProvider to submit claims to insurance company on behalf of client and to receive and distribute funds to health professionals"
                labelPlacement="start"
              />
            </Grid>

            <Grid item xs={12} sm={10} className={classes.grid}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={localUser.certify_knowledge}
                    onChange={handleCheckboxChange}
                    name="certify_knowledge"
                  />
                }
                label="I certify that this information is true and accurate to the best of my knowledge"
                labelPlacement="start"
              />
            </Grid>
          </Grid>

          <Button
            disabled={saveBtn}
            variant="contained"
            color="secondary"
            className={classes.saveButton}
            type="submit"
          >
            Save changes
          </Button>
        </form>
      </div>
    </Fragment>
  );
};

export default ClientUserInfo;

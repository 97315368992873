import React, { useState, useEffect, useContext } from 'react';
import { Paper, Grid, Typography, Box, Button } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import PersonIcon from '@material-ui/icons/Person';
import ComputerIcon from '@material-ui/icons/Computer';
import RoomIcon from '@material-ui/icons/Room';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HomeIcon from '@material-ui/icons/Home';
import { providerCardStyles } from './providerCardStyles';
import DialogContext from '../../context/dialogContext/dialogContext';
import ClientContext from '../../context/client/clientContext';
import AuthContext from '../../context/authentication/authContext';
import GoogleContext from '../../context/google/googleContext';
import { withRouter } from 'react-router-dom';

const ProviderCard = (props) => {
  const classes = providerCardStyles();

  const clientContext = useContext(ClientContext);
  const dialogContext = useContext(DialogContext);
  const authContext = useContext(AuthContext);
  const googleContext = useContext(GoogleContext);

  const { user } = authContext;
  const {
    setFavourite,
    getClient,
    clearMsg,
    favourite,
    client,
    updateClient,
  } = clientContext;
  const { handleOpenLogin } = dialogContext;
  const { googleLoading, reloadGoogle } = googleContext;
  const [clientLatLng, setClientLatLng] = useState({
    client_lat: client.client_lat,
    client_lng: client.client_lng,
  });

  const { client_lat, client_lng } = clientLatLng;

  const { provider, page, history, clientLocationInfo } = props;

  const {
    public_id,
    firstname,
    lastname,
    role,
    city,
    province,
    email,
    home_visit,
    profile_pic,
    travel_visit,
  } = provider;

  const userType = localStorage.getItem('user_role');

  const [liked, setLiked] = useState(null);

  useEffect(() => {
    clearMsg();
    if (page === 'search') {
      favourite.includes(email) ? setLiked(true) : setLiked(false);
    } else {
      setLiked(true);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user && userType === 'client') {
      getClient(user.public_id);
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (userType === 'client') {
      if (liked === false) {
        setFavourite(email, 'removeFavourite');
      } else {
        setFavourite(email);
      }
    }
    // eslint-disable-next-line
  }, [liked]);

  useEffect(() => {
    if (typeof window !== 'undefined' && window.google === undefined) {
      reloadGoogle();
    }

    if (window.google) {
      if (clientLocationInfo && clientLocationInfo.set_lat_lng === true) {
        console.log(' are we ever in here?');
        // ONTARIO LATITUDE AND LONGITUDE
        // Can change this to be more dynamic but seems
        // like this app is focused on ontario
        // Also not sure if this is completely necessary just wanted
        // to potentially increase the accuracy
        const ontario = new window.google.maps.LatLng(51.2538, 85.3232);
        const map = new window.google.maps.Map(document.getElementById('map'), {
          center: ontario,
          zoom: 17,
        });

        const service = new window.google.maps.places.PlacesService(map);
        const request = {
          query: clientLocationInfo.clientAddress.address,
          fields: ['geometry'],
        };

        service.findPlaceFromQuery(request, (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setClientLatLng({
              client_lat: results[0].geometry.location.lat(),
              client_lng: results[0].geometry.location.lng(),
            });

            updateClient(
              {
                client_lat: results[0].geometry.location.lat(),
                client_lng: results[0].geometry.location.lng(),
                set_lat_lng: false,
              },
              client.public_id
            );
          }
        });
      }
    }
  }, [googleLoading]);
  const handleLikeClick = () => {
    userType === 'client' && setLiked(!liked);
  };

  const handleClick = () => {
    if (localStorage.user_role) {
      history.push(`/provider-public-profile/${public_id}`);
    } else {
      handleOpenLogin();
    }
  };

  const calculateDistanceBetween = () => {
    const R = 6371e3;
    const lat1 = (client_lat * Math.PI) / 180;
    const lat2 = (provider.home_visit_lat * Math.PI) / 180;
    const diffLat = ((lat2 - lat1) * Math.PI) / 180;
    const diffLng = ((provider.home_visit_lng - client_lng) * Math.PI) / 180;

    const a =
      Math.sin(diffLat / 2) * Math.sin(diffLat / 2) +
      Math.cos(client_lat) *
        Math.cos(provider.home_visit_lat) *
        Math.sin(diffLng / 2) *
        Math.sin(diffLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const meters = c * R;
    const kilometers = meters / 1000;
    return kilometers < provider.home_visit_distance;
  };

  return (
    <Paper className={classes.paper}>
      <div id="map"></div>
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.grid}
      >
        {userType && userType === 'client' && (
          <div className={classes.favourite}>
            <FavoriteIcon
              onClick={handleLikeClick}
              color={
                page === 'search' ? (liked ? 'primary' : 'disabled') : 'primary'
              }
            />
          </div>
        )}
        {home_visit && (
          <div className={classes.homeIcon}>
            <ComputerIcon />
          </div>
        )}

        {travel_visit && (
          <div className={classes.travelIcon}>
            <HomeIcon
              color={
                page === 'search'
                  ? travel_visit
                    ? calculateDistanceBetween()
                      ? 'secondary'
                      : 'disabled'
                    : 'disabled'
                  : 'disabled'
              }
            />
          </div>
        )}

        <div className={classes.profilePicDiv}>
          {profile_pic && (
            <div
              className={classes.profilePicUploaded}
              style={{
                backgroundImage: `url(${profile_pic})`,
              }}
            ></div>
          )}
          {!profile_pic && <PersonIcon className={classes.profilePic} />}
        </div>

        <Typography variant="h6" color="primary" className={classes.user}>
          {firstname} {lastname}
        </Typography>
        <Typography color="textSecondary" className={classes.userRole}>
          {role}
        </Typography>

        <Box
          component="fieldset"
          borderColor="transparent"
          className={classes.review}
        >
          <Rating name="read-only" value={provider.rating} readOnly />
        </Box>

        <div className={classes.div}>
          <RoomIcon className={classes.locationIcon} />
          <Typography
            color="textSecondary"
            variant="body2"
            className={classes.location}
          >
            {city} {province}
          </Typography>
        </div>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={handleClick}
        >
          Book an appointment
        </Button>
      </Grid>
    </Paper>
  );
};

export default withRouter(ProviderCard);

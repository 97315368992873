import { makeStyles } from '@material-ui/core/styles';

export const providerProfileStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 420px)',
    marginTop: '130px',
    marginLeft: '380px',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
      marginLeft: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px',
      textAlign: 'center',
    },
  },
  divContainer: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '120px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
  },
  previewLink: {
    textDecoration: 'none',
  },
  previewBtn: {
    color: theme.palette.primary.main,
    padding: theme.spacing(1.5, 3),
    textTransform: 'lowercase',
    fontSize: '1rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
  title: {
    margin: theme.spacing(6, 0, 2),
    fontSize: '1.2rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  textArea: {
    width: '730px',
    height: '125px',
    borderRadius: '7px',
    borderColor: '#ced4da',
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontFamily: '"Poppins", sans-serif',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '500px',
      height: '140px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
      height: '108px',
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '250px',
      height: '100px',
      fontSize: '14px',
      padding: theme.spacing(1),
    },
  },
  divider: {
    margin: theme.spacing(4, 0),
    width: '800px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '530px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '380px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '280px',
      margin: theme.spacing(4, 'auto'),
    },
  },
  formControl: {
    margin: theme.spacing(2, 0),
  },
  addBtn: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '4px',
    right: '10%',
  },
  skillsInput: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    borderRadius: 7,
    color: theme.palette.primary.main,
    fontSize: 16,
    width: '732px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '494px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '337px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '242px',
    },
  },
  paper: {
    display: 'inline-table',
    padding: theme.spacing(1),
    margin: theme.spacing(1, 1, 1, 0),
    borderRadius: '5px',
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      display: 'table',
      margin: theme.spacing(1, 'auto'),
    },
  },
  removeIconBtn: {
    width: '15px',
    height: '15px',
    marginLeft: theme.spacing(1),
  },
  removeIcon: {
    width: '15px',
    height: '15px',
  },
  clinicInfoForm: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
    },
  },
  grid: {
    margin: theme.spacing(2, 0),
  },
  clinicInfoInput: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    borderRadius: 7,
    color: theme.palette.primary.main,
    fontSize: 16,
    width: '732px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '494px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '337px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '242px',
    },
  },
  clinicInfoFormControl: {
    marginTop: theme.spacing(2.5),
  },
  clinicInfoInput2: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    borderRadius: 7,
    color: theme.palette.primary.main,
    fontSize: 16,
    width: '341px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '220px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '337px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '242px',
    },
  },
  paperHours: {
    display: 'table',
    padding: theme.spacing(2, 1, 2, 2),
    margin: theme.spacing(2, 1, 1, 0),
    borderRadius: '5px',
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
  saveButton: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(1.5, 3),
    color: '#ffffff',
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(1.5, 3),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(1.5, 3),
    },
  },
  feeInputFormControl: {
    width: '100%',
    maxWidth: '341px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(3.5),
    },
  },
  feeSelectionFormControl: {
    width: '100%',
    maxWidth: '341px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '0px',
    },
  },
  feeInput: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    borderRadius: 7,
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  feeHourlyFormControl: {
    width: '100%',
    maxWidth: '341px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '0px',
    },
  },
  hourlyButton: {
    margin: theme.spacing(0, 0, 0.5, 0),

    backgroundColor: theme.palette.secondary.main,
    borderRadius: '4px',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  numberInput: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    borderRadius: 7,
    color: theme.palette.primary.main,
    fontSize: 16,
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  hourlyGrid: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(3),
    },
  },
  minusButton: {
    //marginLeft: theme.spacing(1),
    backgroundColor: '#E52020',
    borderRadius: '4px',
    marginTop: theme.spacing(0.5),
  },
  displayHourly: {
    //marginLeft: theme.spacing(1),
    width: '100%',
    maxWidth: '341px',
    [`& fieldset`]: {
      borderRadius: 7,
    },
  },
  displayGrid: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
      marginLeft: '0px',
    },
  },
  midpointMargin: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  beginningMargin: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(0),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
    },
    [`& fieldset`]: {
      borderRadius: 7,
    },
  },

  autoCompleteGrid: {
    width: '732px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '494px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '337px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '242px',
    },
  },
  streetContainer: {
    paddingTop: '20px',
    [theme.breakpoints.up('sm')]: {
      'justify-content': 'flex-start',
      'align-items': 'flex-start',
    },
  },
}));

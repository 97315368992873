import { makeStyles } from '@material-ui/core/styles';
import bitmap from '../../../assets/bitmap.png';
import mask from '../../../assets/mask.png';

export const homepageStyles = makeStyles((theme) => ({
  bannerContainer: {
    backgroundColor: theme.bgcolor,
    position: 'relative',
    width: '100%',
    height: '60vh',
    zIndex: '0',
  },
  bitmapImg: {
    position: 'absolute',
    height: '60vh',
    width: '35%',
    marginLeft: '65%',
    zIndex: '1',
    backgroundImage: `url(${bitmap})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  overlay: {
    position: 'absolute',
    zIndex: '2',
    height: '60vh',
    width: '35%',
    marginLeft: '65%',
    backgroundImage: `url(${mask})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    opacity: '0.7',
  },
  container: {
    position: 'absolute',
    zIndex: '3',
  },
  bannerTitle: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    margin: theme.spacing(21, 8, 3, 15),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1.7rem',
      margin: theme.spacing(18, 2, 3, 10),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      margin: theme.spacing(15, 2, 3, 8),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem',
      margin: theme.spacing(15, 1.5, 2, 2),
    },
  },

  bannerSubtitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: theme.spacing(2, 8, 2, 15),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
      margin: theme.spacing(2, 2, 2, 10),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      margin: theme.spacing(2, 2, 1, 8),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
      margin: theme.spacing(2, 1.5, 1, 2),
    },
  },

  explnationContainer: {
    padding: theme.spacing(4),
  },

  search: {
    margin: theme.spacing(1, 15),
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(2, 10),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 8),
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0, 0, 2),
    },
  },
  inputProps: {
    paddingRight: '0px',
    backgroundColor: '#ffffff',
  },
  searchIcon: {
    color: '#7c9cbb',
  },
  searchButton: {
    color: '#ffffff',
    padding: theme.spacing(1.2, 3),
    margin: theme.spacing(0, 1),
    boxShadow: 'none',
  },
  groupImg: {
    width: '250px',
    height: '270px',
    margin: theme.spacing(17, 0),
    [theme.breakpoints.between('sm', 'md')]: {
      width: '190px',
      height: '210px',
      margin: theme.spacing(17, 0),
    },
    [theme.breakpoints.down('sm')]: {
      width: '170px',
      height: '190px',
      margin: theme.spacing(18, 0),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  paperContainer: {
    height: '60vh',
    width: '100%',
    position: 'absolute',
    zIndex: '4',
  },
  paper: {
    position: 'absolute',
    zIndex: '5',
    textAlign: 'center',
    borderRadius: '20px',
    marginTop: '8.5%',
    marginLeft: '2%',
    boxShadow: '-3px 7px 30px -17px rgba(0,0,0,0.75)',
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: '11%',
      marginLeft: '1%',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '17%',
      marginLeft: '0%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  paperImg: {
    width: '110px',
    height: '110px',
    borderRadius: '50%',
    margin: theme.spacing(5, 7, 2),
    [theme.breakpoints.between('sm', 'md')]: {
      width: '90px',
      height: '90px',
      margin: theme.spacing(4, 6, 2),
    },
    [theme.breakpoints.down('sm')]: {
      width: '80px',
      height: '80px',
      margin: theme.spacing(4, 5, 2),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  hr1: {
    border: '5px solid #e9ebed',
    borderRadius: '7px',
    margin: theme.spacing(1.5, 6.5),
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(1.5, 5),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 3.5),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  hr2: {
    border: '5px solid #e9ebed',
    borderRadius: '7px',
    margin: theme.spacing(0, 8, 4),
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(0, 7, 4),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 5.5, 3),
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  carousel: {
    width: '100%',
    height: '400px',
    [theme.breakpoints.between('sm', 'md')]: {
      height: '280px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '250px',
      paddingLeft: '37px',
    },
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    margin: theme.spacing(10, 0, 4),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1.5rem',
      margin: theme.spacing(10, 0, 4),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
      margin: theme.spacing(10, 0, 3),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem',
      margin: theme.spacing(10, 0, 4),
    },
  },
  badges: {
    height: '90px',
    width: '90px',
    borderRadius: '40%',
    backgroundColor: theme.bgcolor,
    margin: theme.spacing(4, 'auto'),
  },
  stepText: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    margin: theme.spacing(1, 0),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  disabled: {
    fontWeight: '500',
    fontSize: '1rem',
    margin: theme.spacing(0, 6),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.9rem',
      margin: theme.spacing(0, 1),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      margin: theme.spacing(0),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
      margin: theme.spacing(0, 7),
    },
  },
  direction: {
    textAlign: 'center',
    marginTop: '5%',
    color: theme.bgcolor,
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: '7%',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '8%',
    },
  },
  arrowRight: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  arrowDown: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
    },
  },
  faqContainer: {
    width: '90%',
    margin: theme.spacing(5, 'auto', 10),
    [theme.breakpoints.between('sm', 'md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '88%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  expansionPanel: {
    minWidth: '100%',
  },
  faqTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: theme.spacing(2),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
      margin: theme.spacing(1),
    },
  },
  faqDisabled: {
    fontSize: '1rem',
    fontWeight: 500,
    margin: theme.spacing(0, 2, 3),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  bottomContainer: {
    backgroundColor: theme.bgcolor,
    maxWidth: '90%',
    borderRadius: '16px',
    margin: theme.spacing(10, 'auto', 1),
  },
  bottomTitle: {
    textAlign: 'center',
    margin: theme.spacing(9, 45, 8),
    fontSize: '2.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1.9rem',
      margin: theme.spacing(8, 31, 7),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.7rem',
      margin: theme.spacing(8, 20, 7),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem',
      margin: theme.spacing(8, 1.5, 4),
    },
  },
  bottomBtn: {
    color: '#ffffff',
    padding: theme.spacing(1.2, 3),
    margin: theme.spacing(0, 1, 8),
    boxShadow: 'none',
  },
  heroBtn: {
    boxShadow: 'none',
  },
  popularBtn: {
    boxShadow: 'none',
    marginRight: theme.spacing(1),
  },
}));

import React, { useContext, Fragment } from 'react';
import { Typography, List, ListItem, Button } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { NavLink } from 'react-router-dom';
import { sideBarStyles } from './sideBarStyles';
import AuthContext from '../../context/authentication/authContext';
import ClientContext from '../../context/client/clientContext';
import ProviderContext from '../../context/provider/providerContext';

const SideBar = () => {
  const classes = sideBarStyles();

  const authContext = useContext(AuthContext);
  const clientContext = useContext(ClientContext);
  const providerContext = useContext(ProviderContext);

  const { logout, user_role } = authContext;
  const { clientLogout } = clientContext;
  const { providerLogout } = providerContext;

  const handleClick = () => {
    clientLogout();
    providerLogout();
    logout();
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <div className={classes.sidebarDiv}>
          {localStorage.adminToken && (
            <div className={classes.arrowBack}>
              <ArrowBackIcon color="secondary" onClick={handleClick} />
            </div>
          )}
          <Typography variant="h4" color="primary" className={classes.title}>
            User Profile
          </Typography>

          {/* ******************** Provider sidebar ************************ */}
          {user_role && user_role === 'provider' && (
            <List>
              <ListItem className={classes.listItem}>
                <NavLink
                  to="/provider/user-info"
                  className={classes.menuLink}
                  activeClassName={classes.menuActiveLink}
                >
                  <div className={classes.navDiv}>
                    <PermIdentityIcon className={classes.listItemIcon} />
                    <Typography className={classes.subTitle}>
                      User Info
                    </Typography>
                  </div>
                </NavLink>
              </ListItem>

              <ListItem className={classes.listItem}>
                <NavLink
                  to="/provider/profile"
                  className={classes.menuLink}
                  activeClassName={classes.menuActiveLink}
                >
                  <div className={classes.navDiv}>
                    <WorkOutlineOutlinedIcon className={classes.listItemIcon} />
                    <Typography className={classes.subTitle}>
                      My Profile
                    </Typography>
                  </div>
                </NavLink>
              </ListItem>

              <ListItem className={classes.listItem}>
                <NavLink
                  to="/provider/availability"
                  className={classes.menuLink}
                  activeClassName={classes.menuActiveLink}
                >
                  <div className={classes.navDiv}>
                    <AccessTimeOutlinedIcon className={classes.listItemIcon} />
                    <Typography className={classes.subTitle}>
                      Availability
                    </Typography>
                  </div>
                </NavLink>
              </ListItem>
            </List>
          )}

          {/* ************************* Client sidebar ********************** */}
          {user_role && user_role === 'client' && (
            <List>
              <ListItem className={classes.listItem}>
                <NavLink
                  to="/client/user-info"
                  className={classes.menuLink}
                  activeClassName={classes.menuActiveLink}
                >
                  <div className={classes.navDiv}>
                    <PermIdentityIcon className={classes.listItemIcon} />
                    <Typography className={classes.subTitle}>
                      User Info
                    </Typography>
                  </div>
                </NavLink>
              </ListItem>

              <ListItem className={classes.listItem}>
                <NavLink
                  to="/client/my-providers"
                  className={classes.menuLink}
                  activeClassName={classes.menuActiveLink}
                >
                  <div className={classes.navDiv}>
                    <FavoriteBorderIcon className={classes.listItemIcon} />
                    <Typography className={classes.subTitle}>
                      My providers
                    </Typography>
                  </div>
                </NavLink>
              </ListItem>
            </List>
          )}
        </div>
        {!localStorage.adminToken && (
          <div>
            <Button
              color="primary"
              onClick={handleClick}
              className={classes.logoutBtn}
            >
              Log Out
            </Button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default SideBar;

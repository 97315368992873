import { makeStyles } from '@material-ui/core/styles';

export const clientMyproviderStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 420px)',
    marginTop: '130px',
    marginLeft: '380px',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
      marginLeft: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px',
      textAlign: 'center',
    },
  },
  gridContainer: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
}));

import { makeStyles } from '@material-ui/core/styles';

export const filterProviderStyles = makeStyles((theme) => ({
  grid: {
    marginBottom: theme.spacing(3),
  },
  all: {
    marginLeft: theme.spacing(5),
    color: theme.palette.primary.main,
    fontWeight: '500',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(3.5),
    },
  },
  switchText: {
    color: theme.palette.primary.main,
    fontWeight: '500',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(5),
    },
  },
  specialization: {
    marginLeft: theme.spacing(5),
  },
}));

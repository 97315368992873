import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from '@material-ui/core';
import { adminStyles } from './adminStyles';
import AdminContext from '../context/admin/adminContext';

const AllClients = () => {
  const classes = adminStyles();

  const adminContext = useContext(AdminContext);
  const { allClients, getClients } = adminContext;

  useEffect(() => {
    getClients();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.usersRoot}>
      <Container maxWidth="xl">
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell align="right">First Name</TableCell>
                <TableCell align="right">Last Name</TableCell>
                <TableCell align="right">DOB</TableCell>
                <TableCell align="right">Insurance Company</TableCell>
                <TableCell align="right">Policey Holder's name</TableCell>
                <TableCell align="right">Insurance Relationship</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allClients &&
                allClients.map((row) => (
                  <TableRow key={allClients.public_id}>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell align="right">{row.firstname}</TableCell>
                    <TableCell align="right">{row.lastname}</TableCell>
                    <TableCell align="right">{row.dob}</TableCell>
                    <TableCell align="right">{row.insurance_company}</TableCell>
                    <TableCell align="right">{row.insurance_name}</TableCell>
                    <TableCell align="right">
                      {row.insurance_relationship}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
};

export default AllClients;

import { makeStyles } from '@material-ui/core/styles';
import bgImg from '../../../assets/aboutus-bg.png';

export const aboutUsStyles = makeStyles((theme) => ({
  bannerContainer: {
    position: 'relative',
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '60vh',
    width: '100%',
    '&::before': {
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.primary.main,
      height: '60vh',
      width: '100%',
      opacity: '0.9',
      zIndex: '1',
    },
  },
  titleSecondary: {
    fontSize: '1.75rem',
    fontWeight: 500,
    margin: theme.spacing(12, 32),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1.6rem',
      margin: theme.spacing(9, 15),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
      margin: theme.spacing(8, 8),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
      margin: theme.spacing(6, 3),
    },
  },
  teamContainer: {
    margin: theme.spacing(8, 0, 24, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0, 18, 0),
    },
  },
  circularImg: {
    width: '140px',
    height: '140px',
    borderRadius: '50%',
    margin: theme.spacing(3, 0),
  },
  teamItem: {
    textAlign: 'center',
  },
  name: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  role: {
    fontSize: '0.9rem',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.7rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
  },
}));

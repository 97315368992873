import React, { useState, useContext, Fragment, useEffect } from 'react';
import Login from './Login';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  useScrollTrigger,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import { useStyles } from '../../../themes/commonStyles';
import { navStyles } from './navStyles';
import { NavLink, Link } from 'react-router-dom';
import logo from '../../../assets/MP-website-image.png';
import AuthContext from '../../context/authentication/authContext';
import DialogContext from '../../context/dialogContext/dialogContext';

import { withRouter } from 'react-router-dom';

const Navbar = (props) => {
  const classesBase = useStyles();
  const classes = navStyles();

  const dialogContext = useContext(DialogContext);
  const authContext = useContext(AuthContext);

  const { user } = authContext;
  const { handleOpenLogin, handleOpenSignup } = dialogContext;

  const { searchPage, location } = props;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [query, setQuery] = useState('');
  const [isBeta, setBeta] = useState(false);
  const trigger = useScrollTrigger();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // Only allow to see certain things from the landing page if ?beta=true is in the URL
    setBeta(params.get('beta'));

    setQuery(new URLSearchParams(props.location.search).get('text'));
    // eslint-disable-next-line
  }, []);

  const toggleDrawer = (e) => {
    setOpenDrawer(!openDrawer);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  };

  const handleSearchClick = () => {
    query !== '' && props.history.push(`/search?text=${query}`);
  };

  return (
    <div className={classesBase.root}>
      {/* ***************** Landing page Navbar ************* */}

      <AppBar
        color="inherit"
        className={trigger ? '0' : classes.hide}
        position={props.location.pathname === '/search' ? 'sticky' : 'fixed'}
      >
        <Toolbar>
          <div className={classes.linkDiv}>
            <div className={classes.logoDiv}>
              <Link to="/" className={classesBase.logoLink}>
                <img src={logo} alt="logo" className={classesBase.logo} />
              </Link>
            </div>

            {/* ****************** Search ********************* */}
            {searchPage && (
              <TextField
                variant="outlined"
                color="secondary"
                placeholder="Find provider"
                className={classes.search}
                value={query}
                onKeyDown={handleKeyDown}
                onChange={handleSearchChange}
                InputProps={{
                  className: classes.inputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.searchButton}
                        onClick={handleSearchClick}
                      >
                        Search
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </div>

          {/* ***************** Navlink ******************** */}
          <NavLink
            to="/for-provider"
            className={classes.navLink}
            activeClassName={classes.activeLink}
          >
            <Typography className={classes.navLinkTypography}>
              For providers
            </Typography>
          </NavLink>
          <NavLink
            to="/about"
            className={classes.navLink}
            activeClassName={classes.activeLink}
          >
            <Typography className={classes.navLinkTypography}>
              About us
            </Typography>
          </NavLink>

          {/* ****************** Login/Signup ****************** */}

          {!user && (
            <Fragment>
              <div>
                <Button
                  color="primary"
                  className={classes.loginBtn}
                  onClick={handleOpenLogin}
                >
                  Login
                </Button>
                <Login />
              </div>
              <div>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.signupBtn}
                  onClick={handleOpenSignup}
                >
                  Sign Up
                </Button>
                <Signup />
              </div>
              <ForgotPassword />
            </Fragment>
          )}

          {user && (
            <div>
              <NavLink
                to={
                  user && user.user_type === 'provider'
                    ? '/provider/user-info'
                    : '/client/user-info'
                }
              >
                <IconButton
                  className={classes.personBtn}
                  aria-label="open drawer"
                  edge="start"
                >
                  <PersonIcon
                    className={classes.personIcon}
                    style={{ color: '#ffffff' }}
                  />
                </IconButton>
              </NavLink>
            </div>
          )}

          {/* *************** Landing page Small devices Sidebar *************** */}
          <IconButton
            onClick={toggleDrawer}
            className={classes.menuBtn}
            aria-label="open drawer"
            edge="start"
          >
            <MenuIcon fontSize="small" style={{ color: '#ffffff' }} />
          </IconButton>
          <SwipeableDrawer
            anchor="right"
            open={openDrawer}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
          >
            <div onClick={toggleDrawer}>
              <List style={{ marginTop: '15px' }}>
                <NavLink
                  to="/for-provider"
                  className={classes.menuLink}
                  activeClassName={classes.menuActiveLink}
                >
                  <ListItem>
                    <Typography className={classes.navLinkTypography}>
                      For providers
                    </Typography>
                  </ListItem>
                </NavLink>

                <NavLink
                  to="/about"
                  className={classes.menuLink}
                  activeClassName={classes.menuActiveLink}
                >
                  <ListItem>
                    <Typography className={classes.navLinkTypography}>
                      About us
                    </Typography>
                  </ListItem>
                </NavLink>

                {!user && (
                  <Fragment>
                    <ListItem>
                      <Button
                        color="primary"
                        className={classes.menuLoginBtn}
                        onClick={handleOpenLogin}
                      >
                        Login
                      </Button>
                      <Login />
                    </ListItem>

                    <ListItem>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.menuSignupBtn}
                        onClick={handleOpenSignup}
                      >
                        Sign Up
                      </Button>
                      <Signup />
                    </ListItem>
                  </Fragment>
                )}
              </List>
            </div>
          </SwipeableDrawer>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(Navbar);

import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  useMediaQuery,
  Button,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Divider,
  InputAdornment,
  Snackbar,
  Link,
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton, Alert } from '@material-ui/lab';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { loginSignupStyles } from './loginSignupStyles';
import CloseIcon from '@material-ui/icons/Close';
import AuthContext from '../../context/authentication/authContext';
import DialogContext from '../../context/dialogContext/dialogContext';

const Login = (props) => {
  const classes = loginSignupStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const authContext = useContext(AuthContext);
  const dialogContext = useContext(DialogContext);

  const { login, message, clearMsg, isAuthenticated, user_role } = authContext;
  const {
    openLogin,
    handleCloseLogin,
    handleSwitch,
    handleOpenPassword,
  } = dialogContext;

  const { history } = props;

  const [alignment, setAlignment] = useState('left');
  const [userType, setUserType] = useState('client');
  const [user, setUser] = useState({
    email: '',
    password: '',
    user_type: 'client',
  });

  const { email, password, user_type } = user;

  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const [snackbar, setSnackbar] = useState(false);

  useEffect(() => {
    if (isAuthenticated && user_role === 'provider') {
      history.push('/provider/user-info');
    }
    if (isAuthenticated && user_role === 'client') {
      history.push('/client/user-info');
    }
  }, [isAuthenticated, history, user_role]);

  useEffect(() => {
    setUser({
      email: '',
      password: '',
      user_type: 'client',
    });
    setAlignment('left');
    setUserType('client');
    clearMsg();
    setErrMsg(null);
    // eslint-disable-next-line
  }, [openLogin]);

  useEffect(() => {
    setUser({ ...user, user_type: userType });
    // eslint-disable-next-line
  }, [userType]);

  useEffect(() => {
    isAuthenticated === true && handleCloseLogin();
    message !== null || errMsg !== null
      ? setSnackbar(true)
      : setSnackbar(false);
    // eslint-disable-next-line
  }, [message, errMsg, isAuthenticated]);

  const handleSubmit = (e) => {
    e.preventDefault();
    clearMsg();
    if (!email || !password) {
      setErrMsg('Please enter valid email and password');
    } else {
      login({ email, password, user_type });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      newAlignment === 'left' ? setUserType('client') : setUserType('provider');
    }
  };

  const handleSnackbar = () => {
    setErrMsg(null);
    setSnackbar(!snackbar);
  };
  // *********** Provider/Client toggle button style ************
  const StyledToggleButtonGroup = withStyles((theme, alignment) => ({
    grouped: {
      width: '174px',
      padding: theme.spacing(1.5, 2),
      border: 'none',
      '&:not(:first-child)': {
        borderRadius: '200px',
      },
      '&:first-child': {
        borderRadius: '200px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: '150px',
        padding: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        width: '150px',
        padding: theme.spacing(1),
      },
      [theme.breakpoints.down('xs')]: {
        width: '135px',
        padding: theme.spacing(1),
        fontSize: '1rem',
      },
    },
  }))(ToggleButtonGroup);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={openLogin}
        onClose={handleCloseLogin}
        style={{ textAlign: 'center' }}
      >
        {/* *************** DialogTitle ************** */}
        <DialogTitle className={classes.loginDialogTitle}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseLogin}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title}>Welcome back</Typography>
        </DialogTitle>

        {/* ****************** Alert Snackbar ******************* */}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          open={snackbar}
          onClose={handleSnackbar}
        >
          <Alert elevation={6} variant="filled" severity="error">
            {(!email || !password) && errMsg}
            {message !== null && message}
          </Alert>
        </Snackbar>

        {/* ************ DialogContent ************* */}
        <DialogContent style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <TextField
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              color="secondary"
              className={classes.formTextField}
              onChange={handleChange}
              value={email}
              required
            />
            <TextField
              id="password"
              name="password"
              label="Password"
              variant="outlined"
              color="secondary"
              className={classes.formTextField}
              onChange={handleChange}
              value={password}
              required
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <StyledToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
              className={classes.buttonContainer}
            >
              <ToggleButton value="left">I'm Client</ToggleButton>
              <ToggleButton value="right">I'm Provider</ToggleButton>
            </StyledToggleButtonGroup>

            <Button
              variant="contained"
              color="secondary"
              className={classes.loginButton}
              type="submit"
            >
              Login
            </Button>
            <DialogContentText
              className={classes.captionFP}
              style={{ lineHeight: '1.1' }}
            >
              <Typography
                color="textSecondary"
                variant="body1"
                style={{ lineHeight: '1.1' }}
              >
                <Link onClick={handleOpenPassword}>Forgot password?</Link>
              </Typography>
            </DialogContentText>
          </form>
        </DialogContent>

        <Divider />

        {/* *********** DialogActions ************ */}
        <DialogActions
          style={{ justifyContent: 'center' }}
          className={classes.dialogActions}
        >
          <Typography color="textSecondary">Not a member?</Typography>
          <Button
            autoFocus
            color="primary"
            style={{ marginLeft: '0px' }}
            className={classes.actionButton}
            onClick={handleSwitch}
          >
            Signup
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(Login);

import React, { useContext } from 'react';
import AdminContext from '../context/admin/adminContext';
import { Route, Redirect } from 'react-router-dom';

const SecureRoute = ({ component: Component, ...rest }) => {
  const adminContext = useContext(AdminContext);
  const { isAuthenticated, adminToken } = adminContext;
  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !adminToken ? (
          <Redirect to="/admin-login" />
        ) : (
          <Component {...props} />
        )
      }
    ></Route>
  );
};

export default SecureRoute;

// Login/signup action types
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const AUTH_ERROR = 'AUTH_ERROR';
export const USER_LOADED = 'USER_LOADED';
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS';
export const EMAIL_FAIL = 'EMAIL_FAIL';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
export const TOKEN_FAIL = 'TOKEN_FAIL';
export const PASSWORD_UPDATED = 'PASSWORD_UPDATED';
export const PASSWORD_UPDATE_FAIL = 'PASSWORD_UPDATE_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_MSG = 'CLEAR_MSG';

// Provider action types
export const PROVIDER_LOADED = 'PROVIDER_LOADED';
export const MY_PROVIDER_LOADED = 'MY_PROVIDER_LOADED';
export const PROVIDER_ERROR = 'PROVIDER_ERROR';
export const PROVIDER_UPDATED = 'PROVIDER_UPDATED';
export const CREATE_PROVIDER_SUCCESS = 'CREATE_PROVIDER_SUCCESS';
export const CREATE_PROVIDER_FAIL = 'CREATE_PROVIDER_FAIL';
export const GET_REVIEW = 'GET_REVIEW';
export const SET_FEE = 'SET_FEE';
export const GET_FEE = 'GET_FEE';

// Client action types
export const CLIENT_LOADED = 'CLIENT_LOADED';
export const CLIENT_ERROR = 'CLIENT_ERROR';
export const CLIENT_UPDATED = 'CLIENT_UPDATED';
export const FAV_PROVIDER_SUCCESS = 'FAV_PROVIDER_SUCCESS';
export const SET_FAVOURITE = 'SET_FAVOURITE';
export const REMOVE_FAVOURITE = 'REMOVE_FAVOURITE';
export const SEARCH_PROVIDER = 'SEARCH_PROVIDER';
export const NO_PROVIDER_FOUND = 'NO_PROVIDER_FOUND';
export const POST_REVIEW = 'POST_REVIEW';
export const CLIENTS_LOADED = 'CLIENTS_LOADED';

// Dialog action types
export const OPEN_LOGIN = 'OPEN_LOGIN';
export const CLOSE_LOGIN = 'CLOSE_LOGIN';
export const OPEN_SIGNUP = 'OPEN_SIGNUP';
export const CLOSE_SIGNUP = 'CLOSE_SIGNUP';
export const SWITCH_DIALOG = 'SWITCH_DIALOG';
export const OPEN_PASSWORD = 'OPEN_PASSWORD';
export const CLOSE_PASSWORD = 'CLOSE_PASSWORD';

// Google action types
export const GOOGLE_LOADED = 'GOOGLE_LOADED';
export const GOOGLE_RELOAD = 'GOOGLE_RELOAD';
export const GOOGLE_ERROR = 'GOOGLE_ERROR';

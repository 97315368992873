import { makeStyles } from '@material-ui/core/styles';

export const navStyles = makeStyles((theme) => ({
  hide: {
    boxShadow: ' none',
  },
  navLink: {
    textDecoration: 'none',
    padding: '6px 8px',
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  activeLink: {
    color: theme.palette.secondary.main,
    borderTop: '5px solid #97DA91',
    padding: '30px 8px 35px',
  },
  navLinkTypography: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
  loginBtn: {
    marginRight: theme.spacing(2),
    fontSize: '0.9rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  signupBtn: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(1, 2),
    fontSize: '0.9rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuLink: {
    textDecoration: 'none',
    padding: '6px 8px',
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 8, 0, 0),
      display: 'block',
    },
  },
  menuActiveLink: {
    borderLeft: '5px solid #97DA91',
    color: theme.palette.secondary.main,
    padding: '6px 8px 6px 3px',
  },
  menuLoginBtn: {
    marginRight: theme.spacing(2),
    paddingLeft: '5px',
    fontSize: '0.9rem',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  menuSignupBtn: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(1, 2),
    fontSize: '0.9rem',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  menuBtn: {
    display: 'none',
    padding: '10px',
    backgroundColor: theme.palette.secondary.main,
    boxShadow:
      ' 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  personBtn: {
    padding: '10px',
    margin: theme.spacing(0, 1),
    backgroundColor: theme.palette.primary.light,
    boxShadow:
      ' 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2),
    },
  },
  personIcon: {
    [theme.breakpoints.down('sm')]: {
      width: '20px',
      height: '20px',
    },
  },

  linkDiv: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'table',
    },
  },
  logoDiv: {
    width: '200px',
    height: '48px',
    margin: theme.spacing(2),
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      width: '166px',
      height: '40px',
      margin: theme.spacing(2, 0),
    },
  },
  search: {
    marginLeft: theme.spacing(8),
    display: 'inline-block',
    [theme.breakpoints.between('sm', 'md')]: {
      marginLeft: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0),
      maxWidth: '250px',
    },
  },
  inputProps: {
    paddingRight: '0px',
    [theme.breakpoints.down('xs')]: {
      height: '42px',
    },
  },
  searchIcon: {
    color: '#7c9cbb',
  },
  searchButton: {
    color: '#ffffff',
    padding: theme.spacing(1.2, 3),
    margin: theme.spacing(0, 1),
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      height: '32px',
      width: '80px',
    },
  },
}));

import React from 'react';
import {
  Grid,
  Switch,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { filterProviderStyles } from './filterProviderStyles';

const FilterProvider = (props) => {
  const classes = filterProviderStyles();

  const { handleFilterChange, allChecked, toggle, filter } = props;

  return (
    <Grid container alignItems="center" className={classes.grid}>
      <Grid item>
        <FormControlLabel
          className={classes.all}
          control={
            <Checkbox
              checked={allChecked}
              onChange={handleFilterChange}
              name="allChecked"
            />
          }
          label="All"
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          className={classes.switchText}
          control={
            <Switch
              checked={toggle}
              onChange={handleFilterChange}
              name="checked"
            />
          }
          label="Virtual visit"
          labelPlacement="start"
        />
      </Grid>
      <Grid item>
        <FormControl className={classes.specialization}>
          <Select
            name="specialization"
            value={filter}
            disableUnderline
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={handleFilterChange}
          >
            <MenuItem value="All">
              <em>All</em>
            </MenuItem>
            <MenuItem value={'Chiropractor'}>Chiropractor</MenuItem>
            <MenuItem value={'Registered Massage Therapist'}>
              Registered Massage Therapist
            </MenuItem>
            <MenuItem value={'Physiotherapist'}>Physiotherapist</MenuItem>
            <MenuItem value={'Speech Pathologist'}>Speech Pathologist</MenuItem>
            <MenuItem value={'Naturopath'}>Naturopath</MenuItem>
            <MenuItem value={'Psychologist'}>Psychologist</MenuItem>
            <MenuItem value={'Personal Trainer'}>Personal Trainer</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default FilterProvider;

import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthState from './components/context/authentication/AuthState';
import DialogState from './components/context/dialogContext/DialogState';
import ProviderState from './components/context/provider/ProviderState';
import ClientState from './components/context/client/ClientState';
import AdminState from './components/context/admin/AdminState';
import GoogleState from './components/context/google/GoogleState';
import setAuthToken from './components/utility/setAuthToken';
import Homepage from './components/baseComponent/homepage/Homepage';
import AboutUs from './components/baseComponent/aboutUs/AboutUs';
import ForProvider from './components/baseComponent/forProvider/ForProvider';
import SecureRoute from './components/baseComponent/secureRoute/SecureRoute';
import AdminPrivateRoute from './components/admin/AdminPrivateRoute';
import ProviderUserInfo from './components/appComponent/providerUserInfo/ProviderUserInfo';
import ProviderProfile from './components/appComponent/providerProfile/ProviderProfile';
import ProviderAvailability from './components/appComponent/providerAvailability/ProviderAvailability';
import ClientUserInfo from './components/appComponent/clientUserInfo/ClientUserInfo';
import ClientMyprovider from './components/appComponent/clientMyprovider/ClientMyprovider';
import ProviderProfilePreview from './components/appComponent/providerProfilePreview/ProviderProfilePreview';
import SearchProvider from './components/appComponent/searchProvider/SearchProvider';
import AdminLogin from './components/admin/AdminLogin';
import AdminDashboard from './components/admin/AdminDashboard';
import ResetPassword from './components/baseComponent/navbar/ResetPassword';
import Logout from './components/baseComponent/navbar/Logout';
import './App.css';

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  return (
    <Fragment>
      <AuthState>
        <AdminState>
          <DialogState>
            <ProviderState>
              <ClientState>
                <GoogleState>
                  <Router>
                    <Switch>
                      <Route exact path="/" component={Homepage} />
                      <Route exact path="/about" component={AboutUs} />
                      <Route
                        exact
                        path="/for-provider"
                        component={ForProvider}
                      />
                      <Route path="/logout" component={Logout} />
                      <Route path="/search" component={SearchProvider} />
                      <Route exact path="/admin-login" component={AdminLogin} />
                      <Route
                        exact
                        path="/reset-password/:token"
                        component={ResetPassword}
                      />
                      {/* ************** Provider secureRoutes ***************** */}
                      <SecureRoute
                        exact
                        path="/provider/user-info"
                        component={ProviderUserInfo}
                      />
                      <SecureRoute
                        exact
                        path="/provider/profile"
                        component={ProviderProfile}
                      />
                      <SecureRoute
                        exact
                        path="/provider/availability"
                        component={ProviderAvailability}
                      />
                      <SecureRoute
                        path="/provider-public-profile/:id"
                        component={ProviderProfilePreview}
                      />
                      {/* ************** Client secureRoutes ***************** */}
                      <SecureRoute
                        exact
                        path="/client/user-info"
                        component={ClientUserInfo}
                      />
                      <SecureRoute
                        exact
                        path="/client/my-providers"
                        component={ClientMyprovider}
                      />
                      {/* ************** admin private route ***************** */}
                      <AdminPrivateRoute
                        path="/admin-dashboard"
                        component={AdminDashboard}
                      />
                    </Switch>
                  </Router>
                </GoogleState>
              </ClientState>
            </ProviderState>
          </DialogState>
        </AdminState>
      </AuthState>
    </Fragment>
  );
}

export default App;

import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Divider,
  InputAdornment,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { loginSignupStyles } from '../baseComponent/navbar/loginSignupStyles';
import CloseIcon from '@material-ui/icons/Close';
import AdminContext from '../context/admin/adminContext';

const CreateProviderDialog = (props) => {
  const classes = loginSignupStyles();
  const { openDialog, handleCloseDialog } = props;

  const adminContext = useContext(AdminContext);

  const { createProvider, adminMessage, clearMsg } = adminContext;

  const [name, setName] = useState('');
  const [user, setUser] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
    user_type: 'admin',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [pwErr, setPwErr] = useState(false);
  const [pw2Err, setPw2Err] = useState(false);
  const [snackbar, setSnackbar] = useState(true);
  const {
    firstname,
    lastname,
    email,
    password,
    confirmPassword,
    user_type,
  } = user;

  useEffect(() => {
    setName('');
    setNameErr(false);
    setUser({
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmPassword: '',
      user_type: 'admin',
    });
    setEmailErr(false);
    setPwErr(false);
    setPw2Err(false);
    // eslint-disable-next-line
  }, [openDialog]);

  useEffect(() => {
    const nameFormat = (name) => {
      const sptName = name.split(' ');
      const fName = sptName[0];
      const lName = sptName.length > 1 ? sptName[sptName.length - 1] : '';
      setUser({ ...user, firstname: fName, lastname: lName });
    };
    nameFormat(name);
    // eslint-disable-next-line
  }, [name]);

  useEffect(() => {
    adminMessage !== null ? setSnackbar(true) : setSnackbar(false);

    // eslint-disable-next-line
  }, [adminMessage]);

  useEffect(() => {
    if (adminMessage === 'Provider created' && !snackbar) {
      handleCloseDialog();
    }
    if (adminMessage === 'This user already exists' && !snackbar) {
      clearMsg();
    }
    // eslint-disable-next-line
  }, [snackbar]);

  const handleNameChange = (e) => {
    const { value } = e.target;
    setNameErr(false);
    setName(value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    name === 'email' && setEmailErr(false);
    name === 'password' && setPwErr(false);
    name === 'confirmPassword' && setPw2Err(false);
    if (name === 'email') {
      setUser({ ...user, [name]: value.toLowerCase() });
    } else {
      setUser({ ...user, [name]: value });
    }
  };

  const handleSnackbar = () => {
    setSnackbar(!snackbar);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    clearMsg();
    if (!name || !email || !password) {
      !name && setNameErr(true);
      !email && setEmailErr(true);
      !password && setPwErr(true);
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailErr(true);
    } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(password)) {
      setPwErr(true);
    } else if (password !== confirmPassword) {
      setPw2Err(true);
    } else {
      createProvider({ firstname, lastname, email, user_type, password });
    }
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        style={{ textAlign: 'center' }}
      >
        {/* *************** DialogTitle ************** */}
        <DialogTitle className={classes.signupDialogTitle}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseDialog}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title}>Add Provider</Typography>
        </DialogTitle>

        {/* ****************** Alert Snackbar ******************* */}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          open={snackbar}
          onClose={handleSnackbar}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity={adminMessage === 'Provider created' ? 'success' : 'error'}
          >
            {adminMessage}
          </Alert>
        </Snackbar>

        {/* ************ DialogContent form ************* */}
        <DialogContent style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <form
            className={classes.form}
            onSubmit={handleSubmit}
            style={{ paddingBottom: '56px' }}
            noValidate
          >
            <TextField
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              color="secondary"
              className={classes.formTextField}
              onChange={handleNameChange}
              value={name}
              required
              error={nameErr}
              helperText={
                nameErr === true ? 'Name field must not be empty' : ''
              }
            />
            <TextField
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              color="secondary"
              className={classes.formTextField}
              onChange={handleChange}
              value={email}
              required
              error={emailErr}
              helperText={
                emailErr === true ? 'Please enter a valid email address!' : ''
              }
            />
            <TextField
              id="password"
              name="password"
              label="Password"
              variant="outlined"
              color="secondary"
              className={classes.formTextField}
              onChange={handleChange}
              value={password}
              required
              error={pwErr}
              helperText={
                pwErr === true
                  ? 'Must contain at least one number, one uppercase and lowercase letter, and at least 8 or more characters'
                  : ''
              }
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm password"
              variant="outlined"
              color="secondary"
              className={classes.formTextField}
              onChange={handleChange}
              value={confirmPassword}
              required
              error={pw2Err}
              helperText={pw2Err === true ? 'Password does not match' : ''}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              variant="contained"
              color="secondary"
              className={classes.joinButton}
              type="submit"
            >
              Create
            </Button>
          </form>
        </DialogContent>
        <Divider />
      </Dialog>
    </div>
  );
};

export default CreateProviderDialog;

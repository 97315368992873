import { makeStyles } from '@material-ui/core/styles';
import bgImg from '../../../assets/provider-page-bgimg.png';

export const forProviderStyles = makeStyles((theme) => ({
  bannerContainer: {
    position: 'relative',
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '60vh',
    width: '100%',
    '&::before': {
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.primary.main,
      height: '60vh',
      width: '100%',
      opacity: '0.9',
      zIndex: '1',
    },
  },
  titleSecondary: {
    fontSize: '1.5rem',
    fontWeight: 500,
    margin: theme.spacing(12, 32, 0),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1.3rem',
      margin: theme.spacing(9, 15, 0),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      margin: theme.spacing(8, 8, 0),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      margin: theme.spacing(6, 3, 0),
    },
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    margin: theme.spacing(12, 32, 4),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1.6rem',
      margin: theme.spacing(10, 15, 4),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
      margin: theme.spacing(8, 8, 3),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
      margin: theme.spacing(8, 3, 1.5),
    },
  },
  textDisable: {
    fontSize: '1.2rem',
    fontWeight: 500,
    margin: theme.spacing(0, 32, 2),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
      margin: theme.spacing(0, 16, 2),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
      margin: theme.spacing(0, 10, 2),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      margin: theme.spacing(0, 3, 2),
    },
  },
  gridItem: {
    minHeight: '198px',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      minHeight: '0px',
      margin: theme.spacing(2, 0),
    },
  },
  subTitle: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    margin: theme.spacing(1, 2.6),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
      margin: theme.spacing(0, 1.5),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
      margin: theme.spacing(1),
    },
  },
  disabled: {
    fontWeight: '500',
    fontSize: '1rem',
    margin: theme.spacing(0, 5),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.9rem',
      margin: theme.spacing(0, 2.5),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      margin: theme.spacing(0, 3),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
      margin: theme.spacing(0, 3),
    },
  },
  subText: {
    fontWeight: '500',
    fontSize: '1rem',
    margin: theme.spacing(2, 15, 4),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.9rem',
      margin: theme.spacing(2, 10, 3),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      margin: theme.spacing(1, 4, 0),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
      margin: theme.spacing(1, 3, 0),
    },
  },
  planContainer: {
    margin: theme.spacing(10, 0, 7),
    textAlign: 'center',
  },
  planItem: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0),
    },
  },
  card: {
    minHeight: '350px',
    '&:hover': {
      boxShadow: '-3px 7px 30px -17px rgba(0,0,0,0.75)',
    },
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    fontSize: '2rem',
    fontWeight: 'bold',
    padding: theme.spacing(4, 2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.7rem',
    },
  },
  regularPrice: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
    padding: theme.spacing(2, 2, 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
      padding: theme.spacing(2, 1),
    },
  },
  currentPrice: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.1rem',
      padding: theme.spacing(2, 1),
    },
  },
  currentPriceSubText: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    padding: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
    },
  },
  listText: {
    fontSize: '1.1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  badges: {
    height: '90px',
    width: '90px',
    borderRadius: '40%',
    backgroundColor: theme.bgcolor,
    margin: theme.spacing(4, 'auto'),
  },
  direction: {
    textAlign: 'center',
    marginTop: '5%',
    color: theme.bgcolor,
    [theme.breakpoints.between('sm', 'md')]: {
      marginTop: '7%',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '8%',
    },
  },
  arrowRight: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  arrowDown: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'inline-block',
    },
  },
  faqContainer: {
    width: '70%',
    margin: theme.spacing(5, 'auto', 20),
    [theme.breakpoints.between('sm', 'md')]: {
      width: '90%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '88%',
      margin: theme.spacing(5, 'auto', 15),
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: theme.spacing(5, 'auto', 10),
    },
  },
  expansionPanel: {
    minWidth: '100%',
  },
  faqTitle: {
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: theme.spacing(2),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
      margin: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
      margin: theme.spacing(1),
    },
  },
  faqDisabled: {
    fontSize: '1rem',
    fontWeight: 500,
    margin: theme.spacing(0, 2, 3),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  faqInfo: {
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: theme.spacing(2, 4),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
      margin: theme.spacing(1, 3),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
      margin: theme.spacing(1, 3),
    },
  },

  testimonial: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },

  signupCTA: {
    marginBottom: theme.spacing(3),
  },
}));

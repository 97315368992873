import React, { Fragment, useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import DashboardNavBar from '../appbar/DashboardNavBar';
import SideBar from '../sidebar/SideBar';
import SetAvailibility from '../setAvailability/SetAvailability';
import {
  Button,
  Typography,
  Divider,
  FormControl,
  OutlinedInput,
  InputLabel,
  IconButton,
  InputAdornment,
  Grid,
  NativeSelect,
  Paper,
  Container,
  Snackbar,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import { Link } from 'react-router-dom';
import { providerProfileStyles } from './providerProfileStyles';
import AuthContext from '../../context/authentication/authContext';
import ProviderContext from '../../context/provider/providerContext';
import GoogleAutocomplete from '../googleAutocomplete/googleAutocomplete';

const FEE_NAME = 'FEE_NAME';
const TIME_VALUE = 'TIME_VALUE';
const FEE_VALUE = 'FEE_VALUE';

const ProviderProfile = (props) => {
  const classes = providerProfileStyles();

  const authContext = useContext(AuthContext);
  const providerContext = useContext(ProviderContext);

  const { user } = authContext;
  const {
    getProvider,
    providerLoading,
    provider,
    updateProvider,
    providerMsg,
    clearMsg,
    getFee,
    setFee,
    feeStructure,
    feeLoading,
  } = providerContext;

  const [specializationText, setSpecializationText] = useState('');
  const [skills, setSkills] = useState([]);
  const [hours, setHours] = useState([]);
  const [dayHours, setDayHours] = useState({
    id: '',
    day: '',
    start: '',
    end: '',
  });
  const [saveBtn, setSaveBtn] = useState(true);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [localUser, setLocalUser] = useState({
    about: '',
    role: '',
    specialization: [],
    clinic_name: '',
    clinic_street: '',
    clinic_unit: '',
    clinic_city: '',
    clinic_province: 'ON',
    clinic_postal: '',
    clinic_phone: '',
    fees: '',
    clinic_hours: [],
    clinic_link: '',
    home_visit_location: '',
    home_visit_distance: '',
    home_visit_lat: '',
    home_visit_lng: '',
    travel_visit: '',
  });
  const THREE_FEE_STRUCTURE = 'threeFeeStructure';
  const HOURLY_FEE_STRUCTURE = 'hourlyFeeStructure';
  const [feeChoice, setFeeChoice] = useState(THREE_FEE_STRUCTURE);
  const [threeFeeStructure, setThreeFeeStructure] = useState({
    initialName: '',
    followUpName: '',
    complexName: '',
    initialFee: '',
    followUpFee: '',
    complexFee: '',
  });

  const {
    initialName,
    followUpName,
    complexName,
    initialFee,
    followUpFee,
    complexFee,
  } = threeFeeStructure;

  const [feeName, setFeeName] = useState('');
  const [feeValue, setFeeValue] = useState('');
  const [timeValue, setTimeValue] = useState('');
  const [hourlyFeeList, setHourlyFeeList] = useState([]);
  const [threeFeeList, setThreeFeeList] = useState([]);

  const {
    about,
    role,
    clinic_name,
    clinic_city,
    clinic_street,
    clinic_unit,
    clinic_postal,
    clinic_phone,
    clinic_province,
    clinic_link,
    fees,
    home_visit_location,
    home_visit_distance,
    home_visit_lat,
    home_visit_lng,
    travel_visit,
  } = localUser;

  const [autoCompleteOption, setAutoCompleteOption] = useState(undefined);
  const [autoCompleteClinic, setAutoCompleteClinic] = useState(undefined);
  const [googleToken, setGoogleToken] = useState('');

  useEffect(() => {
    user &&
      user.user_type === 'client' &&
      props.history.push('/client/user-info');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (providerLoading) getProvider(user.public_id);

    const modifiedSpecialization = provider.specialization
      ? provider.specialization.map((s) => s.name)
      : [];

    setLocalUser({
      about: provider.about,
      role: provider.role,
      specialization: modifiedSpecialization,
      clinic_name: provider.clinic_name,
      clinic_street: provider.clinic_street,
      clinic_unit: provider.clinic_unit,
      clinic_city: provider.clinic_city,
      clinic_province: provider.clinic_province,
      clinic_postal: provider.clinic_postal,
      clinic_phone: provider.clinic_phone,
      fees: provider.fees,
      clinic_hours: provider.clinic_hours,
      clinic_link: provider.clinic_link,
      home_visit_location: provider.home_visit_location,
      home_visit_distance: provider.home_visit_distance,
      home_visit_lat: provider.home_visit_lat,
      home_visit_lng: provider.home_visit_lng,
      travel_visit: provider.travel_visit,
    });

    setSkills(modifiedSpecialization || []);
    setHours(provider.clinic_hours);

    if (!feeLoading) {
      const feeName = feeStructure.feeStructure;
      const feeValues = feeStructure.feeValues;
      setFeeChoice(feeName);

      if (feeValues.length !== 0)
        if (feeName === THREE_FEE_STRUCTURE) {
          setThreeFeeStructure({
            initialName: feeValues[0].name,
            followUpName: feeValues[1].name,
            complexName: feeValues[2].name,
            initialFee: feeValues[0].fee,
            followUpFee: feeValues[1].fee,
            complexFee: feeValues[2].fee,
          });

          setThreeFeeList([
            {
              feeName: feeValues[0].name,
              feeValue: feeValues[0].fee,
            },
            {
              feeName: feeValues[1].name,
              feeValue: feeValues[1].fee,
            },
            {
              feeName: feeValues[2].name,
              feeValue: feeValues[2].fee,
            },
          ]);
        } else if (feeName === HOURLY_FEE_STRUCTURE) {
          const tempList = feeValues.map((value) => {
            return {
              feeName: value.name,
              feeValue: value.fee,
              timeValue: value.minutes,
            };
          });
          setHourlyFeeList(tempList);
        }
    }
    if (feeLoading === true) getFee(user.public_id);

    // eslint-disable-next-line
  }, [providerLoading, feeLoading]);

  useEffect(() => {
    providerMsg === 'Changes saved successfully' && setSnackbar(true);
    // eslint-disable-next-line
  }, [provider]);

  useEffect(() => {
    if (autoCompleteOption !== undefined && autoCompleteOption !== null) {
      if (autoCompleteOption.description !== home_visit_location) {
        let service = new window.google.maps.places.PlacesService(
          document.getElementById('map')
        );

        service.getDetails(
          {
            placeId: autoCompleteOption.place_id,
            fields: ['geometry'],
            sessionToken: googleToken,
          },
          (results, status) => {
            if (status === 'OK') {
              setLocalUser((prevState) => {
                return {
                  ...prevState,
                  travel_visit: true,
                  home_visit_location: autoCompleteOption.description,
                  home_visit_lat: results.geometry.location.lat(),
                  home_visit_lng: results.geometry.location.lng(),
                };
              });
            }
          }
        );
      }
    } else if (autoCompleteOption !== undefined) {
      setLocalUser((prevState) => {
        return {
          ...prevState,
          travel_visit: false,
          home_visit_location: '',
          home_visit_lat: 0,
          home_visit_lng: 0,
          home_visit_distance: 0,
        };
      });
    }
  }, [autoCompleteOption]);

  useEffect(() => {
    if (window.google) {
      if (autoCompleteClinic !== undefined && autoCompleteClinic !== null) {
        const ontario = new window.google.maps.LatLng(51.2538, 85.3232);
        const map = new window.google.maps.Map(document.getElementById('map'), {
          center: ontario,
          zoom: 17,
        });

        const service = new window.google.maps.places.PlacesService(map);
        const request = {
          placeId: autoCompleteClinic.place_id,
          fields: ['address_component'],
          sessionToken: googleToken,
        };

        service.getDetails(request, (result, status) => {
          setSaveBtn(false);
          let street = '';
          let route = '';
          let city = '';
          let postalCode = '';

          result.address_components.map((component) => {
            component.types.map((type) => {
              if (type === 'street_number' && street === '')
                street = component.long_name;
              if (type === 'route' && route === '') route = component.long_name;
              if (type === 'locality' || type === 'sublocality_level_1') {
                if (city === '') {
                  city = component.long_name;
                }
              }
              if (type === 'postal_code' && postalCode === '')
                postalCode = component.long_name;
            });
          });

          street += ` ${route}`;
          setLocalUser((prevState) => {
            return {
              ...prevState,
              clinic_city: city,
              clinic_street: street,
              clinic_postal: postalCode,
            };
          });
        });
      }
    }
  }, [autoCompleteClinic]);

  const handleSubmit = async () => {
    updateProvider(
      {
        about,
        role,
        clinic_name,
        clinic_city,
        clinic_street,
        clinic_unit,
        clinic_postal,
        clinic_phone,
        clinic_province,
        specialization: skills,
        fees,
        clinic_hours: hours,
        clinic_link,
        home_visit_location,
        home_visit_distance,
        home_visit_lat,
        home_visit_lng,
        travel_visit,
      },
      user.public_id
    );

    if (feeChoice === THREE_FEE_STRUCTURE) {
      let emptyField = false;
      for (let i = 0; i < threeFeeList.length; i++) {
        if (
          threeFeeList[i].feeName === '' ||
          threeFeeList[i].feeValue === '' ||
          threeFeeList[i].feeValue === 0
        )
          emptyField = true;
      }
      if (!emptyField)
        setFee({ name: feeChoice, values: threeFeeList }, provider.public_id);
      else {
        setSnackbarMsg('Service name, and Fee values cannot be empty');
        setSnackbar(true);
        setSnackbarSeverity('error');
        return;
      }
    }

    if (feeChoice === HOURLY_FEE_STRUCTURE) {
      setFee({ name: feeChoice, values: hourlyFeeList }, provider.public_id);
    }

    setSnackbarMsg('Changes saved successfully');
    setSnackbarSeverity('success');
    setSaveBtn(true);
  };

  // handling Availability Input Changes
  const handleHourChange = (e) => {
    const { name, value } = e.target;
    setDayHours({ ...dayHours, id: uuidv4(), [name]: value });
  };

  const handleHourClick = () => {
    setHours([...hours, dayHours]);
    setDayHours({
      id: '',
      day: '',
      start: '',
      end: '',
    });
    setSaveBtn(false);
  };

  const handleSnackbar = () => {
    setSnackbar(!snackbar);
    clearMsg();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSaveBtn(false);
    name === 'specialization' && setSpecializationText(value);
    setLocalUser({ ...localUser, [name]: value });
  };

  const handleAddClick = () => {
    if (specializationText !== '' && !skills.includes(specializationText)) {
      setSkills([...skills, specializationText]);
    }
    setSaveBtn(false);
    setSpecializationText('');
  };

  const handleRemoveSkill = (e) => {
    const currentSkills = skills;
    const filteredSkills = currentSkills.filter(
      (skill) => skill !== e.currentTarget.name
    );
    setSaveBtn(false);
    setSkills(filteredSkills);
  };

  const handleRemoveHour = (e) => {
    const currentHours = hours;
    const filteredhours = currentHours.filter(
      (hour) => hour.id !== e.currentTarget.name
    );
    setSaveBtn(false);
    setHours(filteredhours);
  };

  const handleFeeStructureChoice = (e) => {
    setFeeChoice(e.target.value);
  };

  const handleHourlyChange = (e) => {
    switch (e.target.name) {
      case FEE_NAME:
        setFeeName(e.target.value);
        break;
      case FEE_VALUE:
        setFeeValue(e.target.value);
        break;
      case TIME_VALUE:
        setTimeValue(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleHourlyClick = (e) => {
    if (feeName !== '' && feeValue > 0 && timeValue > 0) {
      setHourlyFeeList((prevArray) => [
        ...prevArray,
        { feeName: feeName, feeValue: feeValue, timeValue: timeValue },
      ]);
      setFeeName('');
      setTimeValue('');
      setFeeValue('');
      setSaveBtn(false);
    }
  };

  const handleHourlyRemoval = (e) => {
    const removedIndex = parseInt(e.currentTarget.name);

    setHourlyFeeList(
      hourlyFeeList.filter((selection, index) => index !== removedIndex)
    );
    setSaveBtn(false);
  };

  const handleThreeChange = (e) => {
    const { name, value } = e.target;
    setThreeFeeStructure({
      ...threeFeeStructure,
      [name]: value,
    });
    setThreeFeeList([
      {
        feeName: threeFeeStructure.initialName,
        feeValue: threeFeeStructure.initialFee,
      },
      {
        feeName: threeFeeStructure.followUpName,
        feeValue: threeFeeStructure.followUpFee,
      },
      {
        feeName: threeFeeStructure.complexName,
        feeValue: threeFeeStructure.complexFee,
      },
    ]);
    setSaveBtn(false);
  };

  return (
    <Fragment>
      <DashboardNavBar />
      <SideBar />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={2000}
        open={snackbar}
        onClose={handleSnackbar}
      >
        <Alert elevation={6} variant="filled" severity={snackbarSeverity}>
          {snackbarMsg}
        </Alert>
      </Snackbar>

      <div className={classes.root}>
        <div className={classes.divContainer}>
          {/* ******************* Preview button ************************* */}
          <Link
            to={`/provider-public-profile/${user.public_id}`}
            className={classes.previewLink}
          >
            <Button
              variant="outlined"
              color="secondary"
              className={classes.previewBtn}
            >
              View my profile as others see it
            </Button>
          </Link>

          {/* ******************* About textarea ************************* */}
          <Typography color="primary" variant="h6" className={classes.title}>
            About
          </Typography>
          <textarea
            type="text"
            id="about"
            name="about"
            className={classes.textArea}
            placeholder="Describe yourself here..."
            value={about}
            onChange={handleChange}
          />
          <div className={classes.divider}>
            <Divider />
          </div>

          {/* ******************* Role ************************* */}
          <Typography color="primary" variant="h6" className={classes.title}>
            Role
          </Typography>

          <FormControl
            variant="outlined"
            className={classes.clinicInfoFormControl}
          >
            <InputLabel>Role</InputLabel>
            <Select
              value={role}
              onChange={handleChange}
              label="role"
              name="role"
            >
              <MenuItem value="">
                <em>Select</em>
              </MenuItem>
              <MenuItem value={'Registered Massage Therapist'}>
                Registered Massage Therapist
              </MenuItem>
              <MenuItem value={'Chiropractor'}>Chiropractor</MenuItem>
              <MenuItem value={'Physiotherapist'}>Physiotherapist</MenuItem>
              <MenuItem value={'Speech Pathologist'}>
                Speech Pathologist
              </MenuItem>
              <MenuItem value={'Naturopath'}>Naturopath</MenuItem>
              <MenuItem value={'Psychologist'}>Psychologist</MenuItem>
              <MenuItem value={'Personal Trainer'}>Personal Trainer</MenuItem>
            </Select>
          </FormControl>

          <div className={classes.divider}>
            <Divider />
          </div>

          {/* ******************* Specialization Section ************************* */}
          <Typography color="primary" variant="h6" className={classes.title}>
            Specializations
          </Typography>
          <FormControl className={classes.formControl}>
            <InputLabel shrink htmlFor="specialization">
              Add specialization
            </InputLabel>
            <OutlinedInput
              id="specialization"
              name="specialization"
              value={specializationText}
              onChange={handleChange}
              className={classes.skillsInput}
              color="secondary"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="add"
                    edge="end"
                    className={classes.addBtn}
                    onClick={handleAddClick}
                  >
                    <AddIcon style={{ color: '#ffffff' }} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>

          {/* ******************** Add Skills Section ****************** */}
          <div style={{ width: '75%' }}>
            {skills &&
              skills.map((skill, index) => (
                <Paper
                  variant="outlined"
                  elevation={0}
                  className={classes.paper}
                  key={`${skill}${index}`}
                >
                  {skill}
                  <IconButton
                    aria-label="remove"
                    className={classes.removeIconBtn}
                    onClick={handleRemoveSkill}
                    name={skill}
                  >
                    <CloseIcon className={classes.removeIcon} />
                  </IconButton>
                </Paper>
              ))}
          </div>
          <div className={classes.divider}>
            <Divider />
          </div>

          {/* ************************* Fee Structures *********************** */}
          <Typography color="primary" variant="h6" className={classes.title}>
            Fee Structure
          </Typography>

          <Container
            maxWidth="md"
            style={{ marginLeft: '0px', paddingLeft: '0px' }}
          >
            <FormControl className={classes.feeSelectionFormControl}>
              <InputLabel shrink htmlFor="feeChoice">
                Fee Structure
              </InputLabel>
              <NativeSelect
                value={feeChoice}
                onChange={handleFeeStructureChoice}
                input={
                  <OutlinedInput
                    id="feeChoice"
                    name="feeChoice"
                    type="text"
                    color="secondary"
                    className={classes.feeInput}
                  />
                }
              >
                <option value={THREE_FEE_STRUCTURE}>3 Fee Structure</option>
                <option value={HOURLY_FEE_STRUCTURE}>
                  Hourly Fee Structure
                </option>
              </NativeSelect>
            </FormControl>
          </Container>

          {/* ************************* Three Fee Structure *********************** */}
          {feeChoice === THREE_FEE_STRUCTURE ? (
            <Container
              maxWidth="md"
              style={{ marginLeft: '0px', paddingLeft: '0px' }}
            >
              <Grid container>
                <Grid item xs={5} className={classes.grid}>
                  <FormControl className={classes.feeInputFormControl}>
                    <InputLabel shrink htmlFor="initialName">
                      Initial Assessment
                    </InputLabel>
                    <OutlinedInput
                      id="initialName"
                      name="initialName"
                      type="text"
                      color="secondary"
                      className={classes.feeInput}
                      value={initialName}
                      onChange={handleThreeChange}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{ marginLeft: '10px' }}
                  className={classes.grid}
                >
                  <FormControl className={classes.feeInputFormControl}>
                    <InputLabel shrink htmlFor="initialFee">
                      Fee
                    </InputLabel>
                    <OutlinedInput
                      id="initialFee"
                      name="initialFee"
                      type="number"
                      color="secondary"
                      className={classes.feeInput}
                      value={initialFee}
                      onChange={handleThreeChange}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5} className={classes.grid}>
                  <FormControl className={classes.feeInputFormControl}>
                    <InputLabel shrink htmlFor="followUpName">
                      Follow-Up Treatment
                    </InputLabel>
                    <OutlinedInput
                      id="followUpName"
                      name="followUpName"
                      type="text"
                      color="secondary"
                      className={classes.feeInput}
                      value={followUpName}
                      onChange={handleThreeChange}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{ marginLeft: '10px' }}
                  className={classes.grid}
                >
                  <FormControl className={classes.feeInputFormControl}>
                    <InputLabel shrink htmlFor="followUpFee">
                      Fee
                    </InputLabel>
                    <OutlinedInput
                      id="followUpFee"
                      name="followUpFee"
                      type="number"
                      color="secondary"
                      className={classes.feeInput}
                      value={followUpFee}
                      onChange={handleThreeChange}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={5} className={classes.grid}>
                  <FormControl className={classes.feeInputFormControl}>
                    <InputLabel shrink htmlFor="complexName">
                      Complex Treatment
                    </InputLabel>
                    <OutlinedInput
                      id="complexName"
                      name="complexName"
                      type="text"
                      color="secondary"
                      className={classes.feeInput}
                      value={complexName}
                      onChange={handleThreeChange}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{ marginLeft: '10px' }}
                  className={classes.grid}
                >
                  <FormControl className={classes.feeInputFormControl}>
                    <InputLabel shrink htmlFor="complexFee">
                      Fee
                    </InputLabel>
                    <OutlinedInput
                      id="complexFee"
                      name="complexFee"
                      type="number"
                      color="secondary"
                      className={classes.feeInput}
                      value={complexFee}
                      onChange={handleThreeChange}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Container>
          ) : null}

          {/* *********************** Hourly Fee Structure ********************** */}
          {feeChoice === HOURLY_FEE_STRUCTURE ? (
            <Container
              maxWidth="md"
              style={{ marginLeft: '0px', paddingLeft: '0px' }}
            >
              <Grid container alignItems="flex-end">
                <Grid item xs={12} sm={5} className={classes.hourlyGrid}>
                  <FormControl className={classes.feeHourlyFormControl}>
                    <InputLabel shrink htmlFor={FEE_NAME}>
                      Service Name
                    </InputLabel>
                    <OutlinedInput
                      id={FEE_NAME}
                      name={FEE_NAME}
                      type="text"
                      color="secondary"
                      placeholder="Massage Therapy"
                      className={classes.feeInput}
                      value={feeName}
                      onChange={handleHourlyChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={5} className={classes.midpointMargin}>
                  <FormControl className={classes.feeHourlyFormControl}>
                    <InputLabel shrink htmlFor={TIME_VALUE}>
                      Length of Time
                    </InputLabel>
                    <OutlinedInput
                      id={TIME_VALUE}
                      name={TIME_VALUE}
                      type="number"
                      color="secondary"
                      placeholder="30"
                      className={classes.numberInput}
                      value={timeValue}
                      onChange={handleHourlyChange}
                      endAdornment={
                        <InputAdornment position="end">min</InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={5} className={classes.hourlyGrid}>
                  <FormControl className={classes.feeHourlyFormControl}>
                    <InputLabel shrink htmlFor={FEE_VALUE}>
                      Fee
                    </InputLabel>
                    <OutlinedInput
                      id={FEE_VALUE}
                      name={FEE_VALUE}
                      type="number"
                      color="secondary"
                      placeholder="100"
                      className={classes.numberInput}
                      value={feeValue}
                      onChange={handleHourlyChange}
                      startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={5} className={classes.hourlyGrid}>
                  <IconButton
                    aria-label="add"
                    className={classes.hourlyButton}
                    onClick={handleHourlyClick}
                  >
                    <AddIcon style={{ color: '#ffffff' }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Container>
          ) : null}
          {feeChoice === 'hourlyFeeStructure' &&
            hourlyFeeList.map((fee, key) => (
              <Grid
                container
                key={key}
                spacing={1}
                style={{ marginTop: '20px' }}
              >
                <Grid item xs={12} sm={2}>
                  <TextField
                    disabled
                    label="Service Name"
                    className={classes.displayHourly}
                    value={fee.feeName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    disabled
                    label="Length of Time"
                    className={classes.displayHourly}
                    value={fee.timeValue}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">min</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <TextField
                    disabled
                    label="Fee"
                    className={classes.displayHourly}
                    value={fee.feeValue}
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <IconButton
                    aria-label="subtract"
                    name={key}
                    className={classes.minusButton}
                    onClick={handleHourlyRemoval}
                  >
                    <RemoveIcon style={{ color: '#ffffff' }} />
                  </IconButton>
                </Grid>
              </Grid>
            ))}

          <div className={classes.divider}>
            <Divider />
          </div>

          {/* *********************** Clinic Info form ********************** */}
          <Typography color="primary" variant="h6" className={classes.title}>
            Clinic information
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Enter your clinic information that clients can see and visit if they
            choose to.
          </Typography>
          <div className={classes.clinicInfoForm}>
            <Container
              maxWidth="md"
              style={{ paddingLeft: '0px', marginLeft: '0px' }}
            >
              <Grid container>
                <Grid item xs={12} className={classes.grid}>
                  <FormControl className={classes.clinicInfoFormControl}>
                    <InputLabel shrink htmlFor="clinic_name">
                      Clinic name
                    </InputLabel>
                    <OutlinedInput
                      disabled={false}
                      id="clinic_name"
                      name="clinic_name"
                      color="secondary"
                      type="text"
                      className={classes.clinicInfoInput}
                      value={clinic_name}
                      onChange={handleChange}
                    />
                  </FormControl>

                  <FormControl className={classes.clinicInfoFormControl}>
                    <InputLabel shrink htmlFor="clinic_link">
                      Website
                    </InputLabel>
                    <OutlinedInput
                      disabled={false}
                      id="clinic_link"
                      name="clinic_link"
                      color="secondary"
                      type="url"
                      className={classes.clinicInfoInput}
                      value={clinic_link}
                      onChange={handleChange}
                    />
                  </FormControl>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.streetContainer}
                  >
                    <Grid item={12} className={classes.autoCompleteGrid}>
                      <GoogleAutocomplete
                        label="Street Address"
                        placeholder="2020 Street"
                        full={false}
                        receiveData={(option) => {
                          setAutoCompleteClinic(option);
                          setSaveBtn(false);
                        }}
                        receiveToken={(token) => setGoogleToken(token)}
                        currentPlace={clinic_street ? clinic_street : ''}
                      />
                    </Grid>
                  </Grid>

                  <FormControl className={classes.clinicInfoFormControl}>
                    <InputLabel shrink htmlFor="clinic_unit">
                      Unit/Suit
                    </InputLabel>
                    <OutlinedInput
                      id="clinic_unit"
                      name="clinic_unit"
                      type="text"
                      color="secondary"
                      className={classes.clinicInfoInput}
                      value={clinic_unit}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={5} className={classes.grid}>
                  <FormControl>
                    <InputLabel shrink htmlFor="clinic_city">
                      City
                    </InputLabel>
                    <OutlinedInput
                      id="clinic_city"
                      name="clinic_city"
                      type="text"
                      color="secondary"
                      className={classes.clinicInfoInput2}
                      value={clinic_city}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={5} className={classes.grid}>
                  <FormControl>
                    <InputLabel shrink htmlFor="clinic_province">
                      Province
                    </InputLabel>
                    <NativeSelect
                      disabled
                      value={clinic_province}
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          id="clinic_province"
                          name="clinic_province"
                          type="text"
                          color="secondary"
                          className={classes.clinicInfoInput2}
                        />
                      }
                    >
                      <option value="AB">Alberta</option>
                      <option value="BC">British Columbia</option>
                      <option value="MB">Manitoba</option>
                      <option value="NB">New Brunswick</option>
                      <option value="NL">Newfoundland and Labrador</option>
                      <option value="NT">Northwest Territories</option>
                      <option value="NS">Nova Scotia</option>
                      <option value="NU">Nunavut</option>
                      <option value="ON">Ontario</option>
                      <option value="PE">Prince Edward Island</option>
                      <option value="QC">Quebec</option>
                      <option value="SK">Saskatchewan</option>
                      <option value="YT">Yukon</option>
                    </NativeSelect>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12} md={5} className={classes.grid}>
                  <FormControl>
                    <InputLabel shrink htmlFor="clinic_postal">
                      Postal Code
                    </InputLabel>
                    <OutlinedInput
                      id="clinic_postal"
                      name="clinic_postal"
                      type="text"
                      color="secondary"
                      className={classes.clinicInfoInput2}
                      value={clinic_postal}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={5} className={classes.grid}>
                  <FormControl>
                    <InputLabel shrink htmlFor="clinic_phone">
                      Phone number
                    </InputLabel>
                    <OutlinedInput
                      id="phone"
                      name="clinic_phone"
                      type="tel"
                      color="secondary"
                      className={classes.clinicInfoInput2}
                      value={clinic_phone}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <div className={classes.divider}>
                <Divider />
              </div>

              {/* ************* Availability hours for clinic **************** */}
              <Typography
                color="primary"
                variant="h6"
                className={classes.title}
              >
                Add your availability for clinic visit
              </Typography>
              <SetAvailibility
                handleHourChange={handleHourChange}
                handleHourClick={handleHourClick}
                dayHours={dayHours}
              />

              {/* ************* Timing paper **************** */}
              <div style={{ width: '75%' }}>
                {hours &&
                  hours.map(({ day, start, end, id }) => (
                    <Paper
                      variant="outlined"
                      elevation={0}
                      className={classes.paperHours}
                      key={id}
                    >
                      {day} - {start} to {end}
                      <IconButton
                        aria-label="remove"
                        className={classes.removeIconBtn}
                        onClick={handleRemoveHour}
                        name={id}
                      >
                        <CloseIcon className={classes.removeIcon} />
                      </IconButton>
                    </Paper>
                  ))}
              </div>
            </Container>
            <Button
              disabled={saveBtn}
              variant="contained"
              color="secondary"
              className={classes.saveButton}
              onClick={handleSubmit}
            >
              Save changes
            </Button>
            <div id="map"></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProviderProfile;

import { makeStyles } from '@material-ui/core/styles';

export const footerStyles = makeStyles((theme) => ({
  toolbar: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      padding: theme.spacing(0),
      margin: theme.spacing(2, 0, 3),
    },
  },
  copyright: {
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      flex: '1 0 100%',
    },
  },
  typography: {
    color: theme.palette.text.disabled,
    fontSize: '1rem',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
  },
  footerLink: {
    textDecoration: 'none',
    padding: '6px 8px',
    marginRight: theme.spacing(2),
    [theme.breakpoints.between('sm', 'md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0, 2),
      padding: '0px 5px',
      order: '-1',
      borderRight: '1px solid #3a5275',
    },
  },
  contactLink: {
    textDecoration: 'none',
    padding: '6px 8px',
    marginRight: theme.spacing(2),
    [theme.breakpoints.between('sm', 'md')]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 0, 2),
      padding: '0px 5px',
      order: '-1',
    },
  },
  footerTypography: {
    fontSize: '1rem',
    fontWeight: 'bold',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
    },
  },
}));

import React, { Fragment, useContext } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Badge,
  Button,
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { useStyles } from '../../themes/commonStyles';
import { adminStyles } from './adminStyles';
import logo from '../../assets/MP-website-image.png';
import AdminContext from '../context/admin/adminContext';

const AdminNavbar = () => {
  const classesBase = useStyles();
  const classes = adminStyles();

  const adminContext = useContext(AdminContext);
  const { adminLogout } = adminContext;

  // Avatar style
  const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: '#97DA91',
      color: '#97DA91',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        content: '""',
      },
    },
  }))(Badge);

  const handleAdminLogout = () => {
    adminLogout();
  };

  return (
    <div>
      <AppBar color="inherit" position="fixed">
        <Toolbar>
          <div className={classes.div}>
            <div className={classes.logoDiv}>
              <img src={logo} alt="logo" className={classesBase.logo} />
            </div>
          </div>

          {localStorage.adminToken && (
            <Fragment>
              {/* ***************** Navlink ******************** */}
              <NavLink
                to="/admin-dashboard/all-providers"
                className={classes.navLink}
                activeClassName={classes.activeLink}
              >
                <Typography className={classes.navLinkTypography}>
                  Providers
                </Typography>
              </NavLink>

              <NavLink
                to="/admin-dashboard/all-clients"
                className={classes.navLink}
                activeClassName={classes.activeLink}
              >
                <Typography className={classes.navLinkTypography}>
                  Clients
                </Typography>
              </NavLink>

              <NavLink
                to="/admin-login"
                className={classes.navLink}
                activeClassName={classes.activeLink}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.navLinkBtn}
                  onClick={handleAdminLogout}
                >
                  Logout
                </Button>
              </NavLink>

              <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar alt="user pic" src="" className={classes.userPic} />
              </StyledBadge>
            </Fragment>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default AdminNavbar;

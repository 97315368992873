import { makeStyles } from '@material-ui/core/styles';

export const searchProviderStyles = makeStyles((theme) => ({
  root: {
    marginTop: '50px',
  },
  grid: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  resultText: {
    marginLeft: theme.spacing(6),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(4),
    },
  },
}));

import React, { useContext, useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  IconButton,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import UserCard from './UserCard';
import CreateProviderDialog from './CreateProviderDialog';
import { adminStyles } from './adminStyles';
import AdminContext from '../context/admin/adminContext';

const AllProviders = () => {
  const classes = adminStyles();

  const adminContext = useContext(AdminContext);
  const { getProviders, allProviders, clearMsg } = adminContext;

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    clearMsg();
    setOpenDialog(false);
  };

  useEffect(() => {
    getProviders();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.usersRoot}>
      <CreateProviderDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
      <Container maxWidth="xl">
        <Grid container>
          <Paper className={classes.paper}>
            <Grid
              container
              alignItems="center"
              justify="center"
              direction="column"
              className={classes.addBtnGrid}
            >
              <Typography color="textSecondary" style={{ marginBottom: '5%' }}>
                Add new Provider
              </Typography>
              <IconButton className={classes.addBtn} onClick={handleOpenDialog}>
                <AddIcon style={{ color: '#ffffff' }} />
              </IconButton>
            </Grid>
          </Paper>
          {allProviders &&
            allProviders.map((provider) => {
              return <UserCard provider={provider} key={provider.public_id} />;
            })}
        </Grid>
      </Container>
    </div>
  );
};

export default AllProviders;

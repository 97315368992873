import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Paper, Grid, Typography, Switch } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import RoomIcon from '@material-ui/icons/Room';
import { adminStyles } from './adminStyles';
import AuthContext from '../context/authentication/authContext';
import ProviderContext from '../context/provider/providerContext';

const UserCard = (props) => {
  const classes = adminStyles();
  const { provider, history } = props;
  const authContext = useContext(AuthContext);
  const providerContext = useContext(ProviderContext);

  const { login, user, user_role } = authContext;

  const { updateProvider } = providerContext;

  const {
    firstname,
    lastname,
    role,
    city,
    province,
    email,
    home_visit,
    user_type,
    profile_pic,
  } = provider;

  const [approved, setApproved] = useState(provider.approved);

  useEffect(() => {
    if (user && user_role === 'provider') {
      history.push('/provider/user-info');
    }
    if (user && user_role === 'client') {
      history.push('/client/user-info');
    }
    // eslint-disable-next-line
  }, [user]);

  const handleClick = () => {
    login({ email, user_type });
  };

  useEffect(() => {
    setApproved(provider.approved);
  }, [provider]);

  const handleApprovedToggleChange = (e) => {
    setApproved(e.target.checked);

    updateProvider(
      {
        approved: e.target.checked,
      },
      provider.public_id
    );
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.grid}
        >
          {home_visit && (
            <div className={classes.homeIcon}>
              <HomeIcon />
            </div>
          )}
          <div className={classes.profilePicDiv}>
            {profile_pic && (
              <div
                className={classes.profilePicUploaded}
                style={{
                  backgroundImage: `url(${profile_pic})`,
                }}
              ></div>
            )}
            {!profile_pic && <PersonIcon className={classes.profilePic} />}
          </div>
          <Typography variant="h6" color="primary" className={classes.user}>
            {firstname} {lastname}
          </Typography>
          <Typography
            variant="h6"
            color="primary"
            className={classes.userEmail}
          >
            {email}
          </Typography>
          <Typography color="textSecondary" className={classes.userRole}>
            {role}
          </Typography>

          <div className={classes.locationDiv}>
            <RoomIcon className={classes.locationIcon} />
            <Typography
              color="textSecondary"
              variant="body2"
              className={classes.location}
            >
              {city} {province}
            </Typography>
          </div>

          <div>
            Approved
            <Switch
              checked={approved}
              onChange={handleApprovedToggleChange}
              name="checked"
            />
          </div>

          <div onClick={handleClick}>Edit Provider</div>
        </Grid>
      </Paper>
    </div>
  );
};

export default withRouter(UserCard);

import { makeStyles } from '@material-ui/core/styles';

export const googleAutocompleteStyles = makeStyles((theme) => ({
  root: {},
  textfield: {
    width: '100%',
    marginTop: theme.spacing(1),
    '& .MuiOutlinedInput-root': {
      borderRadius: 7,
      color: theme.palette.primary.main,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.main,
    },
  },
  typography: {
    fontSize: '0.9rem',
    fontWeight: '100',
    textTransform: 'capitalize',
    opacity: '1',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2),
    },
  },
  icon: {
    color: '#C92929',
  },
  autoCompleteLabel: {
    fontSize: '12px',
    'line-height': 1,
    marginBottom: '2px',
    marginTop: '2px',
    color: 'rgba(0.0, 0, 0, 0.54)',
  },
}));

import React, { Fragment } from 'react';
import { Switch } from 'react-router-dom';
import AdminPrivateRoute from './AdminPrivateRoute';
import AdminNavbar from './AdminNavbar';
import AllProviders from './AllProviders';
import AllClients from './AllClients';

const AdminDashboard = () => {
  return (
    <Fragment>
      <AdminNavbar />
      <Switch>
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/all-providers"
          component={AllProviders}
        />

        <AdminPrivateRoute
          exact
          path="/admin-dashboard/all-clients"
          component={AllClients}
        />
      </Switch>
    </Fragment>
  );
};

export default AdminDashboard;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    textAlign: 'center',
  },
  linkDiv: {
    flexGrow: 1,
  },
  logoDiv: {
    width: '200px',
    height: '48px',
    margin: theme.spacing(3, 2),
    [theme.breakpoints.down('sm')]: {
      width: '166px',
      height: '40px',
      margin: theme.spacing(2, 0),
    },
  },
  logoLink: {
    textDecoration: 'none',
  },
  logo: {
    width: '100%',
    height: '100%',
  },
  bannerText: {
    position: 'absolute',
    zIndex: '2',
    textAlign: 'center',
    color: '#ffffff',
    margin: theme.spacing(2, 0),
  },
  bannerTitle: {
    margin: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.7rem',
    },
  },
  bannerSubTitle: {
    margin: theme.spacing(7, 0, 7, 0),
    fontWeight: 'bold',
    fontSize: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
  titlePrimary: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  textDisable: {
    fontSize: '1.2rem',
    fontWeight: 500,
    margin: theme.spacing(5, 32, 13),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
      margin: theme.spacing(4, 16, 12),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
      margin: theme.spacing(3, 9, 9),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      margin: theme.spacing(3, 2.5, 8),
    },
  },
  contactLink: {
    textDecoration: 'none',
  },
  loading: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

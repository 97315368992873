import React, { useReducer } from 'react';
import DialogContext from './dialogContext';
import dialogReducer from './dialogReducer';

import {
  OPEN_LOGIN,
  CLOSE_LOGIN,
  OPEN_SIGNUP,
  CLOSE_SIGNUP,
  SWITCH_DIALOG,
  OPEN_PASSWORD,
  CLOSE_PASSWORD,
} from '../actionTypes';

const DialogState = (props) => {
  const initialState = {
    openLogin: false,
    openSignup: false,
    openPassword: false,
  };

  const [state, dispatch] = useReducer(dialogReducer, initialState);

  // Handle open login dialog box
  const handleOpenLogin = () => {
    dispatch({
      type: OPEN_LOGIN,
    });
  };

  // Handle close login dialog box
  const handleCloseLogin = () => {
    dispatch({
      type: CLOSE_LOGIN,
    });
  };

  // Handle open signup dialog box
  const handleOpenSignup = () => {
    dispatch({
      type: OPEN_SIGNUP,
    });
  };

  // Handle close signup dialog box
  const handleCloseSignup = () => {
    dispatch({
      type: CLOSE_SIGNUP,
    });
  };

  // Handle switch between signup and login Dialogs
  const handleSwitch = () => {
    dispatch({
      type: SWITCH_DIALOG,
    });
  };

  // Handle open Forgot Password
  const handleOpenPassword = () => {
    dispatch({
      type: OPEN_PASSWORD,
    });
  };

  // Handle close Forgot Password
  const handleClosePassword = () => {
    dispatch({
      type: CLOSE_PASSWORD,
    });
  };

  return (
    <DialogContext.Provider
      value={{
        openLogin: state.openLogin,
        openSignup: state.openSignup,
        openPassword: state.openPassword,
        handleOpenLogin,
        handleCloseLogin,
        handleOpenSignup,
        handleCloseSignup,
        handleSwitch,
        handleOpenPassword,
        handleClosePassword,
      }}
    >
      {props.children}
    </DialogContext.Provider>
  );
};

export default DialogState;

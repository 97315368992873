import React, { useReducer } from 'react';
import GoogleContext from './googleContext';
import googleReducer from './googleReducer';
import { GOOGLE_LOADED, GOOGLE_RELOAD, GOOGLE_ERROR } from '../actionTypes';

const GoogleState = (props) => {
  const initialState = {
    googleLoading: true,
    googleErr: '',
  };

  const [state, dispatch] = useReducer(googleReducer, initialState);

  // ************** Setup google script *************
  const loadGoogle = () => {
    if (!document.querySelector('head')) {
      dispatch({
        type: GOOGLE_ERROR,
        payload: 'Cannot find the head element',
      });
      return;
    }

    // const script = document.createElement('script');
    // script.setAttribute('async', '');
    // script.setAttribute('id', 'google-maps');
    // script.src =
    //   "https://maps.googleapis.com/maps/api/js?key=AIzaSyAoiMBmBLaPJa47Wyp8yyu9RqeF8EQ4wa4&libraries=places";
    // document.querySelector("head").appendChild(script);
    // console.log('this is inside the loadGoogle');

    dispatch({
      type: GOOGLE_LOADED,
      payload: false,
    });
  };

  // ************** Reload google *************
  const reloadGoogle = () => {
    dispatch({
      type: GOOGLE_RELOAD,
      payload: !state.googleLoading,
    });
  };

  return (
    <GoogleContext.Provider
      value={{
        googleLoading: state.googleLoading,
        googleErr: state.googleErr,
        loadGoogle,
        reloadGoogle,
      }}
    >
      {props.children}
    </GoogleContext.Provider>
  );
};

export default GoogleState;

import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import parse from 'autosuggest-highlight/parse';
import { googleAutocompleteStyles } from './googleAutocompleteStyles';

export default function GoogleMaps(props) {
  const classes = googleAutocompleteStyles();
  const [value, setValue] = React.useState('');
  const [update, setUpdate] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [serviceActivated, setServiceActivated] = React.useState(false);
  const [request, setRequest] = React.useState({});
  const [autocompleteService, setAutocompleteService] = React.useState({
    current: null,
  });
  const [sessionToken, setSessionToken] = React.useState(null);

  if (typeof window !== 'undefined' && !serviceActivated) {
    setServiceActivated(true);
  }

  useEffect(() => {
    if (!autocompleteService.current && window.google) {
      setAutocompleteService({
        current: new window.google.maps.places.AutocompleteService(),
      });

      setSessionToken(new window.google.maps.places.AutocompleteSessionToken());
    }

    if (!autocompleteService.current) return undefined;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    autocompleteService.current.getPlacePredictions(request, (results) => {
      if (results) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        newOptions = [...newOptions, ...results];
        setOptions(newOptions);
      }
    });

    if (!update) {
      setUpdate(true);
    }
  }, [value, inputValue]);

  setTimeout(() => {}, 5000);

  return (
    <>
      <Typography
        align="left"
        variant="subtitle1"
        className={classes.autoCompleteLabel}
      >
        {props.label}
      </Typography>
      <Autocomplete
        id="google-maps-autocomplete"
        freeSolo
        autoComplete
        includeInputInList
        filterSelectedOptions
        options={options}
        value={props.currentPlace}
        filterOptions={(x) => x}
        getOptionLabel={(option) =>
          typeof option === 'string'
            ? option
            : props.full
            ? option.description
            : option.structured_formatting.main_text
        }
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          props.receiveToken(sessionToken);
          props.receiveData(newValue);
        }}
        onInputChange={(event, newValue) => {
          setInputValue(newValue);
          setRequest({
            input: newValue,
            sessionToken: sessionToken,
          });
          setValue(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.placeholder}
            variant="outlined"
            className={classes.textfield}
          />
        )}
        renderOption={(option) => {
          if (typeof option !== 'string') {
            const matches =
              option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [
                match.offset,
                match.offset + match.length,
              ])
            );
            return (
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}
                  <Typography
                    variant="subtitle1"
                    className={classes.typography}
                  >
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }
        }}
      />
    </>
  );
}

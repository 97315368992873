import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import {
  useMediaQuery,
  Button,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Divider,
  InputAdornment,
  Snackbar,
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton, Alert } from '@material-ui/lab';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { loginSignupStyles } from './loginSignupStyles';
import CloseIcon from '@material-ui/icons/Close';
import AuthContext from '../../context/authentication/authContext';
import DialogContext from '../../context/dialogContext/dialogContext';

const Signup = (props) => {
  const classes = loginSignupStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const authContext = useContext(AuthContext);
  const dialogContext = useContext(DialogContext);

  const { signup, message, isAuthenticated, clearMsg, user_role } = authContext;
  const { openSignup, handleCloseSignup, handleSwitch } = dialogContext;

  const [alignment, setAlignment] = useState('left');
  const [userType, setUserType] = useState('client');

  const [name, setName] = useState('');
  const [user, setUser] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
    user_type: 'client',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [pwErr, setPwErr] = useState(false);
  const [pw2Err, setPw2Err] = useState(false);
  const [snackbar, setSnackbar] = useState(false);

  const { history } = props;

  const {
    firstname,
    lastname,
    email,
    password,
    confirmPassword,
    user_type,
  } = user;

  useEffect(() => {
    if (isAuthenticated && user_role === 'provider') {
      history.push('/provider/user-info');
    }
    if (isAuthenticated && user_role === 'client') {
      history.push('/client/user-info');
    }
  }, [isAuthenticated, history, user_role]);

  useEffect(() => {
    setName('');
    setNameErr(false);
    setUser({
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmPassword: '',
      user_type: 'client',
    });
    setEmailErr(false);
    setPwErr(false);
    setPw2Err(false);
    setAlignment('left');
    setUserType('client');
    // eslint-disable-next-line
  }, [openSignup]);

  useEffect(() => {
    const nameFormat = (name) => {
      const sptName = name.split(' ');
      const fName = sptName[0];
      const lName = sptName.length > 1 ? sptName[sptName.length - 1] : '';
      setUser({ ...user, firstname: fName, lastname: lName });
    };
    nameFormat(name);
    // eslint-disable-next-line
  }, [name]);

  useEffect(() => {
    setUser({ ...user, user_type: userType });
    // eslint-disable-next-line
  }, [userType]);

  useEffect(() => {
    message !== null ? setSnackbar(true) : setSnackbar(false);
    isAuthenticated === true && handleCloseSignup();
    // eslint-disable-next-line
  }, [message, isAuthenticated]);

  const handleSubmit = (e) => {
    e.preventDefault();
    clearMsg();
    if (!name || !email || !password) {
      !name && setNameErr(true);
      !email && setEmailErr(true);
      !password && setPwErr(true);
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailErr(true);
    } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(password)) {
      setPwErr(true);
    } else if (password !== confirmPassword) {
      setPw2Err(true);
    } else {
      signup({ firstname, lastname, email, user_type, password });
    }
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    setNameErr(false);
    setName(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    name === 'email' && setEmailErr(false);
    name === 'password' && setPwErr(false);
    name === 'confirmPassword' && setPw2Err(false);
    if (name === 'email') {
      setUser({ ...user, [name]: value.toLowerCase() });
    } else {
      setUser({ ...user, [name]: value });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      newAlignment === 'left' ? setUserType('client') : setUserType('provider');
    }
  };

  const handleSnackbar = () => {
    setSnackbar(!snackbar);
  };

  // *********** Provider/Client toggle button style ************
  const StyledToggleButtonGroup = withStyles((theme, alignment) => ({
    grouped: {
      width: '174px',
      padding: theme.spacing(1.5, 2),
      border: 'none',
      '&:not(:first-child)': {
        borderRadius: '200px',
      },
      '&:first-child': {
        borderRadius: '200px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: '150px',
        padding: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        width: '150px',
        padding: theme.spacing(1),
      },
      [theme.breakpoints.down('xs')]: {
        width: '135px',
        padding: theme.spacing(1),
        fontSize: '1rem',
      },
    },
  }))(ToggleButtonGroup);

  return (
    <div>
      <Dialog
        open={openSignup}
        onClose={handleCloseSignup}
        style={{ textAlign: 'center' }}
        fullScreen={fullScreen}
      >
        {/* *************** DialogTitle ************** */}
        <DialogTitle className={classes.signupDialogTitle}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseSignup}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title}>Get started</Typography>
        </DialogTitle>

        {/* ****************** Alert Snackbar ******************* */}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={3000}
          open={snackbar}
          onClose={handleSnackbar}
        >
          <Alert elevation={6} variant="filled" severity="error">
            {message}
          </Alert>
        </Snackbar>

        {/* ************ DialogContent form ************* */}
        <DialogContent style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <TextField
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              color="secondary"
              className={classes.formTextField}
              onChange={handleNameChange}
              value={name}
              required
              error={nameErr}
              helperText={
                nameErr === true ? 'Name field must not be empty' : ''
              }
            />
            <TextField
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              color="secondary"
              className={classes.formTextField}
              onChange={handleChange}
              value={email}
              required
              error={emailErr}
              helperText={
                emailErr === true ? 'Please enter a valid email address!' : ''
              }
            />
            <TextField
              id="password"
              name="password"
              label="Password"
              variant="outlined"
              color="secondary"
              className={classes.formTextField}
              onChange={handleChange}
              value={password}
              required
              error={pwErr}
              helperText={
                pwErr === true
                  ? 'Must contain at least one number, one uppercase and lowercase letter, and at least 8 or more characters'
                  : ''
              }
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm password"
              variant="outlined"
              color="secondary"
              className={classes.formTextField}
              onChange={handleChange}
              value={confirmPassword}
              required
              error={pw2Err}
              helperText={pw2Err === true ? 'Password does not match' : ''}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <StyledToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
              className={classes.buttonContainer}
            >
              <ToggleButton value="left">I'm Client</ToggleButton>
              <ToggleButton value="right">I'm Provider</ToggleButton>
            </StyledToggleButtonGroup>

            <Button
              variant="contained"
              color="secondary"
              className={classes.joinButton}
              type="submit"
            >
              Join
            </Button>
          </form>

          <DialogContentText
            className={classes.caption}
            style={{ lineHeight: '1.1' }}
          >
            <Typography
              color="textSecondary"
              variant="caption"
              style={{ lineHeight: '1.1' }}
            >
              By joining, you agree to Terms of Service, as well as to receive
              occasional emails from us.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <Divider />

        {/* *********** DialogActions ************ */}
        <DialogActions
          style={{ justifyContent: 'center' }}
          className={classes.dialogActions}
        >
          <Typography color="textSecondary">Already a member?</Typography>
          <Button
            autoFocus
            color="primary"
            style={{ marginLeft: '0px' }}
            className={classes.actionButton}
            onClick={handleSwitch}
          >
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(Signup);

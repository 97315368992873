import {
  LOGIN_SUCCESS,
  PROVIDER_LOADED,
  PROVIDER_ERROR,
  CLIENTS_LOADED,
  LOGOUT,
  LOGIN_FAIL,
  CLEAR_MSG,
  CREATE_PROVIDER_SUCCESS,
  CREATE_PROVIDER_FAIL,
} from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      localStorage.setItem('adminToken', action.payload.token);
      localStorage.setItem('public_id', action.payload.admin.public_id);
      const adminSuccess = {
        ...state,
        admin_token: action.payload.token,
        isAuthenticated: true,
        adminLoading: false,
        adminMessage: null,
      };
      return adminSuccess;
    case LOGIN_FAIL:
      const loginFail = {
        ...state,
        adminMessage: action.payload,
        adminLoading: false,
      };
      return loginFail;
    case CREATE_PROVIDER_SUCCESS:
      const createProvider = {
        ...state,
        adminMessage: action.payload,
        adminLoading: false,
      };
      return createProvider;
    case CREATE_PROVIDER_FAIL:
      const createProviderFail = {
        ...state,
        adminMessage: action.payload,
        adminLoading: false,
      };
      return createProviderFail;
    case CLEAR_MSG:
      const clearMsg = {
        ...state,
        adminMessage: null,
        adminLoading: false,
      };
      return clearMsg;
    case PROVIDER_LOADED:
      return {
        ...state,
        allProviders: [...action.payload],
        adminLoading: false,
      };
    case CLIENTS_LOADED:
      return {
        ...state,
        allClients: [...action.payload],
        adminLoading: false,
      };
    case PROVIDER_ERROR:
      const providerError = {
        ...state,
        allProviders: null,
        adminMessage: action.payload,
        adminLoading: false,
      };
      return providerError;
    case LOGOUT:
      localStorage.removeItem('adminToken');
      localStorage.removeItem('public_id');
      const adminLogout = {
        ...state,
        adminToken: null,
        allProviders: null,
        adminMessage: null,
        isAuthenticated: null,
        adminLoading: false,
      };
      return adminLogout;
    default:
      return state;
  }
};

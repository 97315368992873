import React, { useContext } from 'react';
import AuthContext from '../../context/authentication/authContext';
import { Route, Redirect } from 'react-router-dom';

const SecureRoute = ({ component: Component, ...rest }) => {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, token } = authContext;

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated && !token ? (
          localStorage.adminToken ? (
            <Redirect to="/admin-dashboard/all-providers" />
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <Component {...props} />
        )
      }
    ></Route>
  );
};

export default SecureRoute;

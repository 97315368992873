import { makeStyles } from '@material-ui/core/styles';

export const setAvailabilityStyles = makeStyles((theme) => ({
  grid: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  dayInput: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    borderRadius: 7,
    color: theme.palette.primary.main,
    fontSize: 16,
    width: '200px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '120px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '120px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80px',
    },
  },
  timeInput: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    borderRadius: 7,
    color: theme.palette.primary.main,
    fontSize: 16,
    width: '200px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '120px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '120px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '80px',
    },
  },
  addBtn: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '4px',
    top: '35%',
    right: '10%',
  },
}));

import React from 'react';
import {
  FormControl,
  OutlinedInput,
  InputLabel,
  IconButton,
  Grid,
  NativeSelect,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { setAvailabilityStyles } from './setAvailabilityStyles';

const SetAvailability = ({ handleHourChange, handleHourClick, dayHours }) => {
  const classes = setAvailabilityStyles();

  const { day, start, end } = dayHours;

  return (
    <Grid container className={classes.grid}>
      <Grid item xs={3}>
        <FormControl>
          <InputLabel shrink htmlFor="day">
            Day
          </InputLabel>
          <NativeSelect
            value={day}
            onChange={handleHourChange}
            input={
              <OutlinedInput
                id="day"
                name="day"
                type="text"
                color="secondary"
                className={classes.dayInput}
              />
            }
          >
            <option value="" />
            <option value="MON">MON</option>
            <option value="TUE">TUE</option>
            <option value="WED">WED</option>
            <option value="THU">THU</option>
            <option value="FRI">FRI</option>
            <option value="SAT">SAT</option>
            <option value="SUN">SUN</option>
          </NativeSelect>
        </FormControl>
      </Grid>
      <Grid item xs={3} className={classes.grid}>
        <FormControl>
          <InputLabel shrink htmlFor="start">
            From
          </InputLabel>
          <OutlinedInput
            id="start"
            name="start"
            type="time"
            color="secondary"
            className={classes.timeInput}
            value={start}
            onChange={handleHourChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={3} className={classes.grid}>
        <FormControl>
          <InputLabel shrink htmlFor="end">
            To
          </InputLabel>
          <OutlinedInput
            id="end"
            name="end"
            type="time"
            color="secondary"
            className={classes.timeInput}
            value={end}
            onChange={handleHourChange}
          />
        </FormControl>
      </Grid>
      <Grid item xs={2} md={3}>
        <IconButton
          aria-label="add"
          className={classes.addBtn}
          onClick={handleHourClick}
        >
          <AddIcon style={{ color: '#ffffff' }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default SetAvailability;

import React, { useEffect, useState, useContext, Fragment } from 'react';
import {
  Grid,
  Typography,
  Paper,
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  TextField,
  InputAdornment,
  Button,
} from '@material-ui/core';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import Rating from '@material-ui/lab/Rating';
import SearchIcon from '@material-ui/icons/Search';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import 'react-multi-carousel/lib/styles.css';
import { useStyles } from '../../../themes/commonStyles';
import { homepageStyles } from './homepageStyles';
import DialogContext from '../../context/dialogContext/dialogContext';
import group from '../../../assets/group-copy.png';
import bannerImg from '../../../assets/home-banner-img.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarCheck,
  faHandsHelping,
  faUserMd,
} from '@fortawesome/free-solid-svg-icons';
import AuthContext from '../../context/authentication/authContext';

const Homepage = (props) => {
  const classesBase = useStyles();
  const classes = homepageStyles();
  const authContext = useContext(AuthContext);
  const { message } = authContext;
  const dialogContext = useContext(DialogContext);
  const { handleOpenSignup, handleOpenLogin } = dialogContext;

  const [expanded, setExpanded] = useState(false);
  const [query, setQuery] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    if (message === 'Password has been successfully updated try login') {
      handleOpenLogin();
    }
    // eslint-disable-next-line
  }, []);

  // ************** ExpansionPanel handleChange ***************
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  const handleSearchClick = () => {
    query !== '' && props.history.push(`/search?text=${query}`);
  };

  return (
    <Fragment>
      <Navbar />
      <div className={classesBase.root}>
        {/* ******************************* Banner container *********************************** */}
        <Grid container className={classes.bannerContainer}>
          <div className={classes.bitmapImg}></div>
          <div className={classes.overlay}></div>
          <Grid container className={classes.container}>
            <Grid item xs={8}></Grid>
            <Grid item xs={4}>
              <img src={group} className={classes.groupImg} alt="group-icon" />
            </Grid>
          </Grid>

          <Grid container className={classes.paperContainer}>
            <Grid item xs={8} sm={7}>
              <Typography
                color="primary"
                variant="h4"
                className={classes.bannerTitle}
              >
                Find the perfect <i>healthcare provider</i> for you.
              </Typography>
              <Typography
                color="primary"
                variant="subtitle1"
                className={classes.bannerSubtitle}
              >
                Choose from a wide range of experienced healthcare
                professionals.
              </Typography>

              {/* ******************* Search ***************** */}
              <TextField
                variant="outlined"
                color="secondary"
                placeholder='Try "Chiropractor"'
                className={classes.search}
                value={query}
                onKeyDown={handleKeyDown}
                onChange={handleSearchChange}
                InputProps={{
                  className: classes.inputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.searchButton}
                        onClick={handleSearchClick}
                      >
                        Search
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />

              <div className={classes.search}>
                <Typography>Popular: </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.popularBtn}
                  href="/search?text=chiropractor"
                >
                  Chiropractor
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.popularBtn}
                  href="/search?text=massage%20therapist"
                >
                  Massage Therapy
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.popularBtn}
                  href="/search?text=naturopath"
                >
                  Naturopathy
                </Button>
              </div>
            </Grid>
            <Grid item xs={4} sm={5}>
              <Paper elevation={3} className={classes.paper}>
                <img
                  src={bannerImg}
                  className={classes.paperImg}
                  alt="bannerImg"
                />
                <Box component="fieldset" borderColor="transparent">
                  <Rating name="read-only" value={5} readOnly size="small" />
                  <hr className={classes.hr1} />
                  <hr className={classes.hr2} />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        {/* ******************************* How it works *********************************** */}
        <Grid container justify="center" className={classesBase.container}>
          <Typography variant="h4" color="primary" className={classes.title}>
            How it works
          </Typography>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} sm={3} className={classesBase.container}>
            <div className={classes.badges}>
              <FontAwesomeIcon
                icon={faUserMd}
                className="fa-4x"
                style={{ marginTop: '10px', color: '#97DA91' }}
              />
            </div>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.stepText}
            >
              STEP 1
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              className={classes.subTitle}
            >
              Find A Provider
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={classes.disabled}
            >
              Choose from healthcare professionals in your area.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1} className={classes.direction}>
            <ChevronRightIcon className={classes.arrowRight} />
            <ExpandMoreIcon className={classes.arrowDown} />
          </Grid>
          <Grid item xs={12} sm={3} className={classesBase.container}>
            <div className={classes.badges}>
              <FontAwesomeIcon
                icon={faCalendarCheck}
                className="fa-4x"
                style={{ marginTop: '10px', color: '#97DA91' }}
              />
            </div>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.stepText}
            >
              STEP 2
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              className={classes.subTitle}
            >
              Book an Appointment
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={classes.disabled}
            >
              Connect online with the provider directly.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={1} className={classes.direction}>
            <ChevronRightIcon className={classes.arrowRight} />
            <ExpandMoreIcon className={classes.arrowDown} />
          </Grid>
          <Grid item xs={12} sm={3} className={classesBase.container}>
            <div className={classes.badges}>
              <FontAwesomeIcon
                icon={faHandsHelping}
                className="fa-4x"
                style={{ marginTop: '10px', color: '#97DA91' }}
              />
            </div>
            <Typography
              variant="subtitle2"
              color="primary"
              className={classes.stepText}
            >
              STEP 3
            </Typography>
            <Typography
              variant="h6"
              color="primary"
              className={classes.subTitle}
            >
              Receive Care
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className={classes.disabled}
            >
              Experience care in the comfort of your home, connect virtually or
              visit your health professional in a physical clinic.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justify="center"
          spacing={4}
          className={classes.explnationContainer}
        >
          <Grid item xs="12" md="6">
            <Typography variant="subtitle2" color="primary">
              Safety is our top priority. Certified professionals guaranteed.
            </Typography>

            <Typography variant="subtitle1" color="textSecondary">
              Rest assured that every listed provider is fully licensed and
              certified by their respective provincial and federal governing
              bodies.
            </Typography>
          </Grid>

          <Grid item xs="12" md="6">
            <Typography variant="subtitle2" color="primary">
              Complete transparency.
            </Typography>

            <Typography variant="subtitle1" color="textSecondary">
              Know exactly what to expect from your healthcare provider. Take
              control of your health.
            </Typography>
          </Grid>

          <Grid item xs="12" md="6">
            <Typography variant="subtitle2" color="primary">
              Convenient care at home or work.
            </Typography>

            <Typography variant="subtitle1" color="textSecondary">
              Choose from a number of providers who offer treatments in the
              comfort of your home. Select providers may even provide treatment
              at community events, or your workplace.
            </Typography>
          </Grid>

          <Grid item xs="12" md="6">
            <Typography variant="subtitle2" color="primary">
              Booking assistance provided.
            </Typography>

            <Typography variant="subtitle1" color="textSecondary">
              Let us help you find the perfect provider near you. Trained
              MyProvider staff will match you with the ideal provider who
              specializes in your specific condition. Give us a call today.
            </Typography>
          </Grid>
        </Grid>

        <Grid container justify="center" className={classesBase.container}>
          <Typography variant="h6" color="primary" className={classes.title}>
            Popular professional services
          </Typography>

          <Grid container item justify="center">
            <Button
              variant="contained"
              color="secondary"
              className={classes.bottomBtn}
              href="/search?text=physiotherapist"
            >
              Physiotherapy
            </Button>

            <Button
              variant="contained"
              color="secondary"
              className={classes.bottomBtn}
              href="/search?text=chiropractor"
            >
              Chiropractic
            </Button>

            <Button
              variant="contained"
              color="secondary"
              className={classes.bottomBtn}
              href="/search?text=chiropractor%20intern"
            >
              Chiropractic Intern
            </Button>

            <Button
              variant="contained"
              color="secondary"
              className={classes.bottomBtn}
              href="/search?text=massage%20therapist"
            >
              Massage Therapy
            </Button>

            <Button
              variant="contained"
              color="secondary"
              className={classes.bottomBtn}
              href="/search?text=speech%20pathologist"
            >
              Speech Language Pathology
            </Button>

            <Button
              variant="contained"
              color="secondary"
              className={classes.bottomBtn}
              href="/search?text=personal%20trainer"
            >
              Personal Training
            </Button>

            <Button
              variant="contained"
              color="secondary"
              className={classes.bottomBtn}
              href="/search?text=naturopath"
            >
              Naturopathy
            </Button>

            <Button
              variant="contained"
              color="secondary"
              className={classes.bottomBtn}
              href="/search?text=psychologist"
            >
              Psychological Counselling
            </Button>
          </Grid>
        </Grid>

        {/* ********************* FAQ container ********************** */}
        <Grid container justify="center" className={classesBase.container}>
          <Typography variant="h6" color="primary" className={classes.title}>
            Have a question?
          </Typography>
        </Grid>
        <Grid container className={classes.faqContainer}>
          {/* ******************* ExpanPanel 2 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                Are the healthcare providers certified?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                All of the health care professionals on MyProvider are fully
                licensed and registered by their respective provincial governing
                bodies.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 3 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                What should I expect at my first appointment?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                With any new provider, an assessment will be done to address
                your main complaint. This assessment consists of a detailed
                health history, often followed by a physical examination. Next,
                the findings of the assessment are discussed, and the client and
                provider decide upon a plan of action. Treatment is then carried
                out in the initial session, or in subsequent visits, depending
                on the provider. Note: this is a general outline and may vary
                amongst providers and the service selected.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 4 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                Do all of the providers do home visits?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                No. Some providers only treat via Online and some only treat out
                of a clinic. If you would like home visits only please select
                that option when searching.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 5 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel5'}
            onChange={handleChange('panel5')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                How long will my appointment be?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                The first session with a new provider will always include an
                assessment before any treatment can take place. Therefore first
                visits are normally longer. A good estimate would be 45
                minutes-1 hour for initial visits, and 30-45 minutes for
                follow-up sessions. These times will vary according to the
                healthcare provider and the service chosen.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 6 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel6'}
            onChange={handleChange('panel6')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                How many appointments will I need?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                This is highly variable and dependent on your diagnosis and your
                individual goals/expectations from your treatment. A discussion
                with your healthcare provider about your goals is always had
                during the initial assessment.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 7 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel7'}
            onChange={handleChange('panel7')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                Can you help me find a provider?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                Yes! If you would rather not look through and find a provider
                yourself, contact us at info@myprovider.ca or 647 980 0580
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 8 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel8'}
            onChange={handleChange('panel8')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                Are the services on MyProvider covered by OHIP or WSIB?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                No. At this time, none of the services on MyProvider are covered
                by either OHIP or WSIB.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 9 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel9'}
            onChange={handleChange('panel9')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9-content"
              id="panel9-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                Do I need a doctor’s referral?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                No, a physician’s referral is not necessary. Physiotherapists,
                Chiropractors and Massage Therapists are direct access health
                professionals, which means that a physician’s referral is not
                necessary to receive treatment. However, some extended health
                benefit plan insurance companies may still require a physician’s
                referral for reimbursement of treatments. Please login and input
                your insurance information to learn more about your specific
                situation. (link to login/sign up to then input insurance)
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>

        {/* *************************** Bottom container **************************** */}
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.bottomContainer}
        >
          <Typography
            color="primary"
            variant="h4"
            className={classes.bottomTitle}
          >
            Find the right healthcare provider to get you feeling your best
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            className={classes.bottomBtn}
            onClick={handleOpenSignup}
          >
            Get started
          </Button>
        </Grid>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Homepage;

import React, { Fragment, useContext, useEffect, useState } from 'react';
import DashboardNavBar from '../appbar/DashboardNavBar';
import {
  Grid,
  Paper,
  Typography,
  CircularProgress,
  Divider,
  Box,
  Link,
  TextField,
  Button,
} from '@material-ui/core';
import ComputerIcon from '@material-ui/icons/Computer';
import HomeIcon from '@material-ui/icons/Home';
import Rating from '@material-ui/lab/Rating';
import PersonIcon from '@material-ui/icons/Person';
import RoomIcon from '@material-ui/icons/Room';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HelpIcon from '@material-ui/icons/Help';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WorkIcon from '@material-ui/icons/Work';
import SchoolIcon from '@material-ui/icons/School';
import { previewPageStyles } from './previewPageStyles';
import { useStyles } from '../../../themes/commonStyles';
import ProviderContext from '../../context/provider/providerContext';
import ClientContext from '../../context/client/clientContext';
import AuthContext from '../../context/authentication/authContext';

const HELPFULNESS = 'HELPFULNESS';
const PROFESSIONALISM = 'PROFESSIONALISM';
const KNOWLEDGE = 'KNOWLEDGE';
const PUNCTUALITY = 'PUNCTUALITY';
const THREE_FEE_STRUCTURE = 'threeFeeStructure';
const HOURLY_FEE_STRUCTURE = 'hourlyFeeStructure';

const ProviderProfilePreview = (props) => {
  const classes = previewPageStyles();
  const classesBase = useStyles();

  const authContext = useContext(AuthContext);
  const providerContext = useContext(ProviderContext);
  const clientContext = useContext(ClientContext);

  const { user } = authContext;
  const {
    provider,
    getProvider,
    providerLoading,
    getReview,
    reviews,
    reviewAverage,
    allowed,
    feeStructure,
    feeLoading,
    getFee,
  } = providerContext;
  const {
    client,
    getClient,
    postReview,
    setFavourite,
    favourite,
    updateClient,
  } = clientContext;

  const [ratingValue, setRatingValue] = useState(2.5);
  const [helpfulRating, setHelpfulRating] = useState(2.5);
  const [punctualityRating, setPunctualityRating] = useState(2.5);
  const [knowledgeRating, setKnowledgeRating] = useState(2.5);
  const [professionalismRating, setProfessionalismRating] = useState(2.5);
  const [reviewText, setReviewText] = useState('');
  const [reRender, setReRender] = useState(false);
  const [threeFeeStructure, setThreeFeeStructure] = useState({});
  const [hourlyFeeList, setHourlyFeeList] = useState([]);
  const [feeChoice, setFeeChoice] = useState('');
  const [liked, setLiked] = useState(false);

  const {
    firstname,
    lastname,
    about,
    role,
    clinic_name,
    clinic_city,
    clinic_street,
    clinic_unit,
    clinic_postal,
    clinic_phone,
    clinic_province,
    specialization,
    clinic_hours,
    home_visit,
    home_visit_hours,
    clinic_link,
    profile_pic,
    home_visit_location,
    home_visit_distance,
    travel_visit,
  } = provider;

  const modifiedSpecialization = specialization
    ? specialization.map((s) => s.name)
    : [];

  useEffect(() => {
    if (client.favourite) {
      setLiked(client.favourite.includes(provider.email));
    }

    const center = {
      lat: provider.home_visit_lat,
      lng: provider.home_visit_lng,
    };

    const mapDiv = document.getElementById('map');

    if (window.google && provider && mapDiv) {
      const map = new window.google.maps.Map(mapDiv, {
        zoom: 10,
        center: center,
        mapTypeId: 'terrain',
      });

      if ((client.client_lat, client.client_lng)) {
        const marker = new window.google.maps.Marker({
          // The below line is equivalent to writing:
          // position: new google.maps.LatLng(-34.397, 150.644)
          position: {
            lat: client.client_lat,
            lng: client.client_lng,
          },
          map: map,
        });
      }

      const cityCircle = new window.google.maps.Circle({
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35,
        map,
        center: center,
        radius: provider.home_visit_distance * 1000,
      });
    }
  }, [provider]);

  useEffect(() => {
    if (user.user_type.includes('client')) getClient(user.public_id);
    const { id } = props.match.params;
    getProvider(id);
    getReview(id);
    // eslint-disable-next-line
  }, [reRender]);

  useEffect(() => {
    client && client.public_id && updateClient({ favourite }, client.public_id);
  }, [favourite]);

  useEffect(() => {
    if (feeLoading === false) {
      const feeName = feeStructure.feeStructure;
      const feeValues = feeStructure.feeValues;
      setFeeChoice(feeName);

      if (feeValues.length !== 0)
        if (feeName === THREE_FEE_STRUCTURE)
          setThreeFeeStructure({
            initialName: feeValues[0].name,
            followUpName: feeValues[1].name,
            complexName: feeValues[2].name,
            initialFee: feeValues[0].fee,
            followUpFee: feeValues[1].fee,
            complexFee: feeValues[2].fee,
          });
        else if (feeName === HOURLY_FEE_STRUCTURE) {
          feeValues.map((value) =>
            setHourlyFeeList((prevArray) => [
              ...prevArray,
              {
                feeName: value.name,
                feeValue: value.fee,
                timeValue: value.minutes,
              },
            ])
          );
        }
    } else {
      const url = window.location.pathname.split('/');
      getFee(url[2]);
    }
  }, [feeLoading]);

  const handleLikeProvider = (e) => {
    if (!liked) {
      setFavourite(provider.email);
    } else {
      setFavourite(provider.email, 'removeFavourite');
    }

    setLiked((prevState) => !prevState);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postReview({
      client_id: client.public_id,
      provider_id: provider.public_id,
      rating_value: ratingValue,
      review_text: reviewText,
      helpfulness: helpfulRating,
      knowledge: knowledgeRating,
      professionalism: professionalismRating,
      punctuality: punctualityRating,
    });
    setReRender(!reRender);
  };

  const setRating = (e) => {
    switch (e.target.name) {
      case HELPFULNESS:
        setHelpfulRating(parseInt(e.target.value));
        break;
      case PUNCTUALITY:
        setPunctualityRating(parseInt(e.target.value));
        break;
      case PROFESSIONALISM:
        setProfessionalismRating(parseInt(e.target.value));
        break;
      case KNOWLEDGE:
        setKnowledgeRating(parseInt(e.target.value));
        break;
      default:
        setRatingValue(parseInt(e.target.value));
    }
  };

  const setReview = (e) => {
    setReviewText(e.target.value);
  };

  if (providerLoading) {
    return (
      <div className={classesBase.loading}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <Fragment>
      <DashboardNavBar />

      {/* ********************* Left side container ***************** */}
      <div className={classes.sideDiv}>
        <div className={classes.container}>
          <div className={classes.arrowBack}>
            <ArrowBackIcon color="secondary" onClick={props.history.goBack} />
          </div>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            className={classes.grid}
          >
            <div className={classes.specialContainer}>
              {home_visit && <ComputerIcon />}

              {travel_visit && <HomeIcon />}
            </div>

            <Grid item>
              <div className={classes.profilePicDiv}>
                {profile_pic && (
                  <div
                    className={classes.profilePicUploaded}
                    style={{
                      backgroundImage: `url(${profile_pic})`,
                    }}
                  ></div>
                )}
                {!profile_pic && <PersonIcon className={classes.profilePic} />}
              </div>
            </Grid>

            <Grid item>
              <Typography variant="h6" color="primary" className={classes.user}>
                {`${firstname} ${lastname}`}
              </Typography>
              <Typography color="textSecondary" className={classes.userRole}>
                {role}
              </Typography>

              <Grid container style={{ justifyContent: 'center' }}>
                <FavoriteIcon
                  color={liked ? 'primary' : 'disabled'}
                  onClick={handleLikeProvider}
                />
              </Grid>

              <Box
                component="fieldset"
                borderColor="transparent"
                className={classes.review}
              >
                <Rating
                  defaultValue={0}
                  value={reviewAverage}
                  precision={0.5}
                  name="read-only"
                  readOnly
                />
              </Box>

              <div className={classes.div}>
                <RoomIcon className={classes.locationIcon} />
                <Typography
                  color="textSecondary"
                  variant="body2"
                  className={classes.location}
                >
                  {`${clinic_city} ${clinic_province}`}
                </Typography>
              </div>
            </Grid>
            {user && user.user_type === 'client' && (
              <Fragment>
                <Typography
                  color="primary"
                  variant="body2"
                  className={classes.baText}
                >
                  To book an appointment
                </Typography>
                <Grid container justify="center" alignItems="center">
                  <Typography
                    color="secondary"
                    variant="body2"
                    className={classes.baSubText}
                  >
                    Call :
                  </Typography>
                  &nbsp;
                  <Typography
                    color="primary"
                    variant="body2"
                    className={classes.baSubText}
                  >
                    111-111-1111
                  </Typography>
                </Grid>
              </Fragment>
            )}
            {clinic_link && (
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.websiteLink}
              >
                <Typography
                  color="secondary"
                  variant="body2"
                  className={classes.baSubText}
                >
                  Visit :
                </Typography>
                &nbsp;
                <Link
                  href={clinic_link}
                  color="primary"
                  variant="body1"
                  target="_blank"
                >
                  {clinic_link}
                </Link>
              </Grid>
            )}
          </Grid>
        </div>
      </div>

      {/* ******************** Right side container ******************* */}
      <div className={classes.root}>
        {/* **************** About *************** */}
        <Typography color="primary" variant="h6" className={classes.title}>
          About
        </Typography>
        <Typography color="primary" className={classes.text}>
          {about}
        </Typography>
        <div className={classes.divider}>
          <Divider />
        </div>

        {/* ************** Specialization ***************** */}
        <Typography color="primary" variant="h6" className={classes.title}>
          Specializations
        </Typography>
        <div style={{ width: '75%', minHeight: '50px' }}>
          {modifiedSpecialization &&
            modifiedSpecialization.map((skill, index) => (
              <Paper
                variant="outlined"
                elevation={0}
                className={classes.paper}
                key={`${skill}${index}`}
              >
                {skill}
              </Paper>
            ))}
        </div>
        <div className={classes.divider}>
          <Divider />
        </div>

        {/* ***** Fee ******* */}
        <Typography color="primary" variant="h6" className={classes.title}>
          Fees
        </Typography>
        {feeChoice === THREE_FEE_STRUCTURE ? (
          <Grid container spacing={2}>
            {Object.keys(threeFeeStructure).length === 0 ? (
              <Grid item xs={12}>
                <Typography color="textSecondary">No fees yet</Typography>
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={6}>
                  <Typography color="primary">
                    {threeFeeStructure.initialName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="primary">
                    $ {threeFeeStructure.initialFee}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="primary">
                    {threeFeeStructure.followUpName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="primary">
                    $ {threeFeeStructure.followUpFee}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="primary">
                    {threeFeeStructure.complexName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="primary">
                    $ {threeFeeStructure.complexFee}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        ) : null}
        {feeChoice === HOURLY_FEE_STRUCTURE ? (
          <Grid container spacing={1}>
            {hourlyFeeList.length === 0 ? (
              <Grid item xs={12}>
                <Typography color="textSecondary">No fees yet</Typography>
              </Grid>
            ) : null}
            {hourlyFeeList.length !== 0 ? (
              <Grid item xs={4}>
                <Typography
                  color="textSecondary"
                  className={classes.feesSubtitle}
                >
                  Service Name
                </Typography>
              </Grid>
            ) : null}
            {hourlyFeeList.length !== 0 ? (
              <Grid item xs={4}>
                <Typography
                  color="textSecondary"
                  className={classes.feesSubtitle}
                >
                  Minutes
                </Typography>
              </Grid>
            ) : null}
            {hourlyFeeList.length !== 0 ? (
              <Grid item xs={4}>
                <Typography
                  color="textSecondary"
                  className={classes.feesSubtitle}
                >
                  Service Fee
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        ) : null}

        {feeChoice === HOURLY_FEE_STRUCTURE &&
          hourlyFeeList.map((value, key) => (
            <Grid container spacing={1} key={key}>
              <Grid item xs={4}>
                <Typography color="primary">{value.feeName}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="primary">
                  {value.timeValue} minutes
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography color="primary">$ {value.feeValue}</Typography>
              </Grid>
            </Grid>
          ))}

        <div className={classes.divider}>
          <Divider />
        </div>

        {/* ************ virtual visit timing ************* */}
        {home_visit ? (
          <Fragment>
            <Typography color="primary" variant="h6" className={classes.title}>
              Virtual/Home visit
            </Typography>
            <Typography
              color="secondary"
              variant="h6"
              className={classes.subTitle}
            >
              Available timings
            </Typography>

            <div style={{ width: '75%' }} className={classes.text}>
              {home_visit_hours &&
                home_visit_hours.map(({ day, start, end, id }) => (
                  <Typography
                    color="textSecondary"
                    className={classes.subText}
                    key={id}
                  >
                    {day} - {start} to {end}
                  </Typography>
                ))}
            </div>
          </Fragment>
        ) : (
          <Typography
            color="textSecondary"
            variant="h6"
            className={classes.title}
          >
            Does not offer virtual visit
          </Typography>
        )}
        <div className={classes.divider}>
          <Divider />
        </div>

        {travel_visit ? (
          <Fragment>
            <Typography color="primary" variant="h6" className={classes.title}>
              Home Visit
            </Typography>

            <div style={{ width: '75%' }} className={classes.text}>
              <Typography color="textSecondary" className={classes.subText}>
                This provider is willing to travel {home_visit_distance} km from{' '}
                {home_visit_location}
              </Typography>
            </div>

            <div id="map" style={{ width: `100%`, height: 300 }}></div>
          </Fragment>
        ) : (
          <Typography
            color="textSecondary"
            variant="h6"
            className={classes.title}
          >
            Does not offer home visit
          </Typography>
        )}
        <div className={classes.divider}>
          <Divider />
        </div>

        {/* ***************** Available location **************** */}
        <Typography color="primary" variant="h6" className={classes.title}>
          Available location
        </Typography>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12}>
            <Typography
              color="secondary"
              variant="h6"
              className={classes.subTitle}
            >
              {clinic_name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography color="textSecondary" className={classes.subText}>
              {clinic_unit && `${clinic_unit} -`} {clinic_street}
            </Typography>
            <Typography color="textSecondary" className={classes.subText}>
              {clinic_city && `${clinic_city},`} {clinic_province}
            </Typography>
            <Typography color="textSecondary" className={classes.subText}>
              {clinic_postal}
            </Typography>
            <Typography color="textSecondary" className={classes.subText}>
              {clinic_phone}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <div style={{ width: '75%' }}>
              {clinic_hours &&
                clinic_hours.map(({ day, start, end, id }) => (
                  <Typography
                    color="textSecondary"
                    className={classes.subText}
                    key={id}
                  >
                    {day} - {start} to {end}
                  </Typography>
                ))}
            </div>
          </Grid>
        </Grid>
        <div className={classes.divider}>
          <Divider />
        </div>

        {/* ********************* Reviews ********************* */}
        <Typography color="primary" variant="h6" className={classes.title}>
          Reviews
        </Typography>
        {Object.keys(client).length !== 0 && allowed !== false ? (
          <div>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <div>
                  <Typography color="primary">Helpfulness</Typography>
                  <HelpIcon color="primary"></HelpIcon>
                  <Rating
                    className={classes.review}
                    name={HELPFULNESS}
                    defaultValue={2.5}
                    precision={0.5}
                    onChange={setRating}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <Typography color="primary">Punctuality</Typography>
                  <AccessTimeIcon color="primary"></AccessTimeIcon>
                  <Rating
                    className={classes.review}
                    name={PUNCTUALITY}
                    defaultValue={2.5}
                    precision={0.5}
                    onChange={setRating}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <Typography color="primary">Knowledge</Typography>
                  <SchoolIcon color="primary"></SchoolIcon>
                  <Rating
                    className={classes.review}
                    name={KNOWLEDGE}
                    defaultValue={2.5}
                    precision={0.5}
                    onChange={setRating}
                  />
                  <br></br>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <Typography color="primary">Professionalism</Typography>
                  <WorkIcon color="primary"></WorkIcon>
                  <Rating
                    className={classes.review}
                    name={PROFESSIONALISM}
                    defaultValue={2.5}
                    precision={0.5}
                    onChange={setRating}
                  />
                </div>
              </Grid>
            </Grid>

            <Typography color="primary">Overall </Typography>
            <Rating
              className={classes.review}
              name="rating"
              defaultValue={2.5}
              precision={0.5}
              onChange={setRating}
            />

            <Typography color="primary">Comment</Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                name="reviewText"
                multiline
                rows={4}
                placeholder="How was the service you received? In what ways did they exceed your expecations? Any feedback for the provider?"
                variant="outlined"
                onChange={setReview}
                className={classes.reviewTextField}
              />

              <Button
                variant="contained"
                className={classes.reviewButton}
                type="submit"
                color="secondary"
              >
                Submit
              </Button>
            </form>
            <div className={classes.divider}>
              <Divider />
            </div>
          </div>
        ) : null}
        {reviews.length === 0 ? (
          <Typography color="textSecondary" className={classes.subText}>
            No reviews yet
          </Typography>
        ) : null}
        {reviews.map((review, idx) => {
          return (
            <Paper
              elevation={3}
              variant="outlined"
              className={classes.reviewPaper}
              key={idx}
            >
              <Grid key={idx} container className={classes.reviewContainer}>
                <Grid item xs={12}>
                  <Typography color="primary">
                    {review.client_firstname}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Rating
                    name="read-only"
                    defaultValue={review.rating}
                    precision={0.5}
                    readOnly
                  ></Rating>
                </Grid>

                <Grid item xs={12} sm={3} md={6} className={classes.reviewItem}>
                  <HelpIcon color="primary"></HelpIcon>
                  <Typography
                    color="primary"
                    component={'div'}
                    className={classes.reviewRatingDisplay}
                  >
                    <Box display="inline">Helpfulness</Box>
                    <Box display="inline" fontWeight="fontWeightBold" m={1}>
                      {review.helpfulness}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={6} className={classes.reviewItem}>
                  <AccessTimeIcon color="primary"></AccessTimeIcon>
                  <Typography
                    color="primary"
                    component={'div'}
                    className={classes.reviewRatingDisplay}
                  >
                    <Box display="inline">Punctuality</Box>
                    <Box display="inline" fontWeight="fontWeightBold" m={1}>
                      {review.punctuality}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={6} className={classes.reviewItem}>
                  <SchoolIcon color="primary"></SchoolIcon>
                  <Typography
                    color="primary"
                    component={'div'}
                    className={classes.reviewRatingDisplay}
                  >
                    <Box display="inline">Knowledge</Box>
                    <Box display="inline" fontWeight="fontWeightBold" m={1}>
                      {review.knowledge}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} md={6} className={classes.reviewItem}>
                  <WorkIcon color="primary"></WorkIcon>
                  <Typography
                    color="primary"
                    component={'div'}
                    className={classes.reviewRatingDisplay}
                  >
                    <Box display="inline">Professionalism</Box>
                    <Box display="inline" fontWeight="fontWeightBold" m={1}>
                      {review.professionalism}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="primary" className={classes.reviewComment}>
                    {review.comment}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          );
        })}
      </div>
    </Fragment>
  );
};

export default ProviderProfilePreview;

import { makeStyles } from '@material-ui/core/styles';

export const earningCalcStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '16px',
    maxWidth: '90%',
    boxShadow: '-3px 7px 30px -17px rgba(0,0,0,0.75)',
    margin: theme.spacing(15, 0, 5),
  },
  header: {
    color: '#ffffff',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '16px 16px 0 0',
  },
  paperTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    margin: theme.spacing(5, 32),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1.6rem',
      margin: theme.spacing(4, 10),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
      margin: theme.spacing(4, 8),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.2rem',
      margin: theme.spacing(2, 6),
    },
  },
  subContent: {
    width: '60%',
    margin: theme.spacing(7, 'auto', 10),
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      margin: theme.spacing(5, 'auto', 8),
    },
  },
  paperSubText: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
  },
  paperText: {
    fontSize: '1.3rem',
    fontWeight: 'bold',
  },
  form: {
    width: '150px',
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      width: '100px',
    },
  },
  slider: {
    margin: theme.spacing(9, 0, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(5, 0, 0),
    },
  },
  itemFooter: {
    margin: theme.spacing(7, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 0, 6),
    },
  },
  span: {
    fontSize: '1.2rem',
    color: '#ffffff',
    fontWeight: 'bold',
    fontFamily: '"Poppins", sans-serif',
    backgroundColor: '#97DA91',
    margin: theme.spacing(0, 2),
    padding: theme.spacing(1.5, 3),
    borderRadius: '200px',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(3, 0, 0),
    },
  },
}));

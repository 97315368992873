import React, { Fragment, useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Paper,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Slider,
  Divider,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { earningCalcStyles } from './earningCalcStyles';

const EarningCalc = () => {
  const classes = earningCalcStyles();

  // *********** For Slider component ************
  const PrettoSlider = withStyles({
    root: {
      color: '#3a5275',
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid #3a5275',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);

  const [amount, setAmount] = useState('110');
  const [slider, setSlider] = useState(7);
  const [total, setTotal] = useState('');

  // Calculate total earning
  const earningCalc = () => {
    // Earning up to 100% of that calculation total
    const totalEarning = Math.round(Number(amount) * slider * 4 * 0.1);

    setTotal(totalEarning);
  };

  useEffect(() => {
    earningCalc();
    // eslint-disable-next-line
  }, [amount, slider]);

  // Handling amount input
  const handleChange = (e) => {
    setAmount(e.target.value);
  };

  // Handling slider input
  const handleSlider = (event, val) => {
    setSlider(val);
  };

  return (
    <Fragment>
      <Paper elevation={3} className={classes.paper}>
        <Grid container className={classes.header}>
          <Typography variant="h6" className={classes.paperTitle}>
            See how much you can earn in a month of working for yourself
          </Typography>
        </Grid>

        <Grid container alignItems="center" className={classes.subContent}>
          <Typography color="primary" className={classes.paperSubText}>
            Price
          </Typography>
          <FormControl variant="outlined" className={classes.form}>
            <OutlinedInput
              onChange={handleChange}
              value={amount}
              placeholder="amount"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              styles={{ color: '#e9ebed' }}
            />
          </FormControl>
          {/* <Typography color="primary" className={classes.paperSubText}>
            per session (from 70%)
          </Typography> */}

          <PrettoSlider
            valueLabelDisplay="auto"
            aria-label="pretto slider"
            onChangeCommitted={handleSlider}
            defaultValue={slider}
            className={classes.slider}
          />
          <Typography color="primary" className={classes.paperSubText}>
            Patients a week
          </Typography>
        </Grid>
        <Divider />
        <Grid
          container
          item
          justify="center"
          alignItems="center"
          className={classes.itemFooter}
        >
          <Typography color="primary" className={classes.paperText}>
            You can earn up to
          </Typography>
          <span className={classes.span}>${`${total}`} per month</span>
        </Grid>
      </Paper>
    </Fragment>
  );
};

export default EarningCalc;

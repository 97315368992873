import { makeStyles } from '@material-ui/core/styles';

export const previewPageStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 510px)',
    marginTop: '130px',
    marginLeft: '500px',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
      marginLeft: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px',
      textAlign: 'center',
    },
  },
  title: {
    margin: theme.spacing(6, 8, 2, 0),
    fontSize: '1.2rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  subTitle: {
    margin: theme.spacing(1, 0),
    fontSize: '1.1rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  baText: {
    margin: theme.spacing(4, 0, 2),
    fontSize: '1.1rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  baSubText: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  text: {
    margin: theme.spacing(0, 20, 3, 0),
    fontSize: '0.9rem',
    fontWeight: '500',
    minHeight: '50px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
  },
  subText: {
    fontSize: '1rem',
    fontWeight: '500',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
  },
  divider: {
    margin: theme.spacing(4, 0),
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      margin: theme.spacing(4, 'auto'),
    },
  },
  paper: {
    display: 'inline-table',
    padding: theme.spacing(1),
    margin: theme.spacing(1, 1, 1, 0),
    borderRadius: '5px',
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      display: 'table',
      margin: theme.spacing(1, 'auto'),
    },
  },
  gridContainer: {
    minHeight: '50px',
  },
  review: {
    marginBottom: theme.spacing(3),
  },
  sideDiv: {
    height: '100vh',
    position: 'fixed',
    width: '420px',
    borderRight: '1px solid rgba(0,0,0,0.12)',
    display: 'flex',
    flexDirection: 'column',
    top: '0px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  arrowBack: {
    marginLeft: theme.spacing(3),
  },
  homeIcon: {
    position: 'absolute',
    top: '2%',
    right: '5%',
    color: '#7c9cbb',
  },

  specialContainer: {
    width: `100%`,
    textAlign: `right`,
    color: '#7c9cbb',
    padding: theme.spacing(1),
  },
  container: {
    marginTop: '100px',
  },
  grid: {
    textAlign: 'center',
    position: 'relative',
  },
  profilePicDiv: {
    width: '140px',
    height: '140px',
    backgroundColor: theme.bgcolor,
    borderRadius: '200px',
    textAlign: 'center',
    overflow: 'hidden',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '120px',
      height: '120px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px',
    },
  },

  profilePic: {
    width: '140px',
    height: '140px',
    color: '#ffffff',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '120px',
      height: '120px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px',
    },
  },

  profilePicUploaded: {
    width: 140,
    height: 140,
    borderRadius: 60,
    backgroundClick: `padding-box`,
    backgroundSize: `cover`,
    backgroundPosition: `center center`,
  },

  user: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    margin: theme.spacing(2.5, 0, 1),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  userRole: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
  },
  div: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  locationIcon: {
    color: theme.bgcolor,
    marginRight: theme.spacing(1),
  },
  location: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.85rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
  websiteLink: {
    textDecoration: 'none',
    marginTop: theme.spacing(4),
  },
  reviewTextField: {
    width: '90%',
    margin: '0px 0px 16px 0px',
    '& label.Mui-focused': {
      color: 'black',
    },
    '& textarea:hover': {
      outline: 'none',
      border: '0px #ffffff',
    },
    '& textarea:focus': {
      border: '0px #ffffff',
      borderRadius: '0px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px',
      fontWeight: 'bold',
      color: '#3a5275',
      '&.Mui-focused fieldset': {
        borderColor: '#97DA91',
      },
    },
  },
  reviewButton: {
    color: '#ffffff',
    margin: '0px 0px 16px 0px',
    boxShadow: 'none',
    [theme.breakpoints.between('sm', 'xl')]: {
      display: 'flex',
    },
  },
  subReview: {
    color: '#97DA91',
  },
  reviewItem: {
    [theme.breakpoints.between('md', 'xl')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  reviewRatingDisplay: {
    marginLeft: '5px',
  },
  reviewComment: {
    marginTop: '16px',
  },
  feesSubtitle: {
    fontWeight: 'bold',
  },
  reviewPaper: {
    width: '90%',
    marginBottom: theme.spacing(2),
  },
  reviewContainer: {
    marginLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

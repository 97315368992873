import {
  CLIENT_LOADED,
  CLIENT_ERROR,
  CLIENT_UPDATED,
  CLEAR_MSG,
  FAV_PROVIDER_SUCCESS,
  SET_FAVOURITE,
  REMOVE_FAVOURITE,
  LOGOUT,
  POST_REVIEW,
} from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case CLIENT_LOADED:
      const loadClient = {
        ...state,
        client: { ...action.payload },
        clientLoading: false,
        clientLoaded: true,
        clientMsg: null,
      };
      return loadClient;
    case CLIENT_ERROR:
      const clientErr = {
        ...state,
        client: {},
        clientLoading: false,
        clientMsg: action.payload,
      };
      return clientErr;
    case CLIENT_UPDATED:
      const clientUpdate = {
        ...state,
        client: { ...action.payload.client },
        clientLoading: false,
        clientMsg: action.payload.msg,
      };
      return clientUpdate;
    case SET_FAVOURITE:
      const setFav = {
        ...state,
        favourite: [...state.favourite, action.payload],
      };
      return setFav;
    case REMOVE_FAVOURITE:
      const removeFav = {
        ...state,
        favourite: [...state.favourite].filter(
          (item) => item !== action.payload
        ),
      };
      return removeFav;
    case FAV_PROVIDER_SUCCESS:
      const favProviders = {
        ...state,
        favouriteProviders: [...action.payload],
        clientLoading: false,
        clientMsg: null,
      };
      return favProviders;
    case CLEAR_MSG:
      const authMsg = {
        ...state,
        clientMsg: null,
      };
      return authMsg;
      break;
    case LOGOUT:
      const logout = {
        ...state,
        client: {},
        favouriteProviders: null,
        clientLoading: true,
        clientMsg: null,
        clientLoaded: false,
      };
      return logout;
    case POST_REVIEW:
      const postReview = {
        ...state,
      };
      return postReview;
    default:
      return state;
  }
};

import React, { Fragment, useEffect, useContext } from 'react';
import DashboardNavBar from '../appbar/DashboardNavBar';
import SideBar from '../sidebar/SideBar';
import ProviderCard from '../providerCard/ProviderCard';
import { Grid } from '@material-ui/core';
import ClientContext from '../../context/client/clientContext';
import { clientMyproviderStyles } from './clientMyproviderStyles';

const ClientMyprovider = () => {
  const classes = clientMyproviderStyles();

  const clientContext = useContext(ClientContext);

  const { favouriteProviders, getFavourite, favourite } = clientContext;

  useEffect(() => {
    getFavourite(favourite);
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <DashboardNavBar />
      <SideBar />
      <div className={classes.root}>
        <Grid container className={classes.gridContainer}>
          {favouriteProviders &&
            favouriteProviders.map((provider) => (
              <ProviderCard
                provider={provider}
                page="client"
                key={provider.public_id}
              />
            ))}
        </Grid>
      </div>
    </Fragment>
  );
};

export default ClientMyprovider;

import React, { useReducer } from 'react';
import ClientContext from './clientContext';
import clientReducer from './clientReducer';
import axios from 'axios';
import {
  CLIENT_LOADED,
  CLIENT_ERROR,
  CLIENT_UPDATED,
  CLEAR_MSG,
  FAV_PROVIDER_SUCCESS,
  SET_FAVOURITE,
  REMOVE_FAVOURITE,
  LOGOUT,
  POST_REVIEW,
} from '../actionTypes';

const ClientState = (props) => {
  const initialState = {
    client: {},
    favouriteProviders: null,
    clientLoaded: false,
    clientLoading: true,
    clientMsg: null,
    favourite: [],
  };

  const [state, dispatch] = useReducer(clientReducer, initialState);

  const checkExpiry = (res) => {
    if (res.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('user_role');
      localStorage.removeItem('adminToken');
      localStorage.removeItem('public_id');

      return (window.location = '/');
    }
  };

  // ************** Get single client Info *************
  const getClient = async (id) => {
    clearMsg();

    try {
      let client = state.client;

      if (!state.clientLoaded) {
        const res = await axios.get(`/api/client/${id}`);

        client = res.data.client;
      }

      dispatch({
        type: CLIENT_LOADED,
        payload: client,
      });
    } catch (err) {
      checkExpiry(err.response);

      const { msg } = err.response.data;
      dispatch({ type: CLIENT_ERROR, payload: msg });
    }
  };

  // *************** Update User Info *******************
  const updateClient = async (formData, id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    clearMsg();
    try {
      const res = await axios.put(`/api/client/${id}`, formData, config);
      dispatch({
        type: CLIENT_UPDATED,
        payload: res.data,
      });
    } catch (err) {
      const { msg } = err.response.data;
      dispatch({
        type: CLIENT_ERROR,
        payload: msg,
      });
    }
  };

  const uploadImage = async (e, id, type) => {
    const file = e.target.files[0];
    const data = new FormData();

    data.append('file', file);
    data.append('id', id);
    data.append('type', type);

    try {
      const res = await axios.post(`/api/upload`, data);

      dispatch({
        type: CLIENT_UPDATED,
        payload: res.data,
      });
    } catch (err) {
      const { msg } = err.response.data;

      dispatch({
        type: CLIENT_ERROR,
        payload: msg,
      });
    }
  };

  // ************ set Favourite Provider ************
  const setFavourite = (email, id) => {
    if (!id) {
      dispatch({
        type: SET_FAVOURITE,
        payload: email,
      });
    } else {
      dispatch({
        type: REMOVE_FAVOURITE,
        payload: email,
      });
    }
  };

  // ************* Get favourite Providers *******
  const getFavourite = async (array) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.get('/api/client/favourite', config);
      const { providers } = res.data;
      dispatch({
        type: FAV_PROVIDER_SUCCESS,
        payload: providers,
      });
    } catch (err) {
      checkExpiry(err.response);

      const { msg } = err.response.data;

      dispatch({
        type: CLIENT_ERROR,
        payload: msg,
      });
    }
  };
  // ************* Clear Message ***************
  const clearMsg = () => {
    dispatch({
      type: CLEAR_MSG,
    });
  };

  // ************** Logout *********************
  const clientLogout = () =>
    dispatch({ type: LOGOUT, payload: 'Successfully logged out' });

  // ************** Post Review ****************
  const postReview = async (ids) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post(
        `/api/review/${ids['provider_id']}`,
        ids,
        config
      );

      dispatch({ type: POST_REVIEW, payload: res.data });
    } catch (err) {
      dispatch({ type: POST_REVIEW, payload: 'Review error' });
    }
  };

  return (
    <ClientContext.Provider
      value={{
        client: state.client,
        clientLoading: state.clientLoading,
        clientMsg: state.clientMsg,
        favouriteProviders: state.favouriteProviders,
        favourite: state.favourite,
        getClient,
        updateClient,
        uploadImage,
        clearMsg,
        getFavourite,
        setFavourite,
        clientLogout,
        postReview,
      }}
    >
      {props.children}
    </ClientContext.Provider>
  );
};

export default ClientState;

import { makeStyles } from '@material-ui/core/styles';

export const providerCardStyles = makeStyles((theme) => ({
  paper: {
    position: 'relative',
    margin: theme.spacing(3),
    minHeight: '472px',
    '&:hover': {
      boxShadow: '-3px 7px 30px -17px rgba(0,0,0,0.75)',
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 1),
      minHeight: '380px',
    },
  },
  favourite: {
    position: 'absolute',
    top: '5%',
    right: '5%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  homeIcon: {
    position: 'absolute',
    top: '5%',
    left: '5%',
    color: '#7c9cbb',
  },
  travelIcon: {
    position: 'absolute',
    top: '5%',
    left: '15%',
    color: '#7c9cbb',
  },
  grid: {
    textAlign: 'center',
    padding: theme.spacing(4, 12),
    [theme.breakpoints.between('sm', 'md')]: {
      padding: theme.spacing(4, 8),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 4),
    },
  },
  profilePicDiv: {
    width: '140px',
    height: '140px',
    backgroundColor: theme.bgcolor,
    borderRadius: '200px',
    textAlign: 'center',
    overflow: 'hidden',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '120px',
      height: '120px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px',
    },
  },
  profilePic: {
    width: '140px',
    height: '140px',
    color: '#ffffff',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '120px',
      height: '120px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px',
    },
  },

  profilePicUploaded: {
    width: 140,
    height: 140,
    borderRadius: 60,
    backgroundClick: `padding-box`,
    backgroundSize: `cover`,
    backgroundPosition: `center center`,
  },

  user: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    margin: theme.spacing(2.5, 0, 1),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  userRole: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
  },
  div: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
  },
  locationIcon: {
    color: theme.bgcolor,
    marginRight: theme.spacing(1),
  },
  location: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.85rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
  button: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(1.5, 3),
    color: '#ffffff',
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(1.5, 3),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(1.5, 3),
    },
  },
}));

import { makeStyles } from '@material-ui/core/styles';

export const dashboardNavStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  logoDiv: {
    width: '200px',
    height: '48px',
    margin: theme.spacing(2),
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  search: {
    marginLeft: theme.spacing(8),
    [theme.breakpoints.between('sm', 'md')]: {
      marginLeft: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  inputProps: {
    paddingRight: '0px',
  },
  searchIcon: {
    color: '#7c9cbb',
  },
  searchButton: {
    color: '#ffffff',
    padding: theme.spacing(1.2, 3),
    margin: theme.spacing(0, 1),
    boxShadow: 'none',
  },
  userPic: {
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
    [theme.breakpoints.between('sm', 'md')]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },
  userName: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuIcon: {
    display: 'none',
    padding: '10px',
    borderRadius: '5px',
    backgroundColor: theme.palette.primary.light,
    boxShadow:
      ' 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginLeft: '0px',
    },
  },
  menuLink: {
    textDecoration: 'none',
    padding: '6px 8px',
    marginLeft: theme.spacing(2),
  },
  menuActiveLink: {
    borderRight: '5px solid #3a5275',
    color: theme.palette.primary.main,
    padding: '6px 8px 6px 3px',
  },
  drawer: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  logoDivSideBar: {
    width: '200px',
    height: '48px',
    margin: theme.spacing(2, 3),
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block',
    },
  },
  drawerLink: {
    textDecoration: 'none',
    padding: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    width: '100%',
  },
  drawerActiveLink: {
    borderRight: '3px solid #3a5275',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    width: '100%',
  },
  drawerDiv: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(2),
  },
  listItem: {
    padding: theme.spacing(0),
  },
  listItemIcon: {
    marginRight: theme.spacing(1),
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    margin: theme.spacing(1, 4),
  },
  subTitle: {
    fontSize: '1rem',
  },
  logoutBtn: {
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: theme.spacing(3, 3),
  },
}));

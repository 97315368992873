import React, { useEffect, useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  TextField,
  Typography,
  Button,
  Snackbar,
  CircularProgress,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import logo from '../../../assets/MP-website-image.png';
import { useStyles } from '../../../themes/commonStyles';
import { loginSignupStyles } from './loginSignupStyles';
import AuthContext from '../../context/authentication/authContext';

const ResetPassword = (props) => {
  const classes = loginSignupStyles();
  const classesBase = useStyles();

  const authContext = useContext(AuthContext);
  const { resetAllowed, confirmPassword, clearMsg, message } = authContext;
  const { token } = props.match.params;

  const [snackbar, setSnackbar] = useState(false);
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [pwErr, setPwErr] = useState(false);
  const [pw2Err, setPw2Err] = useState(false);

  useEffect(() => {
    confirmPassword({ token });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (message === 'Password has been successfully updated try login') {
      setSnackbar(true);
    }
    // eslint-disable-next-line
  }, [message]);
  useEffect(() => {
    if (
      message === 'Password has been successfully updated try login' &&
      !snackbar
    ) {
      props.history.push('/?beta=true');
    }
    // eslint-disable-next-line
  }, [snackbar]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPwErr(false);
    setPw2Err(false);
    name === 'password' && setPassword(value);
    name === 'password2' && setPassword2(value);
  };

  const handleSnackbar = () => {
    setSnackbar(!snackbar);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    clearMsg();
    if (!password) {
      !password && setPwErr(true);
    } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(password)) {
      setPwErr(true);
    } else if (password !== password2) {
      setPw2Err(true);
    } else {
      confirmPassword({ password, token });
    }
  };

  if (resetAllowed === null) {
    return (
      <div className={classesBase.loading}>
        <CircularProgress color="secondary" />
      </div>
    );
  } else if (resetAllowed) {
    return (
      <div className={classes.root}>
        <AppBar color="inherit" className={classes.appbar}>
          <Toolbar>
            <div className={classesBase.linkDiv}>
              <div className={classesBase.logoDiv}>
                <img src={logo} alt="logo" className={classesBase.logo} />
              </div>
            </div>
          </Toolbar>
        </AppBar>

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          open={snackbar}
          onClose={handleSnackbar}
        >
          <Alert elevation={6} variant="filled" severity="success">
            {message !== null && message}
          </Alert>
        </Snackbar>

        <Typography
          variant="h4"
          color="primary"
          className={classes.resetPWTitle}
        >
          Set New Password
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Grid container direction="column" alignItems="center">
            <TextField
              id="password"
              name="password"
              label="New password"
              variant="outlined"
              type="password"
              color="secondary"
              className={classes.formTextField}
              onChange={handleChange}
              value={password}
              error={pwErr}
              helperText={
                pwErr === true
                  ? 'Must contain at least one number, one uppercase and lowercase letter, and at least 8 or more characters'
                  : ''
              }
              required
            />
            <TextField
              id="password2"
              name="password2"
              label="Confirm new password"
              variant="outlined"
              type="password"
              color="secondary"
              className={classes.formTextField}
              onChange={handleChange}
              value={password2}
              error={pw2Err}
              helperText={pw2Err === true ? 'Password does not match' : ''}
              required
            />
            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              type="submit"
            >
              Reset Password
            </Button>
          </Grid>
        </form>
      </div>
    );
  } else {
    return (
      <div>
        <AppBar color="inherit" className={classes.appbar}>
          <Toolbar>
            <div className={classesBase.linkDiv}>
              <div className={classesBase.logoDiv}>
                <img src={logo} alt="logo" className={classesBase.logo} />
              </div>
            </div>
          </Toolbar>
        </AppBar>
        <Typography color="error" className={classes.tokenErrMsg}>
          Sorry, this reset password link is not valid or has expired
        </Typography>
      </div>
    );
  }
};

export default withRouter(ResetPassword);

import { makeStyles } from '@material-ui/core/styles';

export const clientUserInfoStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 420px)',
    marginTop: '130px',
    marginLeft: '380px',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
      marginLeft: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px',
      textAlign: 'center',
    },
  },
  form: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '40px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
  },
  grid: {
    margin: theme.spacing(2, 0),
  },
  profileDivContainer: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  profileItemDiv: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: '40px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0px',
    },
  },
  profilePicDiv: {
    width: '140px',
    height: '140px',
    backgroundColor: theme.bgcolor,
    borderRadius: '200px',
    margin: theme.spacing(0, 5, 4, 0),
    textAlign: 'center',
    overflow: 'hidden',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '120px',
      height: '120px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px',
    },
  },
  profilePic: {
    width: '120px',
    height: '120px',
    color: '#ffffff',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '100px',
      height: '100px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '80px',
      height: '80px',
    },
  },

  profilePicUploaded: {
    width: 140,
    height: 140,
    borderRadius: 60,
    backgroundClick: `padding-box`,
    backgroundSize: `cover`,
    backgroundPosition: `center center`,
  },

  div: {
    display: 'flex',
    flexDirection: 'row',
  },
  user: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  location: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.85rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
  locationIcon: {
    color: theme.bgcolor,
    marginRight: theme.spacing(1),
  },
  input: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    borderRadius: 7,
    color: theme.palette.primary.main,
    fontSize: 16,
    width: '320px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '200px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '220px',
    },
  },
  saveButton: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(1.5, 3),
    color: '#ffffff',
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(1.5, 3),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(1.5, 3),
    },
  },
  divider: {
    margin: theme.spacing(4, 0),
    width: '800px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '530px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '380px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '280px',
      margin: theme.spacing(4, 'auto'),
    },
  },
  title: {
    margin: theme.spacing(6, 0, 2),
    fontSize: '1.2rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
}));

import { makeStyles } from '@material-ui/core/styles';

export const loginSignupStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appbar: {
    boxShadow: 'none',
  },
  tokenErrMsg: {
    marginTop: theme.spacing(18),
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  signupDialogTitle: {
    margin: theme.spacing(4, 0, 1),
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(3, 0, 0),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3, 0, 0),
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(3, 0, 1),
    },
  },
  loginDialogTitle: {
    margin: theme.spacing(4, 0),
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(3, 0),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(3, 0),
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(3, 0),
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '1.8rem',
    fontWeight: 'bold',
  },
  form: {
    margin: theme.spacing(0, 8),
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(0, 7),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 5),
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 3),
    },
  },
  formTextField: {
    margin: theme.spacing(0.5),
    width: '360px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '300px',
      margin: theme.spacing(0.3),
    },
    [theme.breakpoints.down('sm')]: {
      width: '300px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '280px',
    },
  },
  buttonContainer: {
    margin: theme.spacing(0.5),
    width: '360px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: theme.spacing(0.5),
    borderRadius: '200px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '300px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '300px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '280px',
    },
  },
  joinButton: {
    marginTop: theme.spacing(5),
    color: '#ffffff',
    fontSize: '1rem',
    fontWeight: 'bold',
    width: '360px',
    height: '60px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '300px',
      height: '50px',
      marginTop: theme.spacing(3.3),
    },
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      height: '50px',
      marginTop: theme.spacing(2.5),
    },
    [theme.breakpoints.down('xs')]: {
      width: '280px',
      height: '50px',
      marginTop: theme.spacing(4),
    },
  },
  loginButton: {
    margin: theme.spacing(5, 0, 2),
    color: '#ffffff',
    fontSize: '1rem',
    fontWeight: 'bold',
    width: '360px',
    height: '60px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '300px',
      height: '50px',
      margin: theme.spacing(3.3, 0, 3.3),
    },
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      height: '50px',
      margin: theme.spacing(4, 0, 4),
    },
    [theme.breakpoints.down('xs')]: {
      width: '280px',
      height: '50px',
      margin: theme.spacing(4, 0, 4),
    },
  },
  submitButton: {
    margin: theme.spacing(5, 0, 7),
    color: '#ffffff',
    fontSize: '1rem',
    fontWeight: 'bold',
    width: '360px',
    height: '60px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '300px',
      height: '50px',
      margin: theme.spacing(3.3, 0, 3.3),
    },
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      height: '50px',
      margin: theme.spacing(4, 0, 4),
    },
    [theme.breakpoints.down('xs')]: {
      width: '280px',
      height: '50px',
      margin: theme.spacing(4, 0, 4),
    },
  },
  caption: {
    margin: theme.spacing(1, 19),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 5),
    },
  },
  captionFP: {
    margin: theme.spacing(1, 0, 2),
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 5),
    },
  },
  resetPWTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
  subtitleText: {
    margin: theme.spacing(0, 14, 2),
    textAlign: 'center',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 8),
    },
  },
  dialogActions: {
    margin: theme.spacing(1),
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(0.5),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
  },
  actionButton: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
}));

import React, { useReducer } from 'react';
import AuthContext from './authContext';
import authReducer from './authReducer';
import axios from 'axios';
import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  EMAIL_SUCCESS,
  EMAIL_FAIL,
  TOKEN_SUCCESS,
  TOKEN_FAIL,
  PASSWORD_UPDATED,
  PASSWORD_UPDATE_FAIL,
  LOGOUT,
  CLEAR_MSG,
} from '../actionTypes';
import setAuthToken from '../../utility/setAuthToken';

const AuthState = (props) => {
  const initialState = {
    user: JSON.parse(localStorage.getItem('user')),
    token: localStorage.getItem('token'),
    user_role: localStorage.getItem('user_role'),
    isAuthenticated: null,
    loading: true,
    message: null,
    emailSuccess: null,
    resetAllowed: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  // *************** Load user ***************
  const loadUser = async () => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    try {
      const res = await axios.get('/api/login');
      // console.log(res.data.user);
      const { user } = res.data;
      dispatch({
        type: USER_LOADED,
        payload: user,
      });
    } catch (err) {
      dispatch({ type: AUTH_ERROR, payload: 'Authentication failed' });
    }
  };

  // ************** Signup user ***************
  const signup = async (formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post('/api/signup', formData, config);
      // console.log(res.data);
      dispatch({
        type: SIGNUP_SUCCESS,
        payload: res.data,
      });
      loadUser();
    } catch (err) {
      const { msg } = err.response.data;
      dispatch({
        type: SIGNUP_FAIL,
        payload: msg,
      });
    }
  };

  // ************** Login user ***************
  const login = async (formData) => {
    if (localStorage.adminToken) {
      setAuthToken(localStorage.adminToken);
    }
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post('/api/login', formData, config);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      loadUser();
    } catch (err) {
      const { msg } = err.response.data;
      dispatch({
        type: LOGIN_FAIL,
        payload: msg,
      });
    }
  };
  // ************** Forgot password ************
  const forgotPassword = async (formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post('/api/forgotPassEmail', formData, config);
      // console.log(res.data);
      dispatch({
        type: EMAIL_SUCCESS,
        payload: res.data.msg,
      });
    } catch (err) {
      const { msg } = err.response.data;
      dispatch({
        type: EMAIL_FAIL,
        payload: msg,
      });
    }
  };
  // ************** confirm Password ***********
  const confirmPassword = async (formData) => {
    if (!formData.password) {
      const config = {
        headers: {
          'reset-token': `${formData.token}`,
        },
      };
      try {
        const res = await axios.get('/api/confirm_password', config);
        dispatch({
          type: TOKEN_SUCCESS,
          payload: res.data.msg,
        });
      } catch (err) {
        const { msg } = err.response.data;
        dispatch({
          type: TOKEN_FAIL,
          payload: msg,
        });
      }
    } else {
      const config = {
        headers: {
          'reset-token': `${formData.token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const res = await axios.post(
          '/api/confirm_password',
          { password: formData.password },
          config
        );
        dispatch({
          type: PASSWORD_UPDATED,
          payload: res.data.msg,
        });
      } catch (err) {
        const { msg } = err.response.data;
        dispatch({
          type: PASSWORD_UPDATE_FAIL,
          payload: msg,
        });
      }
    }
  };

  // ************** Logout *********************
  const logout = () => {
    dispatch({ type: LOGOUT, payload: 'Successfully logged out' });
    setAuthToken();
  };

  // ************** Clear message ***************
  const clearMsg = () => {
    dispatch({
      type: CLEAR_MSG,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        token: state.token,
        message: state.message,
        user_role: state.user_role,
        isAuthenticated: state.isAuthenticated,
        emailSuccess: state.emailSuccess,
        resetAllowed: state.resetAllowed,
        signup,
        login,
        loadUser,
        forgotPassword,
        confirmPassword,
        logout,
        clearMsg,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;

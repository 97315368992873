import React, { useReducer } from 'react';
import ProviderContext from './providerContext';
import providerReducer from './providerReducer';
import axios from 'axios';
import {
  PROVIDER_LOADED,
  MY_PROVIDER_LOADED,
  PROVIDER_ERROR,
  PROVIDER_UPDATED,
  CLEAR_MSG,
  SEARCH_PROVIDER,
  NO_PROVIDER_FOUND,
  LOGOUT,
  GET_REVIEW,
  SET_FEE,
  GET_FEE,
} from '../actionTypes';

const ProviderState = (props) => {
  const initialState = {
    provider: {},
    searchedProviders: null,
    providerLoading: true,
    providerMsg: null,
    reviews: [],
    reviewAverage: null,
    allowed: true,
    feeStructure: {},
    feeLoading: true,
    myProvider: {},
    myProviderLoaded: false,
  };

  const [state, dispatch] = useReducer(providerReducer, initialState);

  const checkExpiry = (res) => {
    if (res.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('user_role');
      localStorage.removeItem('adminToken');
      localStorage.removeItem('public_id');

      return (window.location = '/');
    }
  };

  // ************** Get single Provider Info *************
  const getProvider = async (id) => {
    clearMsg();
    try {
      const res = await axios.get(`/api/provider/${id}`);
      const { provider } = res.data;
      dispatch({
        type: PROVIDER_LOADED,
        payload: provider,
      });
    } catch (err) {
      checkExpiry(err.response);

      const { msg } = err.response.data;
      dispatch({ type: PROVIDER_ERROR, payload: msg });
    }
  };

  // ************** Get Current Provider Info *************
  const getMyProvider = async (id) => {
    clearMsg();
    try {
      // console.log('is admin', localStorage.getItem('adminToken'));
      if (localStorage.getItem('adminToken')) {
        const res = await axios.get(`/api/provider/${id}`);
        const { provider } = res.data;
        dispatch({
          type: PROVIDER_LOADED,
          payload: provider,
        });

        dispatch({
          type: MY_PROVIDER_LOADED,
          payload: provider,
        });
      } else {
        console.log('trying to grab info');
        // This should ideally be moved into a context on a higher level
        // rather than being called on every page to check if its been loaded
        let provider = state.myProvider;

        if (!state.myProviderLoaded) {
          const res = await axios.get(`/api/provider/${id}`);
          provider = res.data.provider;
        }

        dispatch({
          type: MY_PROVIDER_LOADED,
          payload: provider,
        });
      }
    } catch (err) {
      checkExpiry(err.response);

      const { msg } = err.response.data;
      dispatch({ type: PROVIDER_ERROR, payload: msg });
    }
  };

  // *************** Update Provider Info *******************
  const updateProvider = async (formData, id) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    clearMsg();
    try {
      const res = await axios.put(`/api/provider/${id}`, formData, config);
      dispatch({
        type: PROVIDER_UPDATED,
        payload: res.data,
      });
    } catch (err) {
      const { msg } = err.response.data;
      dispatch({
        type: PROVIDER_ERROR,
        payload: msg,
      });
    }
  };

  const uploadImage = async (e, id, type) => {
    const file = e.target.files[0];
    const data = new FormData();

    data.append('file', file);
    data.append('id', id);
    data.append('type', type);

    try {
      const res = await axios.post(`/api/upload`, data);

      dispatch({
        type: PROVIDER_UPDATED,
        payload: res.data,
      });
    } catch (err) {
      const { msg } = err.response.data;

      dispatch({
        type: PROVIDER_ERROR,
        payload: msg,
      });
    }
  };

  // ************** Get providers for search query ****************
  const getProviders = async (query) => {
    const config = {
      params: {
        search_term: query,
      },
    };
    try {
      const res = await axios.get('/api/search', config);
      const { providers } = res.data;
      if (providers.length) {
        dispatch({
          type: SEARCH_PROVIDER,
          payload: providers,
        });
      } else {
        dispatch({
          type: NO_PROVIDER_FOUND,
          payload: 'No Result Found',
        });
      }
    } catch (err) {
      const { msg } = err.response.data;
      dispatch({
        type: PROVIDER_ERROR,
        payload: msg,
      });
    }
  };
  // ************* Clear Message ***************
  const clearMsg = () => {
    dispatch({
      type: CLEAR_MSG,
    });
  };

  // ************** Logout *********************
  const providerLogout = () =>
    dispatch({ type: LOGOUT, payload: 'Successfully logged out' });

  // ************** Get Review *****************
  const getReview = async (provider_id) => {
    const res = await axios.get(`/api/review/${provider_id}`);
    const { reviews } = res.data;

    dispatch({
      type: GET_REVIEW,
      payload: reviews,
    });
  };

  // ************** Set Fee ********************
  const setFee = async (feeStructure, provider_id) => {
    const res = await axios.post(`/api/fee/${provider_id}`, feeStructure);

    dispatch({
      type: SET_FEE,
      payload: res.data,
    });
  };
  // ************** Get Fee ********************
  const getFee = async (provider_id) => {
    const res = await axios.get(`/api/fee/${provider_id}`);
    const { feeStructure, feeValues, noLoad } = res.data;

    dispatch({
      type: GET_FEE,
      payload: {
        feeStructure: feeStructure,
        feeValues: feeValues,
        noLoad: noLoad,
      },
    });
  };
  return (
    <ProviderContext.Provider
      value={{
        provider: state.provider,
        myProvider: state.myProvider,
        providerLoading: state.providerLoading,
        providerMsg: state.providerMsg,
        searchedProviders: state.searchedProviders,
        reviews: state.reviews,
        reviewAverage: state.reviewAverage,
        allowed: state.allowed,
        feeStructure: state.feeStructure,
        feeLoading: state.feeLoading,
        getProvider,
        getMyProvider,
        updateProvider,
        uploadImage,
        clearMsg,
        getProviders,
        providerLogout,
        getReview,
        setFee,
        getFee,
      }}
    >
      {props.children}
    </ProviderContext.Provider>
  );
};

export default ProviderState;

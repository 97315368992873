import {
  OPEN_LOGIN,
  CLOSE_LOGIN,
  OPEN_SIGNUP,
  CLOSE_SIGNUP,
  SWITCH_DIALOG,
  CLOSE_PASSWORD,
  OPEN_PASSWORD,
} from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case OPEN_LOGIN:
      return {
        ...state,
        openLogin: true,
      };
    case CLOSE_LOGIN:
      return {
        ...state,
        openLogin: false,
      };
    case OPEN_SIGNUP:
      return {
        ...state,
        openSignup: true,
      };
    case CLOSE_SIGNUP:
      return {
        ...state,
        openSignup: false,
      };
    case OPEN_PASSWORD:
      return {
        ...state,
        openLogin: false,
        openPassword: true,
      };
    case CLOSE_PASSWORD:
      return {
        ...state,
        openPassword: false,
      };
    case SWITCH_DIALOG:
      return {
        ...state,
        openLogin: !state.openLogin,
        openSignup: !state.openSignup,
      };
    default:
      return state;
  }
};

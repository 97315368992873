import { GOOGLE_LOADED, GOOGLE_RELOAD, GOOGLE_ERROR } from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case GOOGLE_LOADED:
      const googleLoaded = {
        ...state,
        googleLoading: action.payload,
      };
      return googleLoaded;
    case GOOGLE_RELOAD:
      const googleReload = {
        ...state,
        googleLoading: action.payload,
      };
      return googleReload;
    case GOOGLE_ERROR:
      const googleError = {
        ...state,
        googleErr: action.payload,
      };
      return googleError;
    default:
      return state;
  }
};

import { makeStyles } from '@material-ui/core/styles';

export const adminStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  div: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  logoDiv: {
    width: '200px',
    height: '48px',
    margin: theme.spacing(2),
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(4),
  },
  formTextField: {
    margin: theme.spacing(0.5, 0),
    width: '360px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '300px',
      margin: theme.spacing(0.3),
    },
    [theme.breakpoints.down('sm')]: {
      width: '300px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '280px',
    },
  },
  loginButton: {
    margin: theme.spacing(5, 0, 5),
    color: '#ffffff',
    fontSize: '1rem',
    fontWeight: 'bold',
    width: '360px',
    height: '60px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '300px',
      height: '50px',
      margin: theme.spacing(3.3, 0, 3.3),
    },
    [theme.breakpoints.down('sm')]: {
      width: '300px',
      height: '50px',
      margin: theme.spacing(4, 0, 4),
    },
    [theme.breakpoints.down('xs')]: {
      width: '280px',
      height: '50px',
      margin: theme.spacing(4, 0, 4),
    },
  },
  navLink: {
    textDecoration: 'none',
    padding: '6px 8px',
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  activeLink: {
    color: theme.palette.secondary.main,
    borderTop: '5px solid #97DA91',
    padding: '30px 8px 35px',
  },
  navLinkTypography: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
  userPic: {
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
    [theme.breakpoints.between('sm', 'md')]: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
  },
  userName: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  usersRoot: {
    marginTop: '150px',
  },
  paper: {
    position: 'relative',
    margin: theme.spacing(3),
    minHeight: '440px',
    minWidth: '400px',
    '&:hover': {
      boxShadow: '-3px 7px 30px -17px rgba(0,0,0,0.75)',
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 1),
      minHeight: '380px',
      minWidth: '340px',
    },
  },
  homeIcon: {
    position: 'absolute',
    top: '5%',
    left: '5%',
    color: '#7c9cbb',
  },
  grid: {
    textAlign: 'center',
    padding: theme.spacing(4, 10),
    [theme.breakpoints.between('sm', 'md')]: {
      padding: theme.spacing(4, 8),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 4),
    },
  },
  profilePicDiv: {
    width: '140px',
    height: '140px',
    backgroundColor: theme.bgcolor,
    borderRadius: '200px',
    textAlign: 'center',
    overflow: 'hidden',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '120px',
      height: '120px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px',
    },
  },
  profilePic: {
    width: '140px',
    height: '140px',
    color: '#ffffff',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '120px',
      height: '120px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px',
    },
  },

  profilePicUploaded: {
    width: 140,
    height: 140,
    borderRadius: 60,
    backgroundClick: `padding-box`,
    backgroundSize: `cover`,
    backgroundPosition: `center center`,
  },

  user: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    margin: theme.spacing(2.5, 0, 1),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  userEmail: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    margin: theme.spacing(2.5, 0, 1),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  userRole: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.8rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem',
    },
  },
  locationDiv: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
  },
  locationIcon: {
    color: theme.bgcolor,
    marginRight: theme.spacing(1),
  },
  location: {
    fontSize: '0.9rem',
    fontWeight: 'bold',
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '0.85rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
  addBtnGrid: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  addBtn: {
    backgroundColor: theme.palette.secondary.main,

    '&:hover': {
      boxShadow: '0px 0px 10px -5px rgba(0,0,0,0.75)',
    },
  },
}));

import React, { useReducer } from 'react';
import AdminContext from './adminContext';
import adminReducer from './adminReducer';
import axios from 'axios';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  PROVIDER_LOADED,
  PROVIDER_ERROR,
  CLIENTS_LOADED,
  LOGOUT,
  CLEAR_MSG,
  CREATE_PROVIDER_SUCCESS,
  CREATE_PROVIDER_FAIL,
} from '../actionTypes';
import setAuthToken from '../../utility/setAuthToken';

const AdminState = (props) => {
  const initialState = {
    adminLoading: false,
    adminMessage: null,
    isAuthenticated: localStorage.getItem('auth'),
    adminToken: localStorage.getItem('adminToken'),
    allProviders: null,
  };

  const [state, dispatch] = useReducer(adminReducer, initialState);

  // *****************Login Admin **************
  const adminLogin = async (formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post('/api/admin-login', formData, config);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      const { msg } = err.response.data;
      dispatch({
        type: LOGIN_FAIL,
        payload: msg,
      });
    }
  };

  // *************** Get providers *****************
  const getProviders = async () => {
    if (localStorage.adminToken) {
      setAuthToken(localStorage.adminToken);
    }
    try {
      const res = await axios.get('/api/all-providers');
      const { providers } = res.data;
      dispatch({
        type: PROVIDER_LOADED,
        payload: providers,
      });
    } catch (err) {
      const { msg } = err.response.data;
      dispatch({ type: PROVIDER_ERROR, payload: msg });
    }
  };

  const getClients = async () => {
    if (localStorage.adminToken) {
      setAuthToken(localStorage.adminToken);
    }
    try {
      const res = await axios.get('/api/all-clients');
      const { clients } = res.data;
      dispatch({
        type: CLIENTS_LOADED,
        payload: clients,
      });
    } catch (err) {
      const { msg } = err.response.data;
      dispatch({ type: PROVIDER_ERROR, payload: msg });
    }
  };

  // ***************** create provider ***********
  const createProvider = async (formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const res = await axios.post('/api/signup', formData, config);

      dispatch({
        type: CREATE_PROVIDER_SUCCESS,
        payload: res.data.msg,
      });
      getProviders();
    } catch (err) {
      const { msg } = err.response.data;
      dispatch({
        type: CREATE_PROVIDER_FAIL,
        payload: msg,
      });
    }
  };

  // **************** Logout ******************
  const adminLogout = () => {
    dispatch({
      type: LOGOUT,
    });
    setAuthToken();
  };

  const clearMsg = () => {
    dispatch({ type: CLEAR_MSG });
  };

  return (
    <AdminContext.Provider
      value={{
        adminToken: state.adminToken,
        isAuthenticated: state.isAuthenticated,
        admin: state.admin,
        allProviders: state.allProviders,
        allClients: state.allClients,
        adminMessage: state.adminMessage,
        adminLogin,
        getProviders,
        getClients,
        createProvider,
        adminLogout,
        clearMsg,
      }}
    >
      {props.children}
    </AdminContext.Provider>
  );
};

export default AdminState;

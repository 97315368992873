import React, { useState, useContext, useEffect, Fragment } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Button,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { adminStyles } from './adminStyles';
import AdminNavbar from './AdminNavbar';
import AdminContext from '../context/admin/adminContext';

const AdminLogin = (props) => {
  const classes = adminStyles();

  const adminContext = useContext(AdminContext);
  const { adminLogin, isAuthenticated, adminMessage, clearMsg } = adminContext;

  const [errMsg, setErrMsg] = useState(null);
  const [snackbar, setSnackbar] = useState(false);
  const [admin, setAdmin] = useState({ email: '', password: '' });
  const { email, password } = admin;

  useEffect(() => {
    adminMessage && setSnackbar(true);
  }, [adminMessage]);

  useEffect(() => {
    isAuthenticated && props.history.push('/admin-dashboard/all-providers');
  }, [isAuthenticated, props.history]);

  const handleSnackbar = () => {
    setErrMsg(null);
    clearMsg();
    setSnackbar(!snackbar);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdmin({ ...admin, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !password) {
      setErrMsg('Please enter email and password');
      setSnackbar(true);
    } else {
      adminLogin({ email, password });
    }
  };

  return (
    <Fragment>
      <AdminNavbar />
      <div className={classes.root}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={2000}
          open={snackbar}
          onClose={handleSnackbar}
        >
          <Alert elevation={6} variant="filled" severity="error">
            {errMsg && errMsg}
            {adminMessage && adminMessage}
          </Alert>
        </Snackbar>
        <Typography variant="h4" color="primary" className={classes.title}>
          Admin Login
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Grid container direction="column">
            <TextField
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              color="secondary"
              className={classes.formTextField}
              onChange={handleChange}
              value={email}
              required
            />
            <TextField
              id="password"
              name="password"
              label="Password"
              variant="outlined"
              type="password"
              color="secondary"
              className={classes.formTextField}
              onChange={handleChange}
              value={password}
              required
            />
            <Button
              variant="contained"
              color="secondary"
              className={classes.loginButton}
              type="submit"
            >
              Login
            </Button>
          </Grid>
        </form>
      </div>
    </Fragment>
  );
};

export default AdminLogin;

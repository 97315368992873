import { makeStyles } from '@material-ui/core/styles';

export const sideBarStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    position: 'fixed',
    width: '280px',
    borderRight: '1px solid rgba(0,0,0,0.12)',
    display: 'flex',
    flexDirection: 'column',
    top: '0px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  sidebarDiv: {
    flexGrow: 1,
    marginTop: '110px',
  },
  menuLink: {
    textDecoration: 'none',
    padding: theme.spacing(1.5),
    color: theme.palette.text.secondary,
    width: '100%',
  },
  menuActiveLink: {
    borderRight: '3px solid #3a5275',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    width: '100%',
  },
  navDiv: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(4),
  },
  listItem: {
    padding: theme.spacing(0),
  },
  listItemIcon: {
    marginRight: theme.spacing(1),
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    margin: theme.spacing(0, 6, 1),
  },
  subTitle: {
    fontSize: '1rem',
  },
  logoutBtn: {
    fontSize: '1rem',
    fontWeight: 'bold',
    margin: theme.spacing(3, 5),
  },
  arrowBack: {
    marginLeft: theme.spacing(3),
  },
}));

import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  useMediaQuery,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Snackbar,
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton, Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme, withStyles } from '@material-ui/core/styles';
import { loginSignupStyles } from './loginSignupStyles';
import AuthContext from '../../context/authentication/authContext';
import DialogContext from '../../context/dialogContext/dialogContext';

const ForgotPassword = () => {
  const classes = loginSignupStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const authContext = useContext(AuthContext);
  const { message, clearMsg, forgotPassword, emailSuccess } = authContext;
  const dialogContext = useContext(DialogContext);
  const { handleClosePassword, openPassword } = dialogContext;

  const [alignment, setAlignment] = useState('left');
  const [userType, setUserType] = useState('client');
  const [snackbar, setSnackbar] = useState(false);
  const [user, setUser] = useState({
    email: '',
  });
  const { email } = user;

  useEffect(() => {
    message && setSnackbar(true);
    // eslint-disable-next-line
  }, [message]);

  useEffect(() => {
    if (!snackbar) {
      clearMsg();
      setUser({ email: '' });
      emailSuccess && handleClosePassword();
    }
    // eslint-disable-next-line
  }, [snackbar]);

  const handleSnackbar = () => {
    setSnackbar(!snackbar);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      newAlignment === 'left' ? setUserType('client') : setUserType('provider');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    forgotPassword({ email, user_type: userType });
  };

  // *********** Provider/Client toggle button style ************
  const StyledToggleButtonGroup = withStyles((theme, alignment) => ({
    grouped: {
      width: '174px',
      padding: theme.spacing(1.5, 2),
      border: 'none',
      '&:not(:first-child)': {
        borderRadius: '200px',
      },
      '&:first-child': {
        borderRadius: '200px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        width: '150px',
        padding: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        width: '150px',
        padding: theme.spacing(1),
      },
      [theme.breakpoints.down('xs')]: {
        width: '135px',
        padding: theme.spacing(1),
        fontSize: '1rem',
      },
    },
  }))(ToggleButtonGroup);

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={openPassword}
        onClose={handleClosePassword}
        style={{ textAlign: 'center' }}
      >
        {/* *************** DialogTitle ************** */}
        <DialogTitle className={classes.loginDialogTitle}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClosePassword}
          >
            <CloseIcon />
          </IconButton>
          <Typography className={classes.title}>Enter your email</Typography>
        </DialogTitle>

        {/* ****************** Alert Snackbar ******************* */}
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          open={snackbar}
          onClose={handleSnackbar}
        >
          <Alert
            elevation={6}
            variant="filled"
            severity={emailSuccess ? 'success' : 'error'}
          >
            {message !== null && message}
          </Alert>
        </Snackbar>

        {/* ************ DialogContent ************* */}
        <Typography
          variant="subtitle2"
          color="textSecondary"
          className={classes.subtitleText}
        >
          Enter the email you used to sign up and we will send you a reset
          password link.
        </Typography>
        <DialogContent style={{ paddingLeft: '0px', paddingRight: '0px' }}>
          <form className={classes.form} onSubmit={handleSubmit} noValidate>
            <TextField
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              type="email"
              color="secondary"
              className={classes.formTextField}
              onChange={handleChange}
              value={email}
              required
            />

            <StyledToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
              className={classes.buttonContainer}
            >
              <ToggleButton value="left">I'm Client</ToggleButton>
              <ToggleButton value="right">I'm Provider</ToggleButton>
            </StyledToggleButtonGroup>

            <Button
              variant="contained"
              color="secondary"
              className={classes.submitButton}
              type="submit"
            >
              Submit
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withRouter(ForgotPassword);

import React, { useState, useContext } from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  SwipeableDrawer,
  List,
  ListItem,
  Avatar,
  Badge,
  Divider,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import WorkOutlineOutlinedIcon from '@material-ui/icons/WorkOutlineOutlined';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { useStyles } from '../../../themes/commonStyles';
import { dashboardNavStyles } from './dashboardNavStyles';
import logo from '../../../assets/MP-website-image.png';
import AuthContext from '../../context/authentication/authContext';

const DashboardNavBar = (props) => {
  const classesBase = useStyles();
  const classes = dashboardNavStyles();

  const authContext = useContext(AuthContext);
  const { user, logout, user_role } = authContext;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [query, setQuery] = useState('');

  // Logout handler
  const handleClick = () => {
    logout();
  };

  // Handle side drawer on small devices
  const toggleDrawer = (e) => {
    setOpenDrawer(!openDrawer);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  };

  const handleSearchClick = () => {
    query !== '' && props.history.push(`/search?text=${query}`);
  };

  // Avatar style
  const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: '#97DA91',
      color: '#97DA91',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        content: '""',
      },
    },
  }))(Badge);

  return (
    <div>
      {/* ***************** Landing page Navbar ************* */}
      <AppBar color="inherit" position="fixed">
        <Toolbar>
          {/* *************** Dashboard Small devices Sidebar *************** */}
          <IconButton
            onClick={toggleDrawer}
            className={classes.menuIcon}
            aria-label="open drawer"
            edge="start"
          >
            <MenuIcon fontSize="small" style={{ color: '#ffffff' }} />
          </IconButton>
          <SwipeableDrawer
            anchor="left"
            open={openDrawer}
            onClose={toggleDrawer}
            onOpen={toggleDrawer}
            className={classes.drawer}
          >
            <div onClick={toggleDrawer}>
              {/* ***************** Logo ************************* */}
              <div className={classes.logoDivSideBar}>
                <Link to="/" className={classesBase.logoLink}>
                  <img src={logo} alt="logo" className={classesBase.logo} />
                </Link>
                <Divider />
              </div>

              {/* ******************* Content ******************** */}
              <div>
                <Typography
                  variant="h4"
                  color="primary"
                  className={classes.title}
                >
                  User Profile
                </Typography>

                {/* ****************** Provider sidebar drawer ******************** */}
                {user_role && user_role === 'provider' && (
                  <List>
                    <ListItem className={classes.listItem}>
                      <NavLink
                        to="/provider/user-info"
                        className={classes.drawerLink}
                        activeClassName={classes.drawerActiveLink}
                      >
                        <div className={classes.drawerDiv}>
                          <PermIdentityIcon className={classes.listItemIcon} />
                          <Typography className={classes.subTitle}>
                            User Info
                          </Typography>
                        </div>
                      </NavLink>
                    </ListItem>

                    <ListItem className={classes.listItem}>
                      <NavLink
                        to="/provider/profile"
                        className={classes.drawerLink}
                        activeClassName={classes.drawerActiveLink}
                      >
                        <div className={classes.drawerDiv}>
                          <WorkOutlineOutlinedIcon
                            className={classes.listItemIcon}
                          />
                          <Typography className={classes.subTitle}>
                            My Profile
                          </Typography>
                        </div>
                      </NavLink>
                    </ListItem>

                    <ListItem className={classes.listItem}>
                      <NavLink
                        to="/provider/availability"
                        className={classes.drawerLink}
                        activeClassName={classes.drawerActiveLink}
                      >
                        <div className={classes.drawerDiv}>
                          <AccessTimeOutlinedIcon
                            className={classes.listItemIcon}
                          />
                          <Typography className={classes.subTitle}>
                            Availability
                          </Typography>
                        </div>
                      </NavLink>
                    </ListItem>
                  </List>
                )}

                {/* ****************** Client sidebar drawer ******************** */}
                {user_role && user_role === 'client' && (
                  <List>
                    <ListItem className={classes.listItem}>
                      <NavLink
                        to="/client/user-info"
                        className={classes.drawerLink}
                        activeClassName={classes.drawerActiveLink}
                      >
                        <div className={classes.drawerDiv}>
                          <PermIdentityIcon className={classes.listItemIcon} />
                          <Typography className={classes.subTitle}>
                            User Info
                          </Typography>
                        </div>
                      </NavLink>
                    </ListItem>

                    <ListItem className={classes.listItem}>
                      <NavLink
                        to="/client/my-providers"
                        className={classes.drawerLink}
                        activeClassName={classes.drawerActiveLink}
                      >
                        <div className={classes.drawerDiv}>
                          <FavoriteBorderIcon
                            className={classes.listItemIcon}
                          />
                          <Typography className={classes.subTitle}>
                            My providers
                          </Typography>
                        </div>
                      </NavLink>
                    </ListItem>
                  </List>
                )}
              </div>
              <div>
                <Button
                  color="primary"
                  onClick={handleClick}
                  className={classes.logoutBtn}
                >
                  Log Out
                </Button>
              </div>
            </div>
          </SwipeableDrawer>

          {/* ***************** Logo ************************* */}
          <div className={classes.root}>
            <div className={classes.logoDiv}>
              <Link to="/" className={classesBase.logoLink}>
                <img src={logo} alt="logo" className={classesBase.logo} />
              </Link>
            </div>

            {/* ****************** Search ********************* */}
            {user_role && user_role === 'client' && (
              <TextField
                variant="outlined"
                color="secondary"
                placeholder="Find provider"
                className={classes.search}
                value={query}
                onKeyDown={handleKeyDown}
                onChange={handleSearchChange}
                InputProps={{
                  className: classes.inputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className={classes.searchIcon} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.searchButton}
                        onClick={handleSearchClick}
                      >
                        Search
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </div>

          {/* ************************ Avatar ********************** */}

          <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            variant="dot"
          >
            <Avatar alt="user pic" src="" className={classes.userPic} />
          </StyledBadge>
          <Typography variant="h6" color="primary" className={classes.userName}>
            <Link to="/client/user-info">
              {user && `${user.firstname} ${user.lastname}`}
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withRouter(DashboardNavBar);

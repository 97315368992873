import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('user_role');
    localStorage.removeItem('adminToken');
    localStorage.removeItem('public_id');

    return (window.location = '/');
  }, []);

  return 'Logging out...';
};

export default withRouter(Logout);

import React, { useState, useEffect, Fragment, useContext } from 'react';
import EarningCalc from '../../appComponent/earningCalc/EarningCalc';
import {
  Grid,
  Container,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Divider,
  Card,
  CardHeader,
  CardContent,
  Paper,
  Button,
} from '@material-ui/core';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from '../../../themes/commonStyles';
import { forProviderStyles } from './forProviderStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCheck,
  faHandHoldingMedical,
  faUserEdit,
  faUserCog,
  faClock,
  faFileSignature,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons';

import DialogContext from '../../context/dialogContext/dialogContext';

const TESTIMONIALS = [
  {
    quote:
      'Best providers and services and very friendly customer services approach. excelelnt treatment and advice',
    image: '',
    provider: 'Dr. John Smith',
    role: 'Chiropractor',
  },
];

const ForProvider = (props) => {
  const classesBase = useStyles();
  const classes = forProviderStyles();
  const dialogContext = useContext(DialogContext);
  const { handleOpenSignup, handleOpenLogin } = dialogContext;

  const [expanded, setExpanded] = useState(false);
  const [testimonial, setTestimonial] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Fragment>
      <Navbar />
      <div className={classesBase.root}>
        {/* ********************** Hero Banner ************************** */}
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.bannerContainer}
        >
          <div className={classesBase.bannerText}>
            <Typography variant="h4" className={classesBase.bannerTitle}>
              MyProvider is an online database of healthcare providers.
            </Typography>

            <Typography variant="h6" className={classesBase.bannersubTitle}>
              Join MyProvider to build your online presence.
            </Typography>
          </div>
        </Grid>

        {/* ******************************* How it works *********************************** */}
        <Grid container justify="center" className={classesBase.container}>
          <Typography variant="h4" color="primary" className={classes.title}>
            How it works
          </Typography>
        </Grid>
        <Container maxWidth="lg">
          <Grid container justify="center">
            <Grid item xs={12} sm={3} className={classesBase.container}>
              <div className={classes.badges}>
                <FontAwesomeIcon
                  icon={faUserCheck}
                  className="fa-4x"
                  style={{ marginTop: '10px', color: '#7c9cbb' }}
                />
              </div>

              <Typography
                variant="h6"
                color="primary"
                className={classes.subTitle}
              >
                Create an online profile.
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className={classes.disabled}
              >
                You're now listed and ready to be found.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={1} className={classes.direction}>
              <ChevronRightIcon className={classes.arrowRight} />
              <ExpandMoreIcon className={classes.arrowDown} />
            </Grid>
            <Grid item xs={12} sm={3} className={classesBase.container}>
              <div className={classes.badges}>
                <FontAwesomeIcon
                  icon={faUserEdit}
                  className="fa-4x"
                  style={{ marginTop: '10px', color: '#7c9cbb' }}
                />
              </div>

              <Typography
                variant="h6"
                color="primary"
                className={classes.subTitle}
              >
                Set your hours, location radius and service costs
              </Typography>
            </Grid>
            <Grid item xs={12} sm={1} className={classes.direction}>
              <ChevronRightIcon className={classes.arrowRight} />
              <ExpandMoreIcon className={classes.arrowDown} />
            </Grid>
            <Grid item xs={12} sm={3} className={classesBase.container}>
              <div className={classes.badges}>
                <FontAwesomeIcon
                  icon={faHandHoldingMedical}
                  className="fa-4x"
                  style={{ marginTop: '10px', color: '#7c9cbb' }}
                />
              </div>

              <Typography
                variant="h6"
                color="primary"
                className={classes.subTitle}
              >
                Treat your patients.
              </Typography>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className={classes.disabled}
              >
                Online, in clinic, in home. It's up to you.
              </Typography>
            </Grid>
          </Grid>
        </Container>

        {/* ******************* How it works with MyProvider *************************** */}
        <Grid container justify="center" className={classesBase.container}>
          <Typography variant="h4" color="primary" className={classes.title}>
            Why MyProvider?
          </Typography>
        </Grid>
        <Container maxWidth="lg">
          <Grid
            container
            alignItems="center"
            justify="center"
            className={classesBase.container}
          >
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <FontAwesomeIcon
                icon={faUserCog}
                className="fa-2x"
                style={{ margin: '15px 0px 10px', color: '#97DA91' }}
              />

              <Typography
                color="primary"
                variant="h4"
                className={classes.subTitle}
              >
                Build your own practice
              </Typography>
              <Typography
                color="textSecondary"
                variant="h4"
                className={classes.subText}
              >
                The freedom of running your own practice and generating your own
                leads.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <FontAwesomeIcon
                icon={faChartLine}
                className="fa-2x"
                style={{ margin: '15px 0px 10px', color: '#97DA91' }}
              />
              <Typography
                color="primary"
                variant="h4"
                className={classes.subTitle}
              >
                Increase your online presence
              </Typography>
              <Typography
                color="textSecondary"
                variant="h4"
                className={classes.subText}
              >
                Create a profile and showcase why you would be a great choice as
                a healthcare professional.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <FontAwesomeIcon
                icon={faFileSignature}
                className="fa-2x"
                style={{ margin: '15px 0px 10px', color: '#97DA91' }}
              />
              <Typography
                color="primary"
                variant="h4"
                className={classes.subTitle}
              >
                No minimum commitments
              </Typography>
              <Typography
                color="textSecondary"
                variant="h4"
                className={classes.subText}
              >
                Treat as many or as few clients as you&#39;d like.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridItem}>
              <FontAwesomeIcon
                icon={faClock}
                className="fa-2x"
                style={{ margin: '15px 0px 10px', color: '#97DA91' }}
              />
              <Typography
                color="primary"
                variant="h4"
                className={classes.subTitle}
              >
                Work when and where you want
              </Typography>
              <Typography
                color="textSecondary"
                variant="h4"
                className={classes.subText}
              >
                Set your own availability and travel radius according to your
                schedule. Work full time or to supplement your income.
              </Typography>
            </Grid>
          </Grid>
        </Container>

        {/* *************************** Annual plans ************************** */}
        <Container maxWidth="lg">
          <Grid
            container
            className={classes.planContainer}
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="primary"
                className={classes.title}
              >
                Create your personal brand
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                className={classes.signupCTA}
                onClick={handleOpenSignup}
              >
                Click here to create your FREE profile
              </Button>
            </Grid>

            <Typography variant="body2">
              To be listed on MyProvider you must be licensed and registered in
              good standing with your regulatory body.
            </Typography>
          </Grid>
        </Container>

        {/* Testimonials */}
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classesBase.container}
        >
          <Typography variant="h6" color="primary" className={classes.title}>
            What other providers are saying
          </Typography>

          <Grid item xs={12} sm={6}>
            <Paper elevation={3} className={classes.testimonial}>
              <Typography
                color="textSecondary"
                variant="h4"
                className={classes.subText}
              >
                {TESTIMONIALS[testimonial].quote}
              </Typography>

              <Typography
                color="textSecondary"
                variant="h4"
                className={classes.subText}
              >
                {TESTIMONIALS[testimonial].provider}
              </Typography>

              <Typography
                color="textSecondary"
                variant="h4"
                className={classes.subText}
              >
                {TESTIMONIALS[testimonial].role}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* ********************** Grid container Calc ************************** */}
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classesBase.container}
        >
          <EarningCalc />
        </Grid>

        {/* ********************** Random ************************** */}
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classesBase.container}
        >
          <Typography
            variant="h6"
            color="secondary"
            className={classes.titleSecondary}
          >
            At MyProvider a vulnerable-sector background check is required for
            all providers wishing to provide in-home care. Instructions on how
            to complete this will be discussed during sign up. Give us a call or
            send us an email and get started today.
          </Typography>
        </Grid>

        {/* ********************** Grid container3 FAQ ************************** */}
        <Grid container justify="center" className={classesBase.container}>
          <Typography variant="h6" color="primary" className={classes.title}>
            Frequently Asked Questions for provider
          </Typography>
        </Grid>
        <Grid container className={classes.faqContainer}>
          {/* ******************* ExpanPanel 1 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                Who decides the price of appointments?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                Each provider has the freedom to charge the price they deem
                appropriate, within their respective governing bodies’
                guidelines. MyProvider will assist this process by giving
                providers an appropriate range/suggested price.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 2 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                How long does a subscription to MyProvider last?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                Subscriptions are on a month to month basis, and can be
                cancelled at any time. Your first year is FREE! So give us a
                try.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 3 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                Do I get paid per hour or per patient?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                Providers are paid per patient and take home at least 70% of the
                appointment costs.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 6 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel6'}
            onChange={handleChange('panel6')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6-content"
              id="panel6-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                Who can use MyProvider?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                We welcome a variety of healthcare professionals. If you are a
                member of a registered healthcare profession we will work to
                make our program suitable to your needs. This includes but is
                not limited to: Chiropractors, Massage Therapists,
                Physiotherapists, Naturopaths, Psychologists, Dietitians,
                Personal Trainers and many more. If you do not see your
                professional category please reach out to our customer support
                at&nbsp;
                <a
                  href="mailto:info@myprovider.ca"
                  className={classesBase.contactLink}
                >
                  <strong>info@myprovider.ca</strong>
                </a>
                &nbsp;for assistance.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 7 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel7'}
            onChange={handleChange('panel7')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7-content"
              id="panel7-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                Is MyProvider for full-time or part-time providers?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                MyProvider is a no-commitment platform and can be used by
                full-time and part-time providers alike. With MyProvider you get
                paid per client and set your own schedule, so you may treat as
                many, or as few patients as you would like.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 8 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel8'}
            onChange={handleChange('panel8')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel8-content"
              id="panel8-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                How do I apply to MyProvider?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                Get started by contacting our MyProvider team by email, or
                telephone. We make getting started as easy as possible. Complete
                your Profile information, professional verification and
                background check, and you are all set.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 9 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel9'}
            onChange={handleChange('panel9')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9-content"
              id="panel9-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                Do I need to be a licensed practitioner to use MyProvider?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                Yes. All providers on MyProvider are required to provide proof
                of registration before being accepted onto the database. They
                will also have to prove professional liability insurance and
                pass a criminal background check.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 10 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel10'}
            onChange={handleChange('panel10')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel10-content"
              id="panel10-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                Is MyProvider a health agency?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                No. MyProvider is a connection point between clients and
                providers. We are a Practice Management Software that will help
                you manage and grow your clientele. We also aid in the
                acquisition of new clients. MyProvider does not make any
                representations or guarantees in regards to the quality of any
                Care services offered.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 12 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel12'}
            onChange={handleChange('panel12')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel12-content"
              id="panel12-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                Can I chat with clients through MyProvider?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                Yes. Using a third party platform, you will be able to
                communicate directly with your clients. We also offer Virtual
                Care sessions, so you will always have a contact point with your
                clients.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 15 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel15'}
            onChange={handleChange('panel15')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel15-content"
              id="panel15-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                How long does it take to create a profile?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                Getting started with MyProvider is quick and easy and should
                only take a few minutes. The first step is creating a profile,
                then you can be professionally verified and your profile will be
                live to be found by clients.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 16 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel16'}
            onChange={handleChange('panel16')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel16-content"
              id="panel16-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                Does MyProvider provide patients/clients?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                Yes! We are constantly working to ensure a steady supply of
                clients. Connecting clients with healthcare providers is the
                purpose of MyProvider. We also offer the services of industry
                experts to help you build and maintain a healthy practice.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel 17 ********************* */}
          <ExpansionPanel
            expanded={expanded === 'panel17'}
            onChange={handleChange('panel17')}
            className={classes.expansionPanel}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel17-content"
              id="panel17-header"
            >
              <Typography color="primary" className={classes.faqTitle}>
                What is Virtual Care?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="textSecondary" className={classes.faqDisabled}>
                Virtual care allows healthcare providers to schedule and treat
                patients through video chat appointments. This is useful for
                clients that do not need in-person treatment or simply require a
                follow up appointment. Virtual care allows you to treat anyone
                in the province of which you practise, at a time that suits you!
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          {/* ******************* ExpanPanel last child ********************* */}
          <ExpansionPanel className={classes.expansionPanel}>
            <Typography color="textSecondary" className={classes.faqInfo}>
              Do you have questions we haven't yet answered? Email us at&nbsp;
              <a
                href="mailto:info@myprovider.ca"
                className={classesBase.contactLink}
              >
                <strong>info@myprovider.ca</strong>
              </a>
              &nbsp; or call us today to have one of our team members get back
              to you with answers as quickly as possible!
            </Typography>
          </ExpansionPanel>
        </Grid>
        <Divider />
      </div>
      <Footer />
    </Fragment>
  );
};

export default ForProvider;

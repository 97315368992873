import React, { useEffect, useContext, Fragment } from 'react';
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import { Grid, Typography, Divider } from '@material-ui/core';
import { useStyles } from '../../../themes/commonStyles';
import { aboutUsStyles } from './aboutUsStyles';
import AuthContext from '../../context/authentication/authContext';

const AboutUs = (props) => {
  const classesBase = useStyles();
  const classes = aboutUsStyles();

  const authContext = useContext(AuthContext);
  const { isAuthenticated, user } = authContext;

  useEffect(() => {
    if (isAuthenticated && user !== null && user.user_type === 'provider') {
      props.history.push('/provider/user-info');
    }
  }, [isAuthenticated, props.history, user]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Navbar />
      <div className={classesBase.root}>
        {/* ********************** Hero Banner ************************** */}
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.bannerContainer}
        >
          <div className={classesBase.bannerText}>
            <Typography variant="h4" className={classesBase.bannerTitle}>
              Technology enables us to push
              <br /> the boundaries of healthcare.
            </Typography>
          </div>
        </Grid>

        {/* ********************** Grid container1 ************************** */}
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classesBase.container}
        >
          <Typography
            variant="h5"
            color="secondary"
            className={classes.titleSecondary}
          >
            Connecting clients and providers for a healthier world.
          </Typography>
          <Typography
            variant="h6"
            color="primary"
            className={classesBase.titlePrimary}
          >
            OUR MISSION
          </Typography>
          <Typography color="textSecondary" className={classesBase.textDisable}>
            <b>MyProvider</b> is first off a database of healthcare providers.
            MyProvider was built to increase transparency in the healthcare
            field. And help providers and patients find each other, whilst
            building your own practise.
            <br />
            <br />
            Our fast growing database of experienced healthcare professionals
            puts your health needs first. We are proud to provide the highest
            level of customer service and medical experience so you don't lose
            any time getting the treatment and care you deserve.
            <br />
            <br />
            If you want to join us in making healthcare accessible to all, give
            us a shout and see if we have a position that will suit your
            interests and strengths. We're looking for high-achieving, driven
            individuals committed to making a lasting impact on the healthcare
            industry.
          </Typography>
        </Grid>
        <Divider />
      </div>
      <Footer />
    </Fragment>
  );
};

export default AboutUs;

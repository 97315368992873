import {
  PROVIDER_LOADED,
  MY_PROVIDER_LOADED,
  PROVIDER_ERROR,
  PROVIDER_UPDATED,
  CLEAR_MSG,
  SEARCH_PROVIDER,
  NO_PROVIDER_FOUND,
  LOGOUT,
  GET_REVIEW,
  SET_FEE,
  GET_FEE,
} from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case PROVIDER_LOADED:
      const loadProvider = {
        ...state,
        provider: { ...action.payload },
        providerLoading: false,
        providerMsg: null,
      };
      return loadProvider;
    case MY_PROVIDER_LOADED:
      const myProvider = {
        ...state,
        myProvider: { ...action.payload },
        myProviderLoaded: true,
      };
      return myProvider;
    case PROVIDER_UPDATED:
      const providerUpdate = {
        ...state,
        provider: { ...action.payload.provider },
        myProvider: { ...action.payload.provider },
        providerLoading: false,
        providerMsg: action.payload.msg,
      };
      return providerUpdate;
    case SEARCH_PROVIDER:
      const searchProviders = {
        ...state,
        searchedProviders: [...action.payload],
        providerLoading: false,
        providerMsg: null,
        provider: {},
      };
      return searchProviders;
    case NO_PROVIDER_FOUND:
      const notFound = {
        ...state,
        searchedProviders: null,
        providerLoading: false,
        providerMsg: null,
        provider: {},
      };
      return notFound;
    case PROVIDER_ERROR:
      const providerErr = {
        ...state,
        provider: {},
        providerLoading: false,
        providerMsg: action.payload,
      };
      return providerErr;
    case CLEAR_MSG:
      const authMsg = {
        ...state,
        providerMsg: null,
      };
      return authMsg;
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('user_role');
      localStorage.removeItem('adminToken');
      localStorage.removeItem('public_id');

      const logout = {
        ...state,
        client: {},
        favouriteProviders: null,
        clientLoading: true,
        clientMsg: null,
        myProviderLoaded: false,
      };
      return logout;
    case GET_REVIEW:
      let average = 0;
      let canPost = true;
      action.payload.forEach((element) => {
        average += element.rating;
        if (
          element.client_public_id ===
          JSON.parse(localStorage.getItem('user')).public_id
        ) {
          canPost = false;
        }
      });
      const review = {
        ...state,
        reviews: action.payload,
        reviewAverage: average / action.payload.length,
        allowed: canPost,
      };
      return review;
    case SET_FEE:
      const providerfeeStructure = {
        ...state,
        feeLoading: action.payload.noLoad ? true : false,
      };
      return providerfeeStructure;
    case GET_FEE:
      const providerFeeStructure = {
        ...state,
        feeStructure: action.payload,
        feeLoading: action.payload.noLoad ? true : false,
      };
      return providerFeeStructure;
    default:
      return state;
  }
};

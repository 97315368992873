import React, { Fragment, useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import DashboardNavBar from '../appbar/DashboardNavBar';
import SideBar from '../sidebar/SideBar';
import SetAvailibility from '../setAvailability/SetAvailability';
import {
  Button,
  Typography,
  Switch,
  Paper,
  IconButton,
  Snackbar,
  Divider,
  Container,
  FormControlLabel,
  Grid,
  InputLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import { providerAvailabilityStyles } from './providerAvailabilityStyles';
import AuthContext from '../../context/authentication/authContext';
import ProviderContext from '../../context/provider/providerContext';
import GoogleAutocomplete from '../googleAutocomplete/googleAutocomplete';

const ProviderAvailability = (props) => {
  const classes = providerAvailabilityStyles();

  const authContext = useContext(AuthContext);
  const providerContext = useContext(ProviderContext);

  const { user } = authContext;
  const {
    getMyProvider,
    myProvider,
    provider,
    updateProvider,
    providerMsg,
    clearMsg,
  } = providerContext;

  const [toggle, setToggle] = useState(false);
  const [hours, setHours] = useState([]);
  const [dayHours, setDayHours] = useState({
    id: '',
    day: '',
    start: '',
    end: '',
  });
  const [saveBtn, setSaveBtn] = useState(true);
  const [snackbar, setSnackbar] = useState(false);
  const [localUser, setLocalUser] = useState({
    availability_note: '',
    home_visit_hours: [],
  });
  const [autoCompleteOption, setAutoCompleteOption] = useState(undefined);
  const [googleToken, setGoogleToken] = useState('');

  const {
    availability_note,
    home_visit_location,
    home_visit_distance,
  } = localUser;

  console.log(localUser);

  useEffect(() => {
    user &&
      user.user_type === 'client' &&
      props.history.push('/client/user-info');

    user && getMyProvider(user.public_id);
  }, []);

  useEffect(() => {
    setLocalUser({
      home_visit_hours: myProvider.home_visit_hours,
      availability_note: myProvider.availability_note,
      home_visit_location: myProvider.home_visit_location,
      home_visit_distance: myProvider.home_visit_distance,
    });
    setToggle(myProvider.home_visit);
    setHours(myProvider.home_visit_hours);
    // eslint-disable-next-line
  }, [myProvider]);

  useEffect(() => {
    if (autoCompleteOption !== undefined && autoCompleteOption !== null) {
      if (autoCompleteOption.description !== home_visit_location) {
        let service = new window.google.maps.places.PlacesService(
          document.getElementById('map')
        );

        service.getDetails(
          {
            placeId: autoCompleteOption.place_id,
            fields: ['geometry'],
            sessionToken: googleToken,
          },
          (results, status) => {
            if (status === 'OK') {
              setLocalUser((prevState) => {
                return {
                  ...prevState,
                  travel_visit: true,
                  home_visit_location: autoCompleteOption.description,
                  home_visit_lat: results.geometry.location.lat(),
                  home_visit_lng: results.geometry.location.lng(),
                };
              });
            }
          }
        );
      }
    } else if (autoCompleteOption !== undefined) {
      setLocalUser((prevState) => {
        return {
          ...prevState,
          travel_visit: false,
          home_visit_location: '',
          home_visit_lat: 0,
          home_visit_lng: 0,
          home_visit_distance: 0,
        };
      });
    }
  }, [autoCompleteOption]);

  useEffect(() => {
    providerMsg === 'Changes saved successfully' && setSnackbar(true);
    // eslint-disable-next-line
  }, [provider]);

  const handleSubmit = () => {
    updateProvider(
      {
        home_visit: toggle,
        home_visit_hours: hours,
        availability_note,
        home_visit_distance,
        home_visit_location,
      },
      user.public_id
    );
    setSaveBtn(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSaveBtn(false);
    setLocalUser((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleSwitch = (e) => {
    setToggle((prevState) => !prevState);
    setSaveBtn(false);
  };

  const handleHourChange = (e) => {
    const { name, value } = e.target;
    setDayHours({ ...dayHours, id: uuidv4(), [name]: value });
  };

  const handleHourClick = () => {
    setHours([...hours, dayHours]);
    setDayHours({
      id: '',
      day: '',
      start: '',
      end: '',
    });
    setSaveBtn(false);
  };

  const handleSnackbar = () => {
    setSnackbar(!snackbar);
    clearMsg();
  };

  const handleRemoveHour = (e) => {
    const currentHours = hours;
    const filteredhours = currentHours.filter(
      (hour) => hour.id !== e.currentTarget.name
    );
    setSaveBtn(false);
    setHours(filteredhours);
  };

  return (
    <Fragment>
      <DashboardNavBar />
      <SideBar />
      <div className={classes.root}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          autoHideDuration={2000}
          open={snackbar}
          onClose={handleSnackbar}
        >
          <Alert elevation={6} variant="filled" severity="success">
            {providerMsg}
          </Alert>
        </Snackbar>

        {/* ************* Virtual visit option ************ */}
        <FormControlLabel
          className={classes.switchText}
          control={
            <Switch checked={toggle} onChange={handleSwitch} name="checked" />
          }
          label="Virtual visit"
          labelPlacement="start"
        />
        <div>
          {/* ************* Availability hours for clinic **************** */}
          <Typography color="primary" variant="h6" className={classes.title}>
            Available timing for a virtual and home visit
          </Typography>
          <Container
            maxWidth="md"
            style={{ paddingLeft: '0px', marginLeft: '0px' }}
          >
            <SetAvailibility
              handleHourChange={handleHourChange}
              handleHourClick={handleHourClick}
              dayHours={dayHours}
            />

            {/* ************* Timing paper **************** */}
            <div style={{ width: '75%' }}>
              {hours &&
                hours.map(({ day, start, end, id }) => (
                  <Paper
                    variant="outlined"
                    elevation={0}
                    className={classes.paperHours}
                    key={id}
                  >
                    {day} - {start} to {end}
                    <IconButton
                      aria-label="remove"
                      className={classes.removeIconBtn}
                      onClick={handleRemoveHour}
                      name={id}
                    >
                      <CloseIcon className={classes.removeIcon} />
                    </IconButton>
                  </Paper>
                ))}
            </div>
          </Container>
          <div className={classes.divider}>
            <Divider />
          </div>

          {/* ************* Availability hours HOME VISITS **************** */}
          <Typography color="primary" variant="h6" className={classes.title}>
            Add your availability for home visit
          </Typography>
          <Grid
            container
            spacing={4}
            justify="center"
            alignItems="center"
            className={classes.gridContainerHomeVisit}
          >
            <Grid item xs={12} sm={5} className={classes.gridItemHomeVisit}>
              <GoogleAutocomplete
                label="Traveling from?"
                placeholder="A1A 1A1"
                full={true}
                receiveData={(option) => {
                  setAutoCompleteOption(option);
                  setSaveBtn(false);
                }}
                receiveToken={(token) => setGoogleToken(token)}
                currentPlace={home_visit_location ? home_visit_location : ''}
                shouldSave={() => {
                  setSaveBtn(true);
                }}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <FormControl>
                <InputLabel shrink htmlFor="home_visit_distance">
                  How far are you willing to travel?
                </InputLabel>
                <OutlinedInput
                  id="home_visit_distance"
                  name="home_visit_distance"
                  type="number"
                  color="secondary"
                  placeholder="10"
                  className={classes.distance}
                  value={home_visit_distance || 0}
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <Typography style={{ textTransform: 'lowercase' }}>
                        km
                      </Typography>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* *************** Note ******** */}
          <Typography color="primary" variant="h6" className={classes.title}>
            Note
          </Typography>
          <textarea
            type="text"
            id="availability_note"
            name="availability_note"
            className={classes.textArea}
            placeholder="Add note..."
            value={availability_note}
            onChange={handleChange}
          />
        </div>
        <Button
          disabled={saveBtn}
          variant="contained"
          color="secondary"
          className={classes.saveButton}
          onClick={handleSubmit}
        >
          Save changes
        </Button>
      </div>
    </Fragment>
  );
};

export default ProviderAvailability;

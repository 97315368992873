import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  CircularProgress,
  Container,
} from '@material-ui/core';
import Navbar from '../../baseComponent/navbar/Navbar';
import ProviderCard from '../providerCard/ProviderCard';
import FilterProvider from '../filterProviders/FilterProvider';
import { searchProviderStyles } from './searchProviderStyles';
import { useStyles } from '../../../themes/commonStyles';
import ProviderContext from '../../context/provider/providerContext';
import ClientContext from '../../context/client/clientContext';

const SearchProvider = (props) => {
  const classes = searchProviderStyles();
  const classesBase = useStyles();

  const providerContext = useContext(ProviderContext);
  const clientContext = useContext(ClientContext);

  const { searchedProviders, getProviders, providerLoading } = providerContext;
  const { clearMsg, client } = clientContext;

  const [toggle, setToggle] = useState(false);
  const [filter, setFilter] = useState('All');
  const [allChecked, setAllChecked] = useState(true);
  const [filterProvider, setFilterProvider] = useState(null);
  const [clientAddress, setClientAddress] = useState('');

  useEffect(() => {
    setFilterProvider(searchedProviders);
    if (client)
      setClientAddress({
        address: `${client.unit}-${client.street} ${client.province} ${client.city} ${client.postal_code}`,
      });
  }, [searchedProviders]);

  useEffect(() => {
    if (allChecked) {
      setToggle(false);
      setFilterProvider(searchedProviders);
    }
    // eslint-disable-next-line
  }, [allChecked]);

  useEffect(() => {
    if (searchedProviders && !allChecked) {
      const tempProviders = searchedProviders;
      const newProvider = tempProviders.filter(
        (provider) => provider.home_visit === toggle
      );
      if (!toggle) setAllChecked(true);
      setFilterProvider(newProvider);
    }
    // eslint-disable-next-line
  }, [toggle]);

  useEffect(() => {
    clearMsg();
    // eslint-disable-next-line
  }, [client]);

  useEffect(() => {
    let query = new URLSearchParams(props.location.search);
    const search_term = query.get('text');
    getProviders(search_term);
    // eslint-disable-next-line
  }, [props.location.search]);

  const handleFilterChange = (e) => {
    const { name } = e.target;
    name === 'allChecked' && setAllChecked(!allChecked);
    if (name === 'checked') {
      setToggle(!toggle);
      setAllChecked(false);
    }

    if (name === 'specialization') {
      setFilter(e.target.value);
    }
  };

  if (providerLoading === true) {
    return (
      <div className={classesBase.loading}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  if (filterProvider) {
    return (
      <Fragment>
        <Navbar searchPage={true} />
        <div className={classes.root}>
          <Typography
            className={classes.resultText}
            color="primary"
            variant="h5"
          >
            Results for "
            {new URLSearchParams(props.location.search).get('text')}"
          </Typography>
          <FilterProvider
            toggle={toggle}
            filter={filter}
            allChecked={allChecked}
            handleFilterChange={handleFilterChange}
          />
          <Container maxWidth="xl">
            <Grid container className={classes.grid}>
              {filterProvider.map((provider) =>
                provider.role === filter ? (
                  <ProviderCard
                    provider={provider}
                    page="search"
                    key={provider.public_id}
                    clientLocationInfo={{
                      clientAddress: clientAddress,
                      set_lat_lng: client.set_lat_lng,
                    }}
                  />
                ) : filter === 'All' ? (
                  <ProviderCard
                    provider={provider}
                    page="search"
                    key={provider.public_id}
                    clientLocationInfo={{
                      clientAddress: clientAddress,
                      set_lat_lng: client.set_lat_lng,
                    }}
                  />
                ) : null
              )}
            </Grid>
          </Container>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Navbar searchPage={true} />
        <div className={classes.root}>
          <Typography
            className={classes.resultText}
            color="textSecondary"
            variant="h5"
          >
            No Result Found for "
            {new URLSearchParams(props.location.search).get('text')}"
          </Typography>
        </div>
      </Fragment>
    );
  }
};

export default SearchProvider;

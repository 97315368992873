import { makeStyles } from '@material-ui/core/styles';

export const providerAvailabilityStyles = makeStyles((theme) => ({
  root: {
    width: 'calc(100% - 420px)',
    marginTop: '130px',
    marginLeft: '380px',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '100%',
      marginLeft: '0px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: '0px',
      textAlign: 'center',
    },
  },
  title: {
    margin: theme.spacing(6, 0, 2),
    fontSize: '1.2rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },

  gridItemHomeVisit: {
    maxWidth: '341px',
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: '252px',
    },
    [theme.breakpoints.only('sm')]: {
      minWidth: '369px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '272px',
    },
  },

  gridContainerHomeVisit: {
    [theme.breakpoints.up('sm')]: {
      'justify-content': 'flex-start',
      'align-items': 'flex-start',
    },
  },

  distance: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    borderRadius: 7,
    color: theme.palette.primary.main,
    fontSize: 16,
    width: '341px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '220px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '337px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '242px',
    },
  },

  switchText: {
    color: theme.palette.primary.main,
    margin: theme.spacing(4, 0, 0),
    fontSize: '1rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
    },
  },
  divider: {
    margin: theme.spacing(4, 0),
    width: '800px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '530px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '380px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '280px',
      margin: theme.spacing(4, 'auto'),
    },
  },
  paperHours: {
    display: 'table',
    padding: theme.spacing(2, 1, 2, 2),
    margin: theme.spacing(2, 1, 1, 0),
    borderRadius: '5px',
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontFamily: '"Poppins", sans-serif',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1, 'auto'),
    },
  },
  removeIconBtn: {
    width: '15px',
    height: '15px',
    marginLeft: theme.spacing(1),
  },
  removeIcon: {
    width: '15px',
    height: '15px',
  },
  textArea: {
    width: '730px',
    height: '125px',
    borderRadius: '7px',
    borderColor: '#ced4da',
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: '16px',
    fontFamily: '"Poppins", sans-serif',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '500px',
      height: '140px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '350px',
      height: '108px',
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '250px',
      height: '100px',
      fontSize: '14px',
      padding: theme.spacing(1),
    },
  },
  saveButton: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(1.5, 3),
    color: '#ffffff',
    [theme.breakpoints.between('sm', 'md')]: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(1.5, 3),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(1.5, 3),
    },
  },
}));

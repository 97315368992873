import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
  palette: {
    primary: {
      main: "#3a5275",
      light: "#7c9cbb",
    },
    secondary: {
      main: "#97DA91",
    },
  },
  bgcolor: "#e9ebed",
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "200px",
        textTransform: "capitalize",
        fontWeight: "bold",
      },
      containedSecondary: {
        "&:hover": {
          backgroundColor: "#6bbd6e",
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "200px",
        textTransform: "capitalize",
        fontWeight: "bold",
      },
      inputAdornedStart: {
        color: "#3a5275",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "10px",
      },
    },
    MuiToggleButton: {
      root: {
        textTransform: "capitalize",
        color: "#7c9cbb",
        fontSize: "1rem",
        "&$selected": {
          color: "#ffffff",
          backgroundColor: "#7c9cbb",
          "&:hover": {
            backgroundColor: "#7c9cbb",
          },
        },
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "none",
        },
      },
    },
    MuiTypography: {
      body1: {
        fontWeight: "none",
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#97DA91",
        },
      },
    },
  },
});

import {
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  AUTH_ERROR,
  EMAIL_SUCCESS,
  EMAIL_FAIL,
  USER_LOADED,
  LOGOUT,
  CLEAR_MSG,
  TOKEN_SUCCESS,
  TOKEN_FAIL,
  PASSWORD_UPDATED,
  PASSWORD_UPDATE_FAIL,
} from '../actionTypes';

export default (state, action) => {
  switch (action.type) {
    case USER_LOADED:
      localStorage.setItem('user', JSON.stringify(action.payload));
      const loadUser = {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
        message: null,
      };
      return loadUser;
    case SIGNUP_SUCCESS:
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('user_role', action.payload.user_type);
      const userSuccess = {
        ...state,
        token: action.payload.token,
        isAuthenticated: true,
        user_role: action.payload.user_type,
        loading: false,
        message: null,
      };
      return userSuccess;
    case SIGNUP_FAIL:
    case LOGIN_FAIL:
    case AUTH_ERROR:
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('user_role');
      const userFail = {
        ...state,
        token: null,
        isAuthenticated: false,
        message: action.payload,
        user_role: null,
        user: null,
        loading: false,
      };
      return userFail;
    case EMAIL_SUCCESS:
      const emailSend = {
        ...state,
        message: action.payload,
        emailSuccess: true,
        loading: false,
      };
      return emailSend;
    case EMAIL_FAIL:
      const emailFail = {
        ...state,
        message: action.payload,
        emailSuccess: false,
        loading: false,
      };
      return emailFail;
    case TOKEN_SUCCESS:
      const tokenSuccess = {
        ...state,
        resetAllowed: true,
        message: action.payload,
        loading: false,
      };
      return tokenSuccess;
    case TOKEN_FAIL:
      const tokenFail = {
        ...state,
        resetAllowed: false,
        message: action.payload,
        loading: false,
      };
      return tokenFail;
    case PASSWORD_UPDATED:
    case PASSWORD_UPDATE_FAIL:
      const passUpdate = {
        ...state,
        message: action.payload,
        loading: false,
      };
      return passUpdate;
    case CLEAR_MSG:
      const authMsg = {
        ...state,
        message: null,
      };
      return authMsg;
    default:
      return state;
  }
};

import React, { Fragment, useContext, useState, useEffect } from 'react';
import DashboardNavBar from '../appbar/DashboardNavBar';
import SideBar from '../sidebar/SideBar';
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Typography,
  OutlinedInput,
  NativeSelect,
  Snackbar,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PersonIcon from '@material-ui/icons/Person';
import RoomIcon from '@material-ui/icons/Room';
import { providerUserInfoStyles } from './providerUserInfoStyles';
import AuthContext from '../../context/authentication/authContext';
import ProviderContext from '../../context/provider/providerContext';

const ProviderUserInfo = (props) => {
  const classes = providerUserInfoStyles();

  const authContext = useContext(AuthContext);
  const providerContext = useContext(ProviderContext);

  const { user } = authContext;
  const {
    getMyProvider,
    myProvider,
    updateProvider,
    providerMsg,
    clearMsg,
    uploadImage,
  } = providerContext;

  const [snackbar, setSnackbar] = useState(false);
  const [saveBtn, setSaveBtn] = useState(true);
  const [localUser, setLocalUser] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    city: '',
    province: 'ON',
    postal_code: '',
  });

  const {
    firstname,
    lastname,
    email,
    phone,
    city,
    province,
    postal_code,
  } = localUser;

  useEffect(() => {
    user &&
      user.user_type === 'client' &&
      props.history.push('/client/user-info');

    user && getMyProvider(user.public_id);
    // this is the first page provider logs into and user state can be null
  }, [user]);

  useEffect(() => {
    setLocalUser({
      firstname: myProvider.firstname,
      lastname: myProvider.lastname,
      email: myProvider.email,
      phone: myProvider.phone,
      city: myProvider.city,
      postal_code: myProvider.postal_code,
    });
  }, [myProvider]);

  useEffect(() => {
    providerMsg === 'Changes saved successfully' && setSnackbar(true);
    // eslint-disable-next-line
  }, [localUser]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    setSaveBtn(false);
    setLocalUser({ ...localUser, [name]: value });
  };

  const handleSnackbar = () => {
    setSnackbar(!snackbar);
    clearMsg();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!firstname || !email) return;

    console.log('submit');
    updateProvider(
      { firstname, lastname, email, phone, city, province, postal_code },
      user.public_id
    );
    setSaveBtn(true);
  };

  const onUploadImage = (e) => {
    uploadImage(e, user.public_id, 'provider');
  };

  return (
    <Fragment>
      <DashboardNavBar />
      <SideBar />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={2000}
        open={snackbar}
        onClose={handleSnackbar}
      >
        <Alert elevation={6} variant="filled" severity="success">
          {providerMsg}
        </Alert>
      </Snackbar>

      <div className={classes.root}>
        {/* ********************** Profile pic container ********************** */}
        <Grid
          container
          alignItems="center"
          className={classes.profileDivContainer}
        >
          <Grid item className={classes.profileItemDiv}>
            <div className={classes.profilePicDiv}>
              {myProvider.profile_pic && (
                <div
                  className={classes.profilePicUploaded}
                  style={{
                    backgroundImage: `url(${myProvider.profile_pic})`,
                  }}
                ></div>
              )}
              {!myProvider.profile_pic && (
                <PersonIcon className={classes.profilePic} />
              )}
            </div>

            <input
              id="avatar-file-upload"
              type="file"
              multiple="false"
              accept=".jpg,.jpeg,.png"
              onChange={onUploadImage}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6" color="primary" className={classes.user}>
              {user && `${user.firstname} ${user.lastname}`}
            </Typography>
            <div className={classes.div}>
              <RoomIcon className={classes.locationIcon} />
              <Typography
                color="textSecondary"
                variant="body2"
                className={classes.location}
              >
                {myProvider && myProvider.city && `${myProvider.city}, `}{' '}
                {myProvider.province}
              </Typography>
            </div>
          </Grid>
        </Grid>

        {/* ************************* Form ********************** */}
        <form noValidate className={classes.form} onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="firstname">
                  First Name
                </InputLabel>
                <OutlinedInput
                  id="firstname"
                  name="firstname"
                  color="secondary"
                  type="text"
                  className={classes.input}
                  value={firstname}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="lastname">
                  Last Name
                </InputLabel>
                <OutlinedInput
                  id="lastname"
                  name="lastname"
                  color="secondary"
                  type="text"
                  className={classes.input}
                  value={lastname}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="email">
                  Email address
                </InputLabel>
                <OutlinedInput
                  disabled
                  id="email"
                  name="email"
                  type="email"
                  color="secondary"
                  className={classes.input}
                  value={email}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="phone">
                  Phone number
                </InputLabel>
                <OutlinedInput
                  id="phone"
                  name="phone"
                  type="text"
                  color="secondary"
                  className={classes.input}
                  value={phone}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="city">
                  City
                </InputLabel>
                <OutlinedInput
                  id="city"
                  name="city"
                  type="text"
                  color="secondary"
                  className={classes.input}
                  value={city}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="province">
                  Province
                </InputLabel>
                <NativeSelect
                  disabled
                  value={province}
                  onChange={handleChange}
                  input={
                    <OutlinedInput
                      id="province"
                      name="province"
                      type="text"
                      color="secondary"
                      className={classes.input}
                    />
                  }
                >
                  <option value="AB">Alberta</option>
                  <option value="BC">British Columbia</option>
                  <option value="MB">Manitoba</option>
                  <option value="NB">New Brunswick</option>
                  <option value="NL">Newfoundland and Labrador</option>
                  <option value="NT">Northwest Territories</option>
                  <option value="NS">Nova Scotia</option>
                  <option value="NU">Nunavut</option>
                  <option value="ON">Ontario</option>
                  <option value="PE">Prince Edward Island</option>
                  <option value="QC">Quebec</option>
                  <option value="SK">Saskatchewan</option>
                  <option value="YT">Yukon</option>
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} sm={5} className={classes.grid}>
              <FormControl>
                <InputLabel shrink htmlFor="postal_code">
                  Postal Code
                </InputLabel>
                <OutlinedInput
                  id="postal_code"
                  name="postal_code"
                  type="text"
                  color="secondary"
                  className={classes.input}
                  value={postal_code}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Button
            disabled={saveBtn}
            variant="contained"
            color="secondary"
            className={classes.saveButton}
            type="submit"
          >
            Save changes
          </Button>
        </form>
      </div>
    </Fragment>
  );
};

export default ProviderUserInfo;

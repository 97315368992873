import React from 'react';
import { AppBar, Container, Toolbar, Typography } from '@material-ui/core';
import { useStyles } from '../../../themes/commonStyles';
import { footerStyles } from './footerStyles';
import { Link } from 'react-router-dom';
import { currentYear } from '../../utility/utils';

const Footer = () => {
  const classesBase = useStyles();
  const classes = footerStyles();

  return (
    <div className={classesBase.root}>
      <AppBar position="static" color="inherit" style={{ boxShadow: ' none' }}>
        <Container maxWidth="lg">
          <Toolbar className={classes.toolbar}>
            <div className={classes.copyright}>
              <Typography variant="body1" className={classes.typography}>
                &copy; MyProvider {currentYear}
              </Typography>
            </div>

            <Link to="/for-provider" className={classes.footerLink}>
              <Typography color="primary" className={classes.footerTypography}>
                For providers
              </Typography>
            </Link>
            <Link to="/about" className={classes.footerLink}>
              <Typography color="primary" className={classes.footerTypography}>
                About us
              </Typography>
            </Link>

            <a
              href="https://storage.googleapis.com/assets.myprovider.ca/files/terms-of-service.html"
              className={classes.footerLink}
              target="_blank"
            >
              <Typography color="primary" className={classes.footerTypography}>
                Terms of Service
              </Typography>
            </a>

            <a
              href="https://storage.googleapis.com/assets.myprovider.ca/files/privacy-policy.html"
              className={classes.footerLink}
              target="_blank"
            >
              <Typography color="primary" className={classes.footerTypography}>
                Privacy Policy
              </Typography>
            </a>

            <a
              href="https://storage.googleapis.com/assets.myprovider.ca/files/copyright.html"
              className={classes.footerLink}
              target="_blank"
            >
              <Typography color="primary" className={classes.footerTypography}>
                Copyright
              </Typography>
            </a>

            <a href="mailto:info@myprovider.ca" className={classes.contactLink}>
              <Typography color="primary" className={classes.footerTypography}>
                Contact us
              </Typography>
            </a>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Footer;
